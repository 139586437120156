import { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Code } from "react-content-loader";
import NiceModal from "../../modals/NiceModal";
import GroupsAPI from "../../../api/GroupsAPI";
import DoctorDashboardPanel from "../../dashboard/panels/DoctorDashboardPanel";
import { useMappedState } from "redux-react-hook";
import { isDebugMode } from "../../../helpers/UiHelper";
import GroupEcgExportTool from "../tools/GroupEcgExportTool";
import moment from "moment";
import ls from "local-storage";
import {
  GROUP_CACHE_LS_KEY,
  GROUP_CACHE_TIMESTAMP_LS_KEY,
  GROUP_CACHE_VALID_SECONDS,
} from "./GroupsPanel";

export default function GroupPanel(props) {
  const { code } = props;

  // console.debug("optimizing rendering GroupPanel.js");
  const [group, setGroup] = useState();

  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);

  const [editId, setEditId] = useState(undefined);
  const [ecgReportVisible, setEcgReportVisible] = useState(false);

  const { currentUserObj, email, isDemo, theme } = useMappedState(
    useCallback((state) => {
      let email = state?.auth?.currentUserObj?.usersUser?.email;
      return {
        currentUserObj: state.auth.currentUserObj,
        email: email,
        isDemo: email == "demo@corsano.com_",
        theme: state.ui.theme,
      };
    })
  );

  let xUUID = currentUserObj?.usersUser?.uuid;

  useEffect(() => {
    if (localStorage.hasOwnProperty(GROUP_CACHE_LS_KEY)) {
      // console.log("has grps key");
      const _time = +ls.get(GROUP_CACHE_TIMESTAMP_LS_KEY);
      const _now = Date.now();
      const cachedDuration = (_now - _time) / 1000;
      // console.log(cachedDuration);
      if (cachedDuration < GROUP_CACHE_VALID_SECONDS) {
        const lastCachedGroup = localStorage.getItem(GROUP_CACHE_LS_KEY);
        const parsedLastCachedGroup = JSON.parse(lastCachedGroup);
        setGroups(parsedLastCachedGroup);
        setLoading(false);
        // console.log("Using grps cache");
        return;
      } else {
        ls.remove(GROUP_CACHE_TIMESTAMP_LS_KEY);
        ls.remove(GROUP_CACHE_LS_KEY);
      }
    }

    setLoading(true);
    GroupsAPI.getGroups().then((arr) => {
      console.debug("optimizing GroupsAPI.getGroups loaded");
      const lastGetGroupsTime = moment().valueOf();
      const sortedArr = arr.sort((a, b) => +b.timestamp - +a.timestamp);
      setGroups(sortedArr);
      setLoading(false); // loading point 1
      // ls.set(`${lastGetGroupsTime}_grps`, sortedArr);
    });
  }, []);

  let isDebug = isDebugMode();

  console.log({ loading, l: groups.length });

  if (loading == true && groups.length == 0) {
    // console.debug("optimizing GroupPanel loading");
    return (
      <div>
        <h1>GroupPanel LOADING</h1>
        {theme === "dark" ? (
          <>
            <Code backgroundColor={"#333"} foregroundColor={"#999"} />
            <Code backgroundColor={"#333"} foregroundColor={"#999"} />
            <Code backgroundColor={"#333"} foregroundColor={"#999"} />
          </>
        ) : (
          <>
            <Code />
            <Code />
            <Code />
          </>
        )}
      </div>
    );
  }

  let editGroup =
    editId == undefined ? undefined : groups.filter((x) => x.id == editId)[0];

  // console.log({ groupsGotPassed: groups, groupsLoading: loading });

  return (
    <Wrapper>
      <DoctorDashboardPanel
        isGwMode={code == undefined || (code != undefined && code.length > 24)}
        tag={code || xUUID}
        groups={groups || []}
        groupsLoading={loading}
      />

      {isDebug == false || code == undefined ? null : (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span
            style={{
              cursor: "pointer",
              fontSize: "12px",
              fontStyle: "italic",
              opacity: 0.5,
            }}
            onClick={() => {
              setEcgReportVisible(true);
            }}
          >
            ECG records report
          </span>
        </div>
      )}

      {ecgReportVisible == false ? null : (
        <NiceModal
          onClose={() => {
            setEcgReportVisible(false);
          }}
        >
          <InnerPlaceholder>
            <GroupEcgExportTool code={code} />
          </InnerPlaceholder>
        </NiceModal>
      )}
    </Wrapper>
  );
}

const InnerPlaceholder = styled.div`
  width: 720px;
  min-height: 500px;
  max-height: calc(min(100vh - 200px, 820px));
  overflow-y: auto;
`;

const ItemBottomPlaceholder = styled.div`
  opacity: 0.8;
  font-size: 12px;
  font-style: italic;
`;

const NamePlaceholder = styled.div`
  color: #000f4b;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 1px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const CodePlaceholder = styled.div`
  color: #000f4b;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 1px;
  opacity: 0.7;
`;

const AddInnerPlaceholder = styled.div`
  width: 600px;
  box-sizing: border-box;
  padding: 20px;
`;

const EditImg = styled.img`
  cursor: pointer;
  opacity: 0.7;
  height: 20px;
  margin-left: 10px;

  :hover {
    opacity: 1;
  }
`;

const Wrapper = styled.div``;

const Heading = styled.div`
  color: #000f4b;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: 1px;
`;

const AddButton = styled.div`
  background: #147aff;
  height: 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: white;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 14px;
  font-weight: 800;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const ItemTopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ItemLeft = styled.div`
  flex: 1;
`;

const ItemRight = styled.div`
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const GroupItem = styled.div`
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid rgba(135, 152, 173, 0.15);
  box-shadow: ${(props) =>
    props.danger == true ? "0px 4px 5px rgba(250, 70, 70, 0.05);" : "none"};
  box-sizing: border-box;
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  flex-direction: row;
`;

const GroupsListPlaceholder = styled.div``;
