import SelectBraceletTool from "../../../bracelets/tools/SelectBraceletTool";
import { getLabelsInfoData } from "../../../groups/panels/GroupsPanel";
import styled, { css } from "styled-components";
import { NamePlaceholder } from "./ParamElements";

export default function NameSection(props) {
  const { layout, xName, p, onPatientClick } = props;
  return (
    <NameSectionPlaceholder
      layout={layout}
      className="name-section-placeholder"
      style={{
        marginBottom: layout.includes("small") ? "5px" : "0px",
      }}
    >
      <NamePlaceholder
        style={{
          fontSize: layout.includes("small") ? "16px" : "18px",
        }}
        className="patient-name"
        onClick={() => {
          onPatientClick(p);
        }}
      >
        {xName}
      </NamePlaceholder>

      {getLabelsInfoData().bracelets_enabled == false ? null : (
        <SelectBraceletTool
          {...p}
          patientUUID={p.uuid}
          onSelected={() => {
            onBraceletChanged(p.uuid);
          }}
        />
      )}
    </NameSectionPlaceholder>
  );
}

const NameSectionPlaceholder = styled.div`
  ${(props) => {
    switch (props.layout) {
      case "normal":
        return css`
          // width: max-content;
          align-items: center;
          justify-content: center;
          // border: 1px solid grey;
          width: 220px;
          // height: 3rem;
          // margin-bottom: 0.5rem;
          margin-right: 1rem;
          padding-right: ${(props) =>
            props.layout.includes("small") ? "4px" : "5px"};
          padding-left: ${(props) =>
            props.layout.includes("small") ? "4px" : "10px"};
        `;
      default:
        return css`
          // width: max-content;
          align-items: center;
          justify-content: center;
          // border: 1px solid grey;
          width: 220px;
          // height: 3rem;
          // margin-bottom: 0.5rem;
          margin-right: 1rem;
          padding-right: ${(props) =>
            props.layout.includes("small") ? "4px" : "5px"};
          padding-left: ${(props) =>
            props.layout.includes("small") ? "4px" : "10px"};
        `;
    }
  }}
`;
