import { isDebugMode } from "../../../../helpers/UiHelper";
import PatientDayPulseRateBaselineTool from "../../../baselines/PatientDayPulseRateBaselineTool";
import PatientDayPulseRateBaselineDebugsTool from "../../../baselines/PatientDayPulseRateBaselineDebugsTool";
import InfoToolBadge from "../../../info/tools/InfoToolBadge";
import SimpleBpmSlotsChart from "../../../heart_rate/tools/SimpleBpmSlotsChart";
import VivalinkItemsTool from "../../../vivalink/tools/VivalinkItemsTool";
import BinaryMeasurementsPanel from "../../binary/panels/BinaryMeasurementsPanel";
import SimpleNoDataMessageTool from "../../../sleep/tools/SimpleNoDataMessageTool";
import ActivityHeartRateChart from "../../../heart_rate/tools/ActivityHeartRateChart";
import AfibTool from "../../../afib/tools/AfibTool";
import HappitechChart from "../../../afib/tools/HappitechChart";
import HappitechTool from "../../../afib/tools/HappitechTool";
import VitalTrendsChartTool from "../../../trends/tools/VitalTrendsChartTool";

import {
  ComplianceHeading,
  ChartContainer,
  ChartTitleSection,
  SquareForImg,
} from "../UiComponents";
import moment from "moment";
import export_img from "../../tools/export_img.svg";

const DEMO_MESSAGE =
  "This is a demo portal. For full access please contact administrator.";

export default function PulseRatePanel(props) {
  const {
    id,
    date,
    seltT,
    summary,
    user,
    loading,
    theme,
    baselineWindow,
    tz,
    loadingAllTypeBaseline,
    allTypeBaselineMap,
    tempActivityPoints,
    vitalSummaryInRange,
    isDemo,
  } = props;
  return (
    <div className="pulse-rate-tab-container">
      <ChartContainer>
        {summary === undefined ||
        summary?.heart_rate === undefined ||
        summary?.heart_rate.slots === undefined ? (
          <SimpleNoDataMessageTool
            loading={loading}
            message={"Sorry, there is no heart rate data for this day."}
          />
        ) : (
          <div className="pulse-rate-area" style={{ height: 380 }}>
            <ChartTitleSection>
              <ComplianceHeading className="patient-statistics-heading">
                Pulse Rate
              </ComplianceHeading>
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <InfoToolBadge type={"HEART_RATE"} />
                {isDebugMode() === false ? null : (
                  <ExportDiv
                    name={"Export PPG"}
                    onExport={() => {
                      if (isDemo === true) {
                        return window.alert(DEMO_MESSAGE);
                      }
                      setExportPpg2Visible(true);
                    }}
                  />
                )}
              </div>
            </ChartTitleSection>
            <PatientDayPulseRateBaselineTool
              baselineWindow={baselineWindow}
              points={allTypeBaselineMap["bpm"]}
              loading={loadingAllTypeBaseline}
              uuid={id}
              date={date}
              tz={tz}
              theme={theme}
              hasNoData={
                summary === undefined ||
                summary?.heart_rate === undefined ||
                summary?.heart_rate?.slots === undefined
              }
            />
          </div>
        )}
      </ChartContainer>

      {isDebugMode() ? (
        <div
          className="temp-heart-rate-area"
          style={{ width: "100%", height: 300, marginTop: "3rem" }}
        >
          <SimpleBpmSlotsChart
            // tz={tz}
            slots={
              // summary?.heart_rate?.slots
              //   ? summary?.heart_rate?.slots.map((x) => ({
              //       ...x,
              //       t: x.timestamp,
              //     }))
              //   : []
              tempActivityPoints
            }
            min={+moment(date).startOf("day")}
            max={+moment(date).startOf("day").add(1, "day").valueOf()}
          />
        </div>
      ) : null}

      {isDebugMode() === false ? null : (
        <div className="arrhythmias-charts-area">
          <ActivityHeartRateChart
            uuid={id}
            dayTimestamp={+moment(date).startOf("day")}
          />

          <ComplianceHeading className="patient-statistics-heading">
            Arrhythmias
          </ComplianceHeading>
          <AfibTool
            uuid={id}
            from={+moment(date).startOf("day")}
            to={+moment(date).endOf("day")}
          />

          <ComplianceHeading className="patient-statistics-heading">
            AF
          </ComplianceHeading>

          <HappitechChart
            uuid={id}
            from={+moment(date).startOf("day")}
            to={+moment(date).endOf("day")}
          />
          <HappitechTool
            uuid={id}
            from={+moment(date).startOf("day")}
            to={+moment(date).endOf("day")}
          />
        </div>
      )}

      {isDebugMode() && (
        <VivalinkItemsTool
          uuid={id}
          from={+moment(seltT).startOf("day")}
          to={+moment(seltT).endOf("day")}
        />
      )}

      <div className="pulse-rate-trend-area">
        <ComplianceHeading className="patient-statistics-heading">
          Trends
        </ComplianceHeading>
        <div
          style={{
            width: "100%",
            height: 360,
          }}
        >
          {/* <PulseRateTrendsChartTool uuid={id} /> */}
          <VitalTrendsChartTool
            dataType={"pulse_rate"}
            uuid={id}
            points={vitalSummaryInRange}
          />
        </div>
      </div>

      <div className="ecg-area">
        <ComplianceHeading
          style={{
            marginTop: "2rem",
            display:
              window.location.href.indexOf("portal.corsano") > 0 &&
              !isDebugMode()
                ? "none"
                : "",
          }}
          className="patient-statistics-heading"
        >
          ECG
        </ComplianceHeading>
        <div
          style={{
            width: "100%",
            height: 300,
            maxHeight: "300px",
            overflowY: "auto",
            display:
              window.location.href.indexOf("portal.corsano") > 0 &&
              !isDebugMode()
                ? "none"
                : "",
          }}
        >
          <BinaryMeasurementsPanel
            uuid={id}
            filterType={"ecg"}
            showDescription={true}
            showNote={true}
            isDemo={isDemo}
            user={user}
          />
        </div>
      </div>
      {isDebugMode() ? (
        <div className="spo2-baseline-area" style={{ height: 2 * 380 }}>
          <div
            className="spo2-baseline-top-area"
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <ComplianceHeading className="patient-statistics-heading">
              BPM (production)
            </ComplianceHeading>
          </div>
          <PatientDayPulseRateBaselineTool
            baselineWindow={baselineWindow}
            points={allTypeBaselineMap["bpm"]}
            loading={loadingAllTypeBaseline}
            uuid={id}
            date={date}
            tz={tz}
            theme={theme}
            hasNoData={
              summary === undefined ||
              summary?.heart_rate === undefined ||
              summary?.heart_rate?.slots === undefined
            }
          />
          <div
            className="spo2-baseline-top-area"
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <ComplianceHeading className="patient-statistics-heading">
              BPM (EMA)
            </ComplianceHeading>
          </div>
          <PatientDayPulseRateBaselineDebugsTool
            uuid={id}
            date={date}
            theme={theme}
            baselineWindow={baselineWindow}
            tz={tz}
            loading={loadingAllTypeBaseline}
            points={allTypeBaselineMap["bpm"]}
            hasNoData={
              summary === undefined ||
              summary?.heart_rate === undefined ||
              summary?.heart_rate?.slots === undefined
            }
            algorithm={4}
          />
        </div>
      ) : null}
    </div>
  );
}

function ExportDiv(props) {
  const { onExport = () => {}, name = "Export" } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        marginBottom: 10,
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        onClick={() => {
          onExport();
        }}
      >
        <SquareForImg>
          <img className="ppg-icon" style={{ height: 22 }} src={export_img} />
        </SquareForImg>
        <span
          className="bp-info-span"
          style={{ color: "#147AFF", cursor: "pointer", fontWeight: "300" }}
        >
          {name}
        </span>
      </div>
    </div>
  );
}
