import React, { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import BinaryMeasurementsAPI from "../../../api/BinaryMeasurementsAPI";

import { Code } from "react-content-loader";

export default function ContinuousMeasurementsPanel(props) {
  const { userId, from, to, type, canExportMergedWiff = false } = props;

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    BinaryMeasurementsAPI.getInstance()
      .getContinuousMeasurements(userId, type, from, to)
      .then((pld) => {
        // console.log("pld = ", pld);
        setItems(pld.sort((a, b) => +a.start_timestamp - +b.start_timestamp));
        setLoading(false);
      })
      .catch((e) => console.error("BinaryMeasurementsAPI", e));
  }, [userId, from, to, type]);

  if (loading == true) {
    return <Code />;
  }

  return (
    <Wrapper>
      <h3 className="continuous-panel-text">{type}</h3>

      {type != "bioz" && type != "ppg2" && type != "acc" ? null : (
        <div style={{ marginBottom: 15 }}>
          <div>
            <a
              className="download-wiff-span"
              href={`https://api.study-integration.corsano.com/health/user/${userId}/continuous-measurements/${type}/${from}/${to}/wiff`}
              target={"_blank"}
            >
              download merged .wiff file
            </a>
          </div>
          <div
            style={{ fontSize: 12, opacity: 0.5 }}
            className="continuous-panel-text"
          >
            This can take several minutes
          </div>
        </div>
      )}

      {/*{items.length == 0 ? null :*/}
      {/*    <div style={{marginBottom: 10}}>*/}
      {/*        {type != 'ppg2' ? null :*/}
      {/*            <div>*/}
      {/*                <a href={BinaryMeasurementsAPI.getInstance().getContinuousMeasurementsPPG2FileUrl(userId, type, from, to)}*/}
      {/*                   target={'_blank'}>*/}
      {/*                    {`download csv (may take ~1 minute)`}*/}
      {/*                </a>*/}
      {/*            </div>*/}
      {/*        }*/}
      {/*        <div>*/}
      {/*            <a href={BinaryMeasurementsAPI.getInstance().getContinuousMeasurementsRangeFileUrl(userId, type, from, to)}*/}
      {/*               target={'_blank'}>*/}
      {/*                {`download archive (${items.length} files)`}*/}
      {/*            </a>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*}*/}

      <ItemsPlaceholder>
        {items.map((a, i) => {
          let fName = `ppg-${moment(a.start_timestamp).format(
            "YYYY-MM-DD-HH-mm-ss"
          )}.zip`;
          let biozFname = `bioz-${moment(a.start_timestamp).format(
            "YYYY-MM-DD-HH-mm-ss"
          )}.zip`;
          return (
            <Item key={a._id}>
              <SpanLeft className="continuous-panel-text">
                {moment(a.start_timestamp).format("DD.MM.YYYY HH:mm:ss")}
              </SpanLeft>
              <SpanRight>
                <DownloadWiffSpan
                  target={"_blank"}
                  className="download-wiff-span"
                  href={BinaryMeasurementsAPI.getInstance().getContinuousMeasurementFileUrl(
                    userId,
                    type,
                    a.start_timestamp
                  )}
                >
                  download .wiff
                </DownloadWiffSpan>
                {type != "ppg2" ? null : (
                  <DownloadWiffSpan
                    className="download-wiff-span"
                    target={"_blank"}
                    href={BinaryMeasurementsAPI.getInstance().getContinuousMeasurementsSinglePPG2FileUrl(
                      userId,
                      type,
                      a.start_timestamp,
                      fName
                    )}
                  >
                    download .csv
                  </DownloadWiffSpan>
                )}
                {type != "bioz" ? null : (
                  <DownloadWiffSpan
                    className="download-wiff-span"
                    target={"_blank"}
                    href={BinaryMeasurementsAPI.getInstance().getContinuousMeasurementsSinglePPG2FileUrl(
                      userId,
                      type,
                      a.start_timestamp,
                      biozFname
                    )}
                  >
                    download .csv
                  </DownloadWiffSpan>
                )}
              </SpanRight>
            </Item>
          );
        })}
      </ItemsPlaceholder>
    </Wrapper>
  );
}

const Item = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgrey;
  padding-bottom: 5px;
`;

const DownloadWiffSpan = styled.a`
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  opacity: 0.5;
  font-size: 12px;
  text-decoration: underline;
`;

const SpanLeft = styled.div``;

const SpanRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Wrapper = styled.div``;

const ItemsPlaceholder = styled.div``;
