import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "styled-components";
import PatientsListTool from "../tools/PatientsListTool";
import SearchInput from "../tools/SearchInput";
import { Map } from "immutable";

import numberImg from "../../../assets/images/number.svg";
import tableImg from "../../../assets/images/table.svg";
import archiveImg from "../../../assets/images/majesticons_archive.svg";
import filterImg from "../../../assets/images/filterList.svg";

import CommonHelper from "../../../helpers/CommonHelper";
import DoctorAPI from "../../../api/DoctorAPI";
import { BulletList, Code } from "react-content-loader";
import { useDispatch, useMappedState } from "redux-react-hook";
import { DEMO_FAKE_USERS } from "../../../constants/config";
import NiceModal from "../../modals/NiceModal";
import DoctorExportPanel from "../../export/panels/DoctorExportPanel";
import DoctorOrderFilterPanel from "../../export/panels/DoctorOrderFilterPanel";
import * as braceletsActions from "../../../redux/actions/BraceletsActions";
import * as UiActions from "../../../redux/actions/UiActions";

import Select from "react-select";
import Tabs, { TabItem } from "../../ui/Tabs";
import GroupsAPI from "../../../api/GroupsAPI";
import NewVouchersPanel from "./NewVouchersPanel";
import { Button } from "../../auth/ui";

import alarms_icon from "./images/alarm_icon.png";
import UsersInterfaceAPI from "../../../api/UsersInterfaceAPI";
import { Spin } from "arui-feather/spin";

import { GOOD_RANGES_OPTIONS } from "../../ranges/tools/PatientRangesTool";
import { getLabelsInfoData } from "../../groups/panels/GroupsPanel";
import { EditButton } from "../../patients/panels/PatientPanel";
import BraceletsAPI from "../../../api/BraceletsAPI";
import NiceConfirm from "../../modals/NiceConfirm";
import NewPatientRangesTool from "../../ranges/new_tools/NewPatientRangesTool";
import { getRangesSettings } from "../../ranges/new_tools/NewRangeTool";
import { useInterval } from "../../apps/RouterApp";

import ls from "local-storage";

import { Centrifuge } from "centrifuge";

import dismiss_icon from "../../../assets/images/no_alarm.png";
import RealtimeAPI from "../../../api/RealtimeAPI";
import moment from "moment";
import OptimizedDoctorExportPanel from "../../export/panels/OptimizedDoctorExportPanel";
import CardioHelper from "../../../helpers/CardioHelper";
import { isDebugMode } from "../../../helpers/UiHelper";
import Bell from "../tools/Bell";
import offline_img from "../../../assets/images/offline-cloud.svg";
import { Detector } from "react-detect-offline";
import GroupStatsTableTool from "../../groups/tools/GroupStatsTableTool";
import VoucherLetterTool from "../tools/VoucherLetterTool";
import QrCodesList from "../tools/QrCodesList";
import { filter } from "jszip";
import { toast } from "react-hot-toast";
import QRCode from "react-qr-code";
import { Textarea } from "../../ui/templates";
import PatientOtherNewsTool from "../../ranges/new_tools/PatientOtherNewsTool.jsx";
import {
  GROUP_CACHE_LS_KEY,
  GROUP_CACHE_TIMESTAMP_LS_KEY,
  GROUP_CACHE_VALID_SECONDS,
  PATIENTS_CACHE_LS_KEY,
  PATIENTS_CACHE_TIMESTAMP_LS_KEY,
  PATIENTS_CACHE_VALID_SECONDS,
} from "../../groups/panels/GroupsPanel";

const getBraceletsMap = (bracelets) => {
  return bracelets
    .filter((x) => x.patientUUID != undefined)
    .reduce((mp, x) => mp.set(x.patientUUID, x), Map());
};

function getUserRangesSettings(uuid, usersRangesMap = {}) {
  let v = usersRangesMap[uuid] || {};
  let result = {};
  for (let opt of GOOD_RANGES_OPTIONS) {
    let { name } = opt;
    let dd = getRangesSettings(name);
    let { defaults } = dd;
    if (v[name] == undefined) {
      result[name] = defaults;
    } else {
      result[name] = v[name];
    }
  }
  return result;
}

export default function DoctorDashboardPanel(props) {
  const { tag, isGwMode = false, groups = [], groupsLoading = false } = props;
  // console.debug("optimizing rendering DoctorDashboardPanel");

  const {
    currentUserObj,
    email,
    isDemo,
    bracelets,
    isCorsanoDemo,
    theme,
    order,
    filter,
    role,
  } = useMappedState(
    useCallback((state) => {
      let email = state?.auth?.currentUserObj?.usersUser?.email;
      return {
        currentUserObj: state.auth.currentUserObj,
        email: email,
        isDemo: email == "demo@corsano.com_",
        isCorsanoDemo: email == "demo@corsano.com",
        bracelets: state.bracelets.braceletsMap.toArray(),
        theme: state.ui.theme,
        order: state.ui.patientsListOrder,
        filter: state.ui.patientsListFilter,
        role: state.ui.role,
      };
    })
  );
  // console.log({ groupsProp: groups });

  const dispatch = useDispatch();
  const isHCPAdmin = role === "hcp-admin";
  const matchedGroup = groups.filter((x) => x.code === tag)[0];

  const [updT, setUpdT] = useState(0);
  const [selectedRanges, setSelectedRanges] = useState({});
  const [usersRangesMap, setUsersRangesMap] = useState({});
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [hasInternet, setHasInternet] = useState(true);

  let hasPortal = window.location.href.indexOf("portal") > -1;
  let hasStudy = window.location.href.indexOf("study") > -1;
  let isPortalOrStudy =
    window.location.href.indexOf("portal.corsano.com") > -1 ||
    window.location.href.indexOf("study.corsano.com") > -1 ||
    window.location.href.indexOf("portal.corsano.xyz") > -1 ||
    window.location.href.indexOf("study.corsano.xyz") > -1 ||
    window.location.href.indexOf("af.corsano.com") > -1;

  const centrSubscriptions = useRef();
  const centrifuge = useRef();
  const realtimeMap = useRef({});
  const lastUpdT = useRef(0);

  // console.log("render: currentUserObj = ", currentUserObj);

  const [statsVisible, setStatsVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  // const [listOrder, setListOrder]
  const [devicesMap, setDevicesMap] = useState({});
  const [rawMetricsMap, setRawMetricsMap] = useState({});
  const [loading, setLoading] = useState();
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [filterModelVisible, setFilterModelVisible] = useState(false);
  const [newVoucherModalVisible, setNewVoucherModalVisible] = useState(false);
  // const [group, setGroup] = useState(undefined);
  // console.log("matchedGroup", matchedGroup);

  const [group, setGroup] = useState(matchedGroup);
  // console.log();
  // console.log(group);

  const [alarmId, setAlarmId] = useState(undefined);
  const [spotId, setSpotId] = useState(undefined);
  const [unlinkId, setUnlinkId] = useState(undefined);

  const [qrCodeModelVisible, setQrCodeModelVisible] = useState("");

  const [hideId, setHideId] = useState(undefined);

  const [exportRanges, setExportRanges] = useState({
    from: +moment().startOf("day"),
    to: +moment().endOf("day"),
  });

  const [unlinking, setUnlinking] = useState(false);
  const [hiding, setHiding] = useState(false);

  const [braceletModeUserId, setBraceletModeUserId] = useState(undefined);
  const [rangesLoading, setRangesLoading] = useState(false);
  const [rangesSaving, setRangesSaving] = useState(false);

  const [lastMetricsLoading, setLastMetricsLoading] = useState(false);

  const [patients, setPatients] = useState([]);
  const [patentsWithMetrics, setPatientsWithMetrics] = useState([]);

  const [corsanoPatientsMap, setCorsanoPatientsMap] = useState({});
  const [groupCompliance, setGroupCompliance] = useState({});
  const [groupCuffBp, setGroupCuffBp] = useState({});
  const [groupSpotMeasurements, setGroupSpotMeasurements] = useState({});

  const [officialVersions, setOfficialVersions] = useState({});

  useEffect(() => {
    console.log("DASHBOARD");

    if (localStorage.hasOwnProperty(GROUP_CACHE_LS_KEY)) {
      console.log("ds has grps key");
      const _time = +ls.get(GROUP_CACHE_TIMESTAMP_LS_KEY);
      const _now = Date.now();
      const cachedDuration = (_now - _time) / 1000;
      // console.log(cachedDuration);
      if (cachedDuration < GROUP_CACHE_VALID_SECONDS) {
        const lastCachedGroup = localStorage.getItem(GROUP_CACHE_LS_KEY);
        const parsedLastCachedGroup = JSON.parse(lastCachedGroup);
        const matchedGroup = parsedLastCachedGroup.filter(
          (x) => x.code === tag
        )[0];
        setGroup(matchedGroup);
        setLoading(false);
        console.log("[Dashboard] Using grps cache");
        return;
      } else {
        ls.remove(GROUP_CACHE_TIMESTAMP_LS_KEY);
        ls.remove(GROUP_CACHE_LS_KEY);
      }
    }
  }, []);

  useEffect(() => {
    setExportRanges({
      from: +moment().startOf("day"),
      to: +moment().endOf("day"),
    });
  }, []);

  useEffect(() => {
    DoctorAPI.getNewestVersions()
      .then((newVersions) => {
        // console.log("newversions", newVersions);
        setOfficialVersions(newVersions);
      })
      .catch((e) => console.error(e));
  }, []);

  let cUUID = currentUserObj?.usersUser?.uuid;
  // console.log("render: cUUID = ", cUUID);

  const onMessage = useCallback(
    (msg) => {
      // console.log("!!!!onMessage; msg = ", msg);
      let newMap = { ...realtimeMap.current };
      let val = newMap[msg.uuid] || {};
      if (msg.t == undefined || msg.uuid == undefined) {
        console.log("mst.t or msg.uuid is not defined");
        return;
      }
      for (let key in msg) {
        if (key == "uuid" || key == "serialNumber") {
          continue;
        }
        // console.log(`iterating: key, val = `, key, val);
        let vv = msg[key];
        if (window.isNaN(vv) == true) {
          val[key] = vv;
        } else {
          val[key] = +vv;
        }
      }
      val.t = +msg.t * 1000;
      val.display_timestamp = +msg.display_timestamp;
      newMap[msg.uuid] = val;
      // console.log("new map = ", newMap);
      // setRealtimeMap(newMap);
      realtimeMap.current = newMap;
      setUpdT(+new Date()); // set update time rerenders
    },
    [cUUID]
  );

  useEffect(() => {
    if (tag == undefined) {
      return;
    }
    // setLoading(true);
    DoctorAPI.getStudyHealthUserSettings(tag).then((d) => {
      dispatch(
        UiActions.changeAlarmParams(
          d == undefined ? {} : d?.ui_settings?.alarms_settings
        )
      );
      dispatch(
        UiActions.changeAlarm(
          d == undefined ? "none" : d?.ui_settings?.alarms_system
        )
      );
    });
  }, [tag]);

  useEffect(() => {
    if (!groupsLoading || (groups && groups.length > 0)) {
      return;
    }
    const startTime = performance.now();
    const matchedGroup = groups.find((x) => x.code === tag);
    // console.log({ useeffect: true, groups, matchedGroup });
    setGroup(matchedGroup);
    GroupsAPI.getGroupByTag(tag).then((gr) => {
      setGroup(gr); // loading point 3
      // console.log({ gr });
      const endTime = performance.now();
      console.debug(
        `setGroup ${tag} loaded in ${(endTime - startTime).toFixed(1)} ms`
      );
    });
  }, [currentUserObj?.usersUser?.email]);

  useEffect(() => {
    let isMounted = true;
    // const controller = new AbortController();
    const startTime = performance.now();
    Object.keys(localStorage).forEach((key) => {
      console.log(key);

      if (key.includes(`${tag}_${PATIENTS_CACHE_LS_KEY}`)) {
        const _time = localStorage.getItem(
          `${tag}_${PATIENTS_CACHE_TIMESTAMP_LS_KEY}`
        );
        const _now = Date.now();
        const cachedDuration = (_now - _time) / 1000;
        // console.log(cachedDuration);
        if (cachedDuration < PATIENTS_CACHE_VALID_SECONDS) {
          // setGroups()
          const lastCachedPatients = localStorage.getItem(
            `${tag}_${PATIENTS_CACHE_LS_KEY}`
          );
          const parsedLastCachedPatients = JSON.parse(lastCachedPatients);
          // console.log(lastCachedGroup);
          console.log(parsedLastCachedPatients);
          setLoading(false); // loading point 2
          setPatients(parsedLastCachedPatients);
          return;
        } else {
        }
      }
    });

    // setLoading(true);
    setLastMetricsLoading(true);
    DoctorAPI.getPatientsByTag(tag).then((arr) => {
      // console.log("DoctorAPI: -->> arr = ", arr);
      setPatients(arr); // where patients set
      ls.set(`${tag}_${PATIENTS_CACHE_LS_KEY}`, arr);
      ls.set(`${tag}_${PATIENTS_CACHE_TIMESTAMP_LS_KEY}`, Date.now());
      // TODO: set patient cache
      setLoading(false); // loading point 2
      const cachedTime = Date.now();
      // console.log({ time });
      const endTime = performance.now();
      console.debug(
        `DoctorDashboardPanel loaded in ${(endTime - startTime).toFixed(1)} ms`
      );
      DoctorAPI.getPatientsSummariesMapByUsersList(arr, isDemo).then((mp) => {
        // console.log("raw metrics map = ", mp);
        setRawMetricsMap(mp);
        setLastMetricsLoading(false);
      });

      DoctorAPI.getPatientsMetricsByTag(tag).then((xArr) => {
        // console.log("getPatientsMetricsByTag", xArr);
        setPatientsWithMetrics(xArr);
      });
      // DoctorAPI.getPatientsRawMetricsMap(tag).then(mp => {
      //     console.log('raw metrics map = ', mp);
      //     setRawMetricsMap(mp);
      //     setLoading(false);
      //     GroupsAPI.getGroupByTag(tag).then(gr => {
      //         setGroup(gr);
      //     })
      // });

      DoctorAPI.getUsersInterfaceSettings(arr).then((arrSettings) => {
        // console.log("arrSettings = ", arrSettings);
        setUsersRangesMap(
          arrSettings.reduce(
            (mp, x) => ({ ...mp, [x.uuid]: x.multi_ranges }),
            {}
          )
        );
      });

      // RealtimeAPI.getInitialMapForGroup(tag).then((mp) => {
      //   console.log('getInitialMapForGroup: mp = ', mp);
      //   realtimeMap.current = mp;
      //   setUpdT(+new Date());
      // });

      if (cUUID != undefined) {
        if (
          centrSubscriptions.current != undefined &&
          centrSubscriptions.current.length == arr.length
        ) {
          return;
        }
        RealtimeAPI.getSettings(cUUID).then((x) => {
          centrSubscriptions.current = [];
          centrifuge.current = new Centrifuge(x.url, {
            token: x.token,
            user: cUUID,
          });
          centrifuge.current.connect();
          // console.debug("RealtimeAPI connect");
          for (let i in arr) {
            let p = arr[i];
            let xSub = centrifuge.current.newSubscription(`realtime:${p.uuid}`);
            // console.log(p.first_name, p.last_name);
            xSub.on("publication", (ctx) => {
              // console.debug(`ctx.data = `, ctx.data);
              const { uuid } = ctx.data;
              // console.log(`ctx = `, ctx);
              const patient = arr.filter((x) => x.uuid === uuid)[0];
              const { first_name, last_name } = patient;
              // console.log({ uuid, name: `${first_name} ${last_name}` });

              CardioHelper.saveRealtimeData(ctx.data);
              // let rd = CardioHelper.getRealtimeData();
              // console.log("realtime data = ", rd);
              onMessage(ctx.data); // what triggers frequent rerender
            });
            xSub.subscribe();
            centrSubscriptions.current.push(xSub);
          }
          try {
            let chName = `gateway:${cUUID}`;
            let gatewaysSubscription =
              centrifuge.current.newSubscription(chName);
            gatewaysSubscription.on("publication", (ctx) => {
              // console.debug(`GATEWAY: ctx.data = `, ctx.data);
            });
            gatewaysSubscription.subscribe();
            centrSubscriptions.current.push(gatewaysSubscription);
            // console.debug("GATEWAY: subscribed: chName = ", chName);
          } catch (exc_g) {}

          centrifuge.current.on("connected", (ctx) => {
            // const suspense = true
            // if (suspense) {return}
            // console.debug("centrifuge: connected!");
            // RealtimeAPI.getLastMessagesMapForUUIDs(centrifuge.current, arr.map(xx => xx.uuid)).then(histPld => {
            //     console.log('histPldMap = ', histPld);
            //     realtimeMap.current = histPld;
            //     setUpdT(+new Date());
            // });

            RealtimeAPI.getInitialMapForGroup(tag).then((mp) => {
              console.debug("getInitialMapForGroup", new Date());
              realtimeMap.current = mp;
              // console.log("RealtimeAPI.getInitialMapForGroup", mp);
              setUpdT(+new Date());
            });
          });
        });
      }

      // CorsanoPatientsAPI.getCorsanoPatientsByTag(tag).then(xArr => {
      //     setCorsanoPatientsMap(xArr.reduce((mp, x) => ({...mp, [x.uuid]: x.name}), {}));
      // });
    });
    // return () => {
    //   controller.abort()
    // }
  }, [currentUserObj?.usersUser?.email, isDemo]);

  useEffect(() => {
    if (alarmId == undefined) {
      return;
    }
    setRangesLoading(true);
    UsersInterfaceAPI.getLazySettings(alarmId)
      .then((x) => {
        console.log("setSelectedRanges: x = ", x);
        setSelectedRanges(x.multi_ranges);
        setRangesLoading(false);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [alarmId]);

  // useEffect(() => {
  //   let isMounted = true;
  //   if (tag === undefined) {
  //     return;
  //   }
  //   GroupsAPI.getComplianceByTag(tag, moment().format("YYYY-MM-DD")).then(
  //     (d) => {
  //       setGroupCompliance(d);
  //     }
  //   );
  //   return () => {
  //     isMounted = false;
  //   };
  // }, [tag]);

  useEffect(() => {
    let isMounted = true;
    if (tag === undefined) {
      return;
    }
    GroupsAPI.getCuffBpByTag(tag).then((d) => {
      if (isMounted) {
        setGroupCuffBp(d);
      }
    });
    return () => {
      isMounted = false;
    };
  }, [tag]);

  useEffect(() => {
    GroupsAPI.getSpotsMeasurements(tag)
      .then((d) => {
        setGroupSpotMeasurements(d);
      })
      .catch((err) => console.error(err));
  }, [tag, spotId]);

  useInterval(() => {
    DoctorAPI.getPatientsMetricsByTag(tag)
      .then((xArr) => {
        setPatientsWithMetrics(xArr);
      })
      .catch((err) => console.error(err));
  }, 40 * 1000);

  useInterval(() => {
    // unmuting logic
    let borderTime = +new Date() - 3 * 60 * 1000;
    if (
      group == undefined ||
      group.alarmsEnabled == true ||
      window.location.href.indexOf("portal") == -1
    ) {
      return;
    }
    let tt = group.alarmsToggleTimestamp || +new Date();
    if (tt < borderTime) {
      // GroupsAPI.updateGroup({
      //     code: tag,
      //     alarmsEnabled: true,
      //     alarmsToggleTimestamp: +new Date()
      // }).then(() => {
      //     GroupsAPI.getGroupByTag(tag).then(gr => {
      //         setGroup(gr);
      //     }).catch(ee => {
      //
      //     });
      // }).catch(ee => {
      //
      // });
    }

    // let dd = {
    //     alarmsEnabled: newAlarmsEnabled,
    //     alarmsToggleTimestamp: +new Date()
    // }
    // let newSilentItems = silentItems.filter(x => (x.timestamp > borderTime));
    // if (silentItems.length == newSilentItems.length){
    //     return;
    // }
    // setSilentItems(newSilentItems);
  }, 5000);

  let changePatientsListOrder = (newOrder) => {
    dispatch(UiActions.changeOrdering(newOrder));
    ls.set("order", newOrder);
  };
  let changePatientsListFilter = (newFilter) => {
    dispatch(UiActions.changeFilter(newFilter));
    ls.set("filter", newFilter);
  };

  // console.log(patients);

  let enhPatients = patients.map((p, i) => {
    let { first_name = "-", last_name = "-", code } = p;
    // console.log(realtimeMap);

    return {
      ...p,
      name:
        first_name == undefined && last_name == undefined
          ? `Patient ${code}`
          : `${first_name || ""} ${last_name || ""}`,
      realtime: realtimeMap.current[p.uuid],
    };
  });

  let filteredPatients = enhPatients.filter((p) => {
    let { name } = p;
    if (searchText == undefined || searchText == "") {
      return true;
    }
    return name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
  });

  let patientsBraceletsMap = getBraceletsMap(bracelets);
  let avBraceletsOptions = bracelets
    .filter(
      (x) =>
        x.patientUUID == "" ||
        x.patientUUID == undefined ||
        x.patientUUID == braceletModeUserId
    )
    .map((x) => ({
      value: x.id,
      label: x.name,
      patientUUID: x.patientUUID,
    }));

  // console.log('-> enhPatients = ', enhPatients);
  // console.log("realtimeMap.current = ", realtimeMap.current);
  // console.log("enhPatients = ", enhPatients);
  // console.log('avBraceletsOptions = ', avBraceletsOptions);
  // console.log('corsanoPatientsMap = ', corsanoPatientsMap);

  if (loading == true) {
    return (
      <div>
        {/* {isPortalOrStudy ? null : <h1>DoctorDashboardPanel LOADING</h1>} */}
        {theme === "dark" ? (
          <BulletList backgroundColor={"#333"} foregroundColor={"#999"} />
        ) : (
          <BulletList />
        )}
      </div>
    );
  }

  // console.log("render: patentsWithMetrics = ", patentsWithMetrics);
  let realMetricsMap = patentsWithMetrics
    .filter((x) => x?.rawMetrics != undefined)
    .reduce(
      (mp, x) => ({
        ...mp,
        [x.uuid]: {
          ...(x?.rawMetrics || {}),
          af_burden: x.af_burden,
        },
      }),
      {}
    );
  // console.log("-> realMetricsMap = ", realMetricsMap);

  let unlinkPatient = patients.filter((x) => x.uuid == unlinkId)[0];
  let hidingPatient =
    hideId == undefined ? undefined : patients.find((x) => x.uuid == hideId);

  let isMutedAll = group?.alarmsEnabled != true;

  // console.log({ middleGroup: group, groups });

  if (group === undefined) {
    return (
      <div>
        {/* {isPortalOrStudy ? null : <h1>group == undefined Loading </h1>} */}
        {theme === "dark" ? (
          <Code backgroundColor={"#333"} foregroundColor={"#999"} />
        ) : (
          <Code />
        )}
      </div>
    );
  }

  if (group?.is_access_denied == true) {
    return (
      <Wrapper>
        <TopPlaceholder>Access denied</TopPlaceholder>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Detector
        render={(x) => {
          return null;
        }}
        onChange={(a) => {
          setHasInternet(a);
        }}
      />

      <TopPlaceholder>
        <TopLeft>
          <TopHeading>
            {isGwMode == true ? (
              "Patients"
            ) : (
              <span>
                {window.location.href.indexOf("af.") > -1 ? null : (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      CommonHelper.linkTo("/");
                    }}
                  >
                    {getLabelsInfoData().group_name}{" "}
                  </span>
                )}
                {window.location.href.indexOf("portal") > -1 ||
                window.location.href.indexOf("af.") > -1
                  ? " "
                  : " / "}{" "}
                {group?.name}{" "}
                {window.location.href.indexOf("portal") > -1
                  ? null
                  : `[${tag}]`}
              </span>
            )}
          </TopHeading>
          <TopLeftControlsPlaceholder></TopLeftControlsPlaceholder>
        </TopLeft>

        <TopRight>
          {window.location.href.indexOf("portal") == -1 ? null : (
            <CGroup
              onClick={async () => {
                let newAlarmsEnabled = !(group?.alarmsEnabled == true);
                let dd = {
                  alarmsEnabled: newAlarmsEnabled,
                  alarmsToggleTimestamp: +new Date(),
                };
                await GroupsAPI.updateGroup({
                  code: tag,
                  ...dd,
                });
                let gr = await GroupsAPI.getGroupByTag(tag);
                setGroup(gr);
                // if (isMutedAll == true){
                //     // ls(muteKey, []);
                //     // setUpdT(+new Date());
                // }else{
                //     // ls(muteKey, allPatientsIds);
                //     // setUpdT(+new Date());
                // }
              }}
            >
              <Square>
                {/*<CImg src={dismiss_icon}*/}
                {/*      style={{*/}
                {/*          width: 34,*/}
                {/*          height: 34*/}
                {/*      }}*/}
                {/*/>*/}
                <Bell type={isMutedAll ? "no_alarm" : "bell"} />
              </Square>
              <Label>
                {isMutedAll ? "all audio paused" : "pause all audio"}
              </Label>
            </CGroup>
          )}

          {getLabelsInfoData().vouchers_enabled == false ? null : (
            <CGroup
              onClick={() => {
                setNewVoucherModalVisible(true);
              }}
            >
              <Square>
                <CImg
                  className="voucher-icon"
                  src={numberImg}
                  style={{
                    width: 34,
                    height: 34,
                  }}
                />
              </Square>
              <Label>Vouchers</Label>
            </CGroup>
          )}

          {getLabelsInfoData().stats_enabled == false ||
          window.location.href.indexOf("portal") > -1 ? null : (
            <CGroup
              onClick={() => {
                setStatsVisible(true);
              }}
            >
              <Square>
                <CImg
                  src={tableImg}
                  style={{
                    width: 28,
                    height: 28,
                  }}
                />
              </Square>
              <Label>Stats</Label>
            </CGroup>
          )}

          {getLabelsInfoData().live_enabled == false ? null : (
            <CGroup
              onClick={() => {
                window
                  .open(`https://live.cardiomood.com/x/${tag}`, "_blank")
                  .focus();
              }}
            >
              <Label>Live</Label>
            </CGroup>
          )}

          {getLabelsInfoData().export_enabled == false ? null : (
            <CGroup
              onClick={() => {
                setExportModalVisible(true);
              }}
            >
              <Square>
                <CImg src={archiveImg} />
              </Square>
              <Label>Export</Label>
            </CGroup>
          )}

          {getLabelsInfoData().invite_enabled == false ? null : (
            <EditButton
              style={{ marginRight: 15 }}
              onClick={() => {
                setInviteModalOpen(true);
              }}
            >
              INVITE
            </EditButton>
          )}

          {hasPortal ? null : (
            <CGroup
              // style={{ display: "none" }}
              onClick={() => {
                setFilterModelVisible(true);
              }}
            >
              <Square>
                <CImg src={filterImg} />
              </Square>
              <Label>Filters</Label>
            </CGroup>
          )}

          <InputPlaceholder>
            <SearchInput
              value={searchText}
              onChange={(s) => {
                setSearchText(s);
              }}
            />
          </InputPlaceholder>
        </TopRight>
      </TopPlaceholder>

      {/* <ListPlaceholder key={`d_${updT}`}> */}
      <ListPlaceholder>
        <PatientsListTool
          tag={tag}
          hiddenPatientsIds={group?.hiddenPatientsIds || []}
          sortByName={
            // window.location.href.indexOf("portal.corsano") == -1
            true
          }
          order={order}
          filter={filter}
          lastMetricsLoading={lastMetricsLoading}
          alarmsEnabled={group?.alarmsEnabled}
          hasInternet={hasInternet}
          isDemo={isDemo}
          getUserRanges={(uuid) => {
            return getUserRangesSettings(uuid, usersRangesMap);
          }}
          metricsMap={rawMetricsMap}
          realMetricsMap={realMetricsMap}
          devicesMap={devicesMap}
          patients={filteredPatients}
          groupCompliance={groupCompliance}
          groupCuffBp={groupCuffBp}
          groupSpotMeasurements={groupSpotMeasurements}
          onPatientClick={(p) => {
            // console.log("--->>> p = ", p);
            if (window.location.href.indexOf("portal") > -1) {
              CommonHelper.linkTo(`/departments/${tag}/user/${p.uuid}`);
            } else {
              CommonHelper.linkTo(`/studies/${tag}/user/${p.uuid}`);
            }
          }}
          onPatientAlarmClick={(p) => {
            setAlarmId(p.uuid);
          }}
          onEnlargeQRCode={(code) => {
            setQrCodeModelVisible(code);
          }}
          onPatientSpotMeasurementsClick={(p) => {
            setSpotId(p.uuid);
          }}
          patientsBraceletsMap={patientsBraceletsMap}
          bracelets={bracelets}
          onPatientBraceletClick={(p) => {
            setBraceletModeUserId(p.uuid);
          }}
          onUnlinkClick={(p) => {
            // console.log("onUnlinkClick: p = ", p);
            setUnlinkId(p?.uuid);
          }}
          onHide={(p) => {
            // console.log("onUnlinkClick: p = ", p);
            // setUnlinkId(p?.uuid);
            setHideId(p?.uuid);
          }}
          onUnhide={async (p) => {
            let oldIds = group?.hiddenPatientsIds || [];
            let newHiddenPatientsIds = oldIds.filter((x) => x != p?.uuid);
            await GroupsAPI.updateGroup({
              code: tag,
              hiddenPatientsIds: newHiddenPatientsIds,
            });
            setGroup({
              ...group,
              hiddenPatientsIds: newHiddenPatientsIds,
            });
            toast.success("Saved");
          }}
          onBraceletChanged={async () => {
            let arr = await await DoctorAPI.getPatientsByTag(tag);
            setPatients(arr);
          }}
          officialVersions={officialVersions}
        />
      </ListPlaceholder>

      {!filterModelVisible ? null : (
        <NiceModal
          style={{ display: "none" }}
          onClose={() => {
            setFilterModelVisible(false);
          }}
        >
          <ExportInnerPlaceholder>
            <DoctorOrderFilterPanel
              onChangeOrder={changePatientsListOrder}
              onChangeFilter={changePatientsListFilter}
              currentOrder={order}
              currentFilter={filter}
            />
            {/* <Select
              options={RANKING_OPTIONS}
              placeholder={"Ranking"}
              defaultValue={RANKING_OPTIONS.find((x) => x.value === order)}
              onChange={(s) => {
                const newOrder = s.value;
                changePatientsListOrder(newOrder);
              }}
            /> */}
          </ExportInnerPlaceholder>
        </NiceModal>
      )}

      {exportModalVisible == false ? null : (
        <NiceModal
          onClose={() => {
            setExportModalVisible(false);
          }}
        >
          <ExportInnerPlaceholder>
            <OptimizedDoctorExportPanel
              isDemo={isDemo}
              patients={patients}
              {...exportRanges}
              // exportTimestamp={statisticsSelectedDayTimestamp}
              exportName={`export.zip`}
            />
          </ExportInnerPlaceholder>
        </NiceModal>
      )}

      {qrCodeModelVisible.length > 0 ? (
        <NiceModal
          onClose={() => {
            setQrCodeModelVisible("");
          }}
        >
          <QRCodePopupPlaceholder className="qrcode-popup">
            <h3>QR Code for Patient {qrCodeModelVisible}</h3>
            <QRCode
              style={{ marginBottom: "1rem" }}
              value={`https://install.corsano.com/?voucher=${qrCodeModelVisible}`}
            />
          </QRCodePopupPlaceholder>
        </NiceModal>
      ) : null}

      {statsVisible == false ? null : (
        <NiceModal
          onClose={() => {
            setStatsVisible(false);
          }}
        >
          <StatsInnerPlaceholder>
            <GroupStatsTableTool tag={tag} />
          </StatsInnerPlaceholder>
        </NiceModal>
      )}

      {spotId === undefined ? null : (
        <NiceModal
          onClose={() => {
            setSpotId(undefined);
          }}
        >
          <AlarmsInnerPlaceholder className="alarm-placeholder">
            <h2
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span style={{ marginRight: 10 }} className="patient-range-title">
                NEWS2 Inputs
                <Spin visible={rangesLoading} />
              </span>
            </h2>

            <PatientOtherNewsTool
              uuid={spotId}
              map={groupSpotMeasurements}
              setSpotId={setSpotId}
            />
          </AlarmsInnerPlaceholder>
        </NiceModal>
      )}

      {alarmId == undefined ? null : (
        <NiceModal
          onClose={() => {
            setAlarmId(undefined);
          }}
        >
          <AlarmsInnerPlaceholder className="alarm-placeholder">
            <h2
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span style={{ marginRight: 10 }} className="patient-range-title">
                Alarms
                <Spin visible={rangesLoading} />
              </span>
              <img
                className="alarm-logo"
                src={alarms_icon}
                style={{ height: 40, marginRight: 10 }}
              />
            </h2>

            <NewPatientRangesTool
              ranges={selectedRanges}
              loading={rangesLoading}
              saving={rangesSaving}
              onChange={(newRanges) => {
                // console.log('NewPatientRangesTool: newRanges = ', newRanges);
                setSelectedRanges(newRanges);
              }}
            />
            {/*<PatientRangesTool*/}
            {/*    ranges={selectedRanges}*/}
            {/*    onChange={newRanges => {*/}
            {/*        setSelectedRanges(newRanges);*/}
            {/*    }}*/}
            {/*/>*/}

            <div>
              <Button
                style={{ display: "none" }}
                onClick={async () => {
                  let arrSettings_ = await DoctorAPI.getUsersInterfaceSettings(
                    patients
                  );

                  // console.log("arrSettings_", arrSettings_);
                }}
              >
                TEST
              </Button>
              <Button
                className="range-button"
                onClick={async () => {
                  if (
                    window.confirm(
                      "Please confirm you want to change the limits."
                    ) == false
                  ) {
                    return;
                  }
                  setRangesLoading(true);
                  // await UsersInterfaceAPI.updateUserSettings({uuid: alarmId, ranges: selectedRanges})
                  setRangesSaving(true);
                  await UsersInterfaceAPI.updateUserSettings({
                    uuid: alarmId,
                    multi_ranges: selectedRanges,
                  }).catch((e) => {
                    console.error(e);
                  });
                  setRangesLoading(false);
                  setRangesSaving(false);
                  let arrSettings_ = await DoctorAPI.getUsersInterfaceSettings(
                    patients
                  );

                  setUsersRangesMap(
                    arrSettings_.reduce(
                      (mp, x) => ({
                        ...mp,
                        [x.uuid]: x.multi_ranges,
                      }),
                      {}
                    )
                  );
                  setAlarmId(undefined);
                  try {
                    let silentItems = (ls(`silentItems_${tag}`) || []).filter(
                      (x) => x.uuid != alarmId
                    );
                    ls(`silentItems_${tag}`, silentItems);
                  } catch (ee) {}
                  window.location.reload();
                }}
              >
                <Spin visible={rangesLoading} className="range-spin" />
                {rangesLoading == true ? null : "Save"}
              </Button>
            </div>
          </AlarmsInnerPlaceholder>
        </NiceModal>
      )}

      {braceletModeUserId == undefined ? null : (
        <NiceModal
          onClose={() => {
            setBraceletModeUserId(undefined);
          }}
        >
          <AlarmsInnerPlaceholder style={{ minHeight: `calc(90vh - 120px)` }}>
            <h2>Select bracelet for this user</h2>

            <Select
              isClearable={true}
              options={avBraceletsOptions}
              value={avBraceletsOptions.filter(
                (x) => x.patientUUID == braceletModeUserId
              )}
              onChange={async (x) => {
                if (x == undefined) {
                  let br = bracelets.filter(
                    (x) => x.patientUUID == braceletModeUserId
                  )[0];
                  if (br != undefined) {
                    await dispatch(
                      braceletsActions.updateBracelet(
                        currentUserObj?.usersUser?.uuid,
                        {
                          id: br.id,
                          patientUUID: "",
                        }
                      )
                    );
                  }
                } else {
                  let brId = x.value;
                  await dispatch(
                    braceletsActions.updateBracelet(
                      currentUserObj?.usersUser?.uuid,
                      {
                        id: brId,
                        patientUUID: braceletModeUserId,
                      }
                    )
                  );
                }
              }}
            />
          </AlarmsInnerPlaceholder>
        </NiceModal>
      )}

      {inviteModalOpen == false ? null : (
        <NiceModal
          onClose={() => {
            setInviteModalOpen(false);
          }}
        >
          <VouchersInnerPlaceholder>
            <h3>Invitation link</h3>
            <div>
              <input
                style={{
                  outline: "none",
                  boxSizing: "border-box",
                  width: "100%",
                }}
                value={`https://${window.location.host}/#/join/${tag}`}
              />
            </div>
            <p>Send link above to existing user to invite into this group.</p>
          </VouchersInnerPlaceholder>
        </NiceModal>
      )}

      {newVoucherModalVisible == false ? null : (
        <NiceModal
          onClose={() => {
            setNewVoucherModalVisible(false);
          }}
        >
          <VouchersInnerPlaceholder>
            <Tabs
              center={true}
              isHCPAdmin={isHCPAdmin}
              // defaultTabIndex={isHCPAdmin ? 0 : 1}
              defaultTabIndex={0}
              tabs={[
                {
                  label: "New Voucher",
                  content: (
                    <TabItem>
                      <NewVouchersPanel
                        tag={tag}
                        isGwMode={isGwMode}
                        isCorsanoDemo={isCorsanoDemo}
                        onAdded={async () => {
                          let arr = await DoctorAPI.getPatientsByTag(tag);
                          setPatients(arr);
                          setNewVoucherModalVisible(false);
                        }}
                      />
                    </TabItem>
                  ),
                },
                {
                  label: "Patient Letter",
                  content: (
                    <TabItem>
                      <VoucherLetterTool
                        code={tag}
                        isHCPAdmin={isHCPAdmin}
                        doctorUUID={currentUserObj?.usersUser?.uuid}
                        voucherCodes={filteredPatients
                          .filter((x) => x.code != undefined)
                          .map((x) => x.code)}
                      />
                    </TabItem>
                  ),
                },
                {
                  label: "Export Vouchers",
                  content: (
                    <TabItem>
                      <h2>Export Vouchers</h2>
                      <div
                        style={{
                          marginBottom: "1.5rem",
                        }}
                      >
                        Vouchers are generated for each participant for
                        anonymity. Find below new voucher codes for each
                        participant and use them in the Corsano App.
                      </div>
                      <Textarea
                        // $mode={"test"}
                        value={filteredPatients
                          .filter((x) => x.code != undefined)
                          .map((x) => x.code)
                          .join("\n")}
                        onChange={(evt) => {}}
                        rows={15}
                      />
                      <div>
                        <ExportCsvSpan
                          onClick={() => {
                            let csvString = filteredPatients
                              .filter((x) => x.code != undefined)
                              .map((x) => x.code)
                              .join("\n");
                            CommonHelper.downloadFile(
                              `vouchers_${tag}.txt`,
                              csvString
                            );
                          }}
                        >
                          Download vouchers
                        </ExportCsvSpan>
                      </div>
                    </TabItem>
                  ),
                },
                {
                  label: "QR Codes",
                  content: (
                    <TabItem>
                      <QrCodesList
                        patientsList={filteredPatients.filter(
                          (x) => x.code != undefined
                        )}
                      />
                    </TabItem>
                  ),
                },
              ]}
            />
          </VouchersInnerPlaceholder>
        </NiceModal>
      )}

      {unlinkId == undefined ? null : (
        <NiceConfirm
          loading={unlinking}
          heading={CommonHelper.getPatientHeadingString(unlinkPatient)}
          subHeading={
            "You are about to remove this patient from the department. Are you sure?"
          }
          onConfirm={async () => {
            setUnlinking(true);
            await DoctorAPI.unlinkPatientFromGroup(unlinkId);
            let arr = await DoctorAPI.getPatientsByTag(tag);
            setUnlinking(false);
            setUnlinkId(undefined);
            setPatients(arr);
          }}
          onCancel={() => {
            setUnlinkId(undefined);
          }}
        />
      )}

      {hideId == undefined ? null : (
        <NiceConfirm
          loading={hiding}
          heading={CommonHelper.getPatientHeadingString(hidingPatient)}
          subHeading={"Are you sure you want to hide this patient?"}
          onConfirm={async () => {
            setHiding(true);

            let oldIds = group?.hiddenPatientsIds || [];
            let newHiddenPatientsIds = oldIds.concat(hideId);
            await GroupsAPI.updateGroup({
              code: tag,
              hiddenPatientsIds: newHiddenPatientsIds,
            });
            setGroup({
              ...group,
              hiddenPatientsIds: newHiddenPatientsIds,
            });
            // await DoctorAPI.hidePatientFromGroup(hideId);
            //
            // let arr = await DoctorAPI.getPatientsByTag(tag);

            setHiding(false);
            setHideId(undefined);
            toast.success("Saved");
            // setPatients(arr);
          }}
          onCancel={() => {
            setHideId(undefined);
          }}
        />
      )}

      {isDebugMode() == false ? null : (
        <div>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              let d = CardioHelper.getRealtimeData();
              for (let uuid in d) {
                let arr = d[uuid];
                let resArr = [];
                let head = `Patient ID;WP timestamp;Bracelet Timestamp;Delta;PR;Spo2;BRPM;Temperature;Battery`;
                let times = [];
                for (let i in arr) {
                  let x = arr[i];
                  let t1 = x.initial_package_arriving_timestamp;
                  let t2 = +x?.initial_package?.t * 1000;
                  let delta = t1 - t2;
                  if (x.initial_package_arriving_timestamp == undefined) {
                    continue;
                  }
                  if (
                    times.indexOf(x.initial_package_arriving_timestamp) > -1
                  ) {
                    continue;
                  }
                  resArr.push(
                    `${uuid};${x.initial_package_arriving_timestamp};${
                      +x?.initial_package?.t * 1000
                    };${delta};${x.heart_rate};${x.spo2};${
                      x.respiration_rate
                    };${x.temperature};${x.battery}`
                  );
                  // arr[i] = `${uuid};${x.initial_package_arriving_timestamp};${(+x?.initial_package?.t) * 1000};${delta};${x.heart_rate};${x.spo2};${x.respiration_rate};${x.temperature};${x.battery}`;
                  times.push(x.initial_package_arriving_timestamp);
                }
                // console.log("resArr = ", resArr);
                // let str = arr.join('\n');
                let str = resArr.join("\n");
                CommonHelper.downloadFile(
                  `realtime_${uuid}.csv`,
                  `${head}\n${str}`
                );
              }
              // CommonHelper.downloadFile(`vouchers_${tag}.txt`, csvString);
            }}
          >
            export realtime test
          </span>
        </div>
      )}
    </Wrapper>
  );
}

const ExportCsvSpan = styled.span`
  cursor: pointer;
  opacity: 0.7;

  :hover {
    opacity: 1;
  }
`;

const VouchersInnerPlaceholder = styled.div`
  width: 720px;
  box-sizing: border-box;
  max-height: calc(80vh - 30px);
  overflow-y: auto;
  @media (max-width: 760px) {
    width: 100%;
  }
`;

const ExportInnerPlaceholder = styled.div`
  width: 520px;
  box-sizing: border-box;
  max-height: calc(80vh - 30px);
  //height: calc(80vh - 30px);
  //   overflow-y: auto;
  @media (max-width: 760px) {
    width: 100%;
  }
`;

const QRCodePopupPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  width: 520px;
  box-sizing: border-box;
  max-height: calc(80vh - 30px);
  justify-content: center;
  align-items: center;
  //height: calc(80vh - 30px);
  //   overflow-y: auto;
  @media (max-width: 760px) {
    width: 100%;
  }
`;

const StatsInnerPlaceholder = styled.div`
  width: 1200px;
  box-sizing: border-box;
  max-height: calc(90vh - 30px);
  //height: calc(80vh - 30px);
  overflow-y: auto;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const AlarmsInnerPlaceholder = styled.div`
  width: 620px;
  box-sizing: border-box;
  max-height: calc(90vh - 30px);
  //height: calc(80vh - 30px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 760px) {
    width: 100%;
  }
`;

const CGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-right: 30px;
`;

const CImg = styled.img`
  width: 24px;
  height: 24px;
`;

const InputPlaceholder = styled.div`
  width: 210px;
`;

const TopRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = styled.div`
  color: #8798ad;
  font-size: 18px;
  line-height: 23px;
`;

export const Square = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(135, 152, 173, 0.1);
  filter: drop-shadow(0px 7px 64px rgba(0, 0, 0, 0.07));
  border-radius: 8px;
  width: 40px;
  height: 40px;
  margin-right: 11px;
`;

const TopLeftControlsPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
`;

const TopHeading = styled.div`
  color: #000f4b;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: 1px;
`;

const TopLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
`;

const ListPlaceholder = styled.div`
  margin-top: 30px;
`;

const RANKING_OPTIONS = [
  {
    value: "alphabetical",
    label: "Alphabetical",
  },
  {
    value: "compliance",
    label: "Compliance",
  },
  {
    value: "hidden",
    label: "Hidden",
  },
];
