import InfoToolBadge from "../../../info/tools/InfoToolBadge";
import PatientEmographyPanel from "../PatientEmographyPanel";
import moment from "moment";
import { isDebugMode } from "../../../../helpers/UiHelper";
import ContinuousMeasurementsPanel from "../ContinuousMeasurementsPanel";

export default function BiozPanel(props) {
  const { id, date } = props;
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <div style={{ marginTop: -12, marginRight: 10, marginBottom: 10 }}>
          <InfoToolBadge type={"EMOGRAPHY"} />
        </div>
      </div>

      <PatientEmographyPanel
        from={+moment(date).startOf("day")}
        to={+moment(date).endOf("day")}
        uuid={id}
      />

      {isDebugMode() === false &&
      window.location.href.indexOf("wiff") === -1 ? null : (
        <div>
          <ContinuousMeasurementsPanel
            type={"bioz"}
            userId={id}
            from={+moment(date).startOf("day")}
            to={+moment(date).endOf("day")}
          />
        </div>
      )}
    </div>
  );
}
