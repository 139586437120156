import styled from "styled-components";
export const ComplianceHeading = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #000f4b;
  margin-bottom: 10px;
`;

export const DimsPlaceholder = styled.div`
  position: absolute;
  top: -5px;
  left: 0px;
  opacity: 0.7;
  font-size: 12px;
`;

export const LogsInnerPlaceholder = styled.div`
  width: 560px;
  box-sizing: border-box;
`;

export const NotesPlaceholder = styled.div`
  margin-top: 40px;
`;

export const SessionsPlaceholder = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
`;

export const TabHeading = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #000f4b;
  // margin-bottom: 30px;
`;

export const ContentPlaceholder = styled.div`
  padding: 20px;
  background: white;
  border: 2px solid rgba(135, 152, 173, 0.1);
`;

export const Wrapper = styled.div``;

export const TabNamesPlaceholder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TabNameItem = styled.div`
  flex: 1;
  text-align: center;
  color: ${(props) =>
    props.selected === true
      ? props.theme === "dark"
        ? "black"
        : "white"
      : props.theme === "dark"
      ? "white"
      : "gray"};
  background-color: ${(props) =>
    props.selected === true
      ? props.theme === "dark"
        ? "#f3f3f3"
        : "#000F4B;"
      : "transparent"};
  cursor: ${(props) => (props.selected === true ? "default" : "pointer")};
  font-weight: ${(props) => (props.selected === true ? "bold" : "bold")};

  font-size: 18px;
  line-height: 18px;

  box-sizing: border-box;
  border-radius: 4px 4px 0px 0px;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 900px) {
    font-size: 14px;
  }

  @media (max-width: 720px) {
    font-size: 12px;
  }
`;

export const SquareForImg = styled.div`
  border: 1px solid rgba(135, 152, 173, 0.1);
  filter: drop-shadow(0px 7px 64px rgba(0, 0, 0, 0.07));
  border-radius: 8px;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  margin-right: 11px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TopPlaceholder = styled.div``;

export const ModeSwitcherItem = styled.div`
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};
  cursor: ${(props) => (props.selected ? "default" : "pointer")};
  text-decoration: ${(props) => (props.selected ? "underline" : "none")};
  margin-left: 5px;
  margin-right: 5px;
`;

export const ChartContainer = styled.div`
  width: 100%;
  height: 380;
  // border: 1px solid blue;
`;

export const ChartTitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  // border: 1px solid blue;
`;
