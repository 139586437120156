import axios from "axios";
const { DOCTORS_API_ENDPOINT = "https://api.study-integration.corsano.com" } =
  env;

const IntegrationAPI = {
  async saveIntegrationSettings(data) {
    // console.log("createExportJob: data = ", data);
    try {
      let pld = (
        await axios.post(`${DOCTORS_API_ENDPOINT}/save-integration-settings`, {
          ...data,
        })
      ).data.result;
      return pld;
    } catch (e) {
      console.error(e);
    }
  },
};

export default IntegrationAPI;