import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import ls from "local-storage";
import moment from "moment";
import uuid from "uuid";
import { Map, Set } from "immutable";
import styled from "styled-components";
import { Code } from "react-content-loader";
import Sidebar from "arui-feather/sidebar";
import Spin from "arui-feather/spin";
import axios from "axios";

import useDimensions from "react-use-dimensions";

import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  Bar,
  BarChart,
  ReferenceLine,
} from "recharts";
import "./style.css";
import SimpleNoDataMessageTool from "../../sleep/tools/SimpleNoDataMessageTool";
import DrawHelper from "../../../helpers/DrawHelper";

export default function SimpleTemperatureDeviationSlotsChart(props) {
  const {
    slots = [],
    min = 0,
    avg_temp_sk1,
    max = +new Date() + 86400000,
    dayTimestamp = +new Date(),
    noDataText = "...",
    connectNulls = false,

    preserveMinMax = true,
    dataKey = "bpm",
  } = props;
  const [ref, { x, y, width, height }] = useDimensions();
  const theme = ls.get("theme");
  let fPoints = slots.filter((x) => +x.t >= +min && +x.t < +max);

  if (fPoints.length < 2 || avg_temp_sk1 == undefined) {
    return (
      <SimpleNoDataMessageTool
        message={"Sorry, there is no temperature data for this day."}
      />
    );
  }

  // let value = slots.map(xx => (xx.temp_sk_1));

  // console.log('transformed points = ', fPoints);

  let sum = 0,
    k = 0;
  for (let i in slots) {
    let p = slots[i];
    if (p.temp_sk2 == undefined || p.temp_sk2 == 0) {
      continue;
    }
    sum = +sum + +p.temp_sk2;
    k++;
  }
  let avr = k == 0 ? 0 : (1.0 * sum) / k;

  let deviationPoints = slots
    .filter(
      (xx) => xx != undefined && xx.temp_sk2 != undefined && xx.temp_sk2 != 0
    )
    .map((x) => ({
      ...x,
      d_temp: x.temp_sk2 - avr,
    }));

  // console.log("deviationPoints = ", deviationPoints);

  return (
    <Wrapper ref={ref}>
      {/*<LineChart width={width} height={height} data={deviationPoints} connectNulls={true}*/}
      {/*           margin={{top: 5, right: 0, left: -20, bottom: 5}}>*/}
      {/*    <XAxis dataKey="date" tickCount={25}*/}
      {/*           domain={[min, max]}*/}

      {/*        // tickFormatter={a => {*/}
      {/*        //     return (moment(a).format('HH:mm'))*/}
      {/*        // }}*/}

      {/*    />*/}
      {/*    <YAxis/>*/}
      {/*    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>*/}
      {/*    <Line type="monotone" dataKey={'d_temp'} stroke="#D92A3E" strokeWidth={2} animationDuration={1}*/}
      {/*          dot={false}/>*/}
      {/*</LineChart>*/}

      <BarChart
        width={width}
        height={height}
        data={deviationPoints}
        margin={{
          top: 5,
          right: 10,
          left: -20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          stroke={theme === "dark" ? "white" : ""}
          dataKey="date"
          tickCount={25}
          domain={[min, max]}

          // tickFormatter={a => {
          //     return (moment(a).format('HH:mm'))
          // }}
        />
        <YAxis stroke={theme === "dark" ? "white" : ""} />
        <ReferenceLine y={0} stroke="#000" />
        <Bar dataKey="d_temp" fill="#FF962A" />
      </BarChart>
    </Wrapper>
  );
}

const NoDataPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const NoDataText = styled.div`
  font-size: 24px;
  color: grey;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;
