import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import uuid from "uuid";
import { Map, Set } from "immutable";
import styled from "styled-components";
import NiceModal from "../../modals/NiceModal";
import ItemForm from "./ItemForm";
import Spin from "arui-feather/spin";
import pencilImage from "../../notes/panels/clarity_edit-solid.svg";

import trashImage from "../../../assets/images/trash.svg";
import NiceConfirm from "../../modals/NiceConfirm";
import UiHelper from "../../../helpers/UiHelper";
import ls from "local-storage";

export default function DictionaryEditorTool(props) {
  const {
    loading = false,
    items = [],
    onChange = (arr) => {},
    addText = "Add item",
    nameHeading = "Name",
    heading = "",
    hasTranslations = true,
    hasDescription = true,
  } = props;

  // console.log("DictionaryEditorTool: items = ", items);

  const [selectedId, setSelectedId] = useState(undefined);
  const [createOpen, setCreateOpen] = useState(false);
  const [deletingId, setDeletingId] = useState(undefined);
  const theme = ls.get("theme");

  let selectedItem =
    selectedId == undefined
      ? undefined
      : items.filter((x) => x.id == selectedId)[0];
  let deletingItem =
    deletingId == undefined
      ? undefined
      : items.filter((x) => x.id == deletingId)[0];

  return (
    <Wrapper>
      <TopPlaceholder>
        <TopLeft>
          <TopHeading className="setting-top-heading">{`${heading}`}</TopHeading>
        </TopLeft>
        <TopRight>
          {loading == true ? (
            <Spin visible={true} />
          ) : (
            <Button
              onClick={() => {
                setCreateOpen(true);
              }}
            >
              {addText}
            </Button>
          )}
        </TopRight>
      </TopPlaceholder>

      <ItemsPlaceholder>
        {items.map((a, i) => {
          return (
            <BraceletItem key={a.id}>
              <BraceletInner className="misc-item">
                <BrLeft>
                  <BrName>
                    {a?.name}
                    {a?.description == undefined ||
                    a?.description == "" ? null : (
                      <div style={{ fontSize: 12, opacity: 0.4 }}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: UiHelper.sanitize(a.description).replace(
                              /\n/g,
                              "<br/>"
                            ),
                          }}
                        ></div>
                      </div>
                    )}
                  </BrName>
                </BrLeft>

                <BrRight>
                  <ContrItem>
                    <NoteEditIcon
                      style={{ height: 25 }}
                      src={trashImage}
                      onClick={() => {
                        setDeletingId(a?.id);
                      }}
                    />
                  </ContrItem>
                  <ContrItem>
                    <NoteEditIcon
                      src={pencilImage}
                      onClick={() => {
                        setSelectedId(a?.id);
                      }}
                    />
                  </ContrItem>
                </BrRight>
              </BraceletInner>
            </BraceletItem>
          );
        })}
      </ItemsPlaceholder>

      {deletingItem == undefined ? null : (
        <NiceConfirm
          heading={deletingItem?.name}
          subHeading={"Are you sure you want to delete this item?"}
          onConfirm={() => {
            let newItems = items.filter((x) => x.id != deletingItem?.id);
            onChange(newItems);
            setDeletingId(undefined);
          }}
          onCancel={() => {
            setDeletingId(undefined);
          }}
        />
      )}

      {createOpen == false ? null : (
        <NiceModal
          onClose={() => {
            setCreateOpen(false);
          }}
        >
          <InnerPlaceholder>
            <ItemForm
              nameHeading={nameHeading}
              hasDescription={hasDescription}
              hasTranslations={hasTranslations}
              loading={loading}
              onSave={(d) => {
                let dd = {
                  ...d,
                  id: `t-${+new Date()}`,
                };
                let newItems = items.concat([dd]);
                onChange(newItems);
                setCreateOpen(false);
              }}
            />
          </InnerPlaceholder>
        </NiceModal>
      )}

      {selectedItem == undefined ? null : (
        <NiceModal
          onClose={() => {
            setSelectedId(undefined);
          }}
        >
          <InnerPlaceholder>
            <ItemForm
              nameHeading={nameHeading}
              hasDescription={hasDescription}
              hasTranslations={hasTranslations}
              loading={loading}
              {...selectedItem}
              onSave={(d) => {
                let updSelItem = { ...selectedItem, ...d };
                let newItems = items.map((x) =>
                  x.id == selectedItem?.id ? updSelItem : x
                );
                console.log("onSave: newItems = ", newItems);
                onChange(newItems);
                setSelectedId(undefined);
              }}
            />
          </InnerPlaceholder>
        </NiceModal>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const InnerPlaceholder = styled.div`
  width: 620px;
  box-sizing: border-box;
  max-height: 80vh;
  overflow-y: auto;
  @media (max-height: 640px) {
    width: 100%;
  }
`;

const TopHeading = styled.div`
  color: #000f4b;
  font-weight: bold;
  font-size: 26px;
  line-height: 35px;
  letter-spacing: 1px;
`;

const TopPlaceholder = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TopLeft = styled.div``;

const TopRight = styled.div``;

const NoteEditIcon = styled.img`
  height: 22px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

export const Button = styled.div`
  width: 200px;

  background: #147aff;
  border-radius: 5px;
  height: 48px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;

  font-weight: 800;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;

  :hover {
    opacity: 0.85;
  }
`;

const AddSpan = styled.span`
  cursor: pointer;
  opacity: 0.5;

  :hover {
    opacity: 1;
  }
`;

const BottomPlaceholder = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ItemLeft = styled.div`
  flex: 1;
`;

const ItemRight = styled.div`
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Item = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

const Description = styled.div`
  opacity: 0.8;
`;

const ItemsPlaceholder = styled.div``;

const BraceletInner = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const BrLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BrName = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
`;

const BrRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContrItem = styled.div`
  margin-left: 15px;
`;

const normalBorder = `1px solid rgba(135, 152, 173, 0.15)`;
const dangerBorder = `2px solid #FA4646;`;

const BraceletItem = styled.div`
  border-radius: 4px;
  background: #ffffff;
  border: ${(props) => (props.danger == true ? dangerBorder : normalBorder)};
  box-shadow: ${(props) =>
    props.danger == true ? "0px 4px 5px rgba(250, 70, 70, 0.05);" : "none"};
  box-sizing: border-box;
  margin-bottom: 20px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;
