import AfNsrTool from "../../../afib/tools/AfNsrTool";
import moment from "moment";

export default function AfPanel(props) {
  const { id, date } = props;
  return (
    <div className="af-tab-container">
      <AfNsrTool uuid={id} t={+moment(date).startOf("day")} />
    </div>
  );
}
