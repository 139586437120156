import { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import Spin from "arui-feather/spin";
import { Input } from "../ui";
import UiHelper from "../../../helpers/UiHelper";
import CommonHelper from "../../../helpers/CommonHelper";
import { Link } from "react-router-dom";
import checkedIcon from "../../../assets/images/check_circle.svg";
import env from "react-dotenv";
import infoImg from "../../info/tools/info_b.svg";

import Select from "react-select";
const USER_CLOUD = "https://api.users.cloud.corsano.com";
const { DOCTORS_API_ENDPOINT = "https://api.study-integration.corsano.com" } =
  env;
const QUESTION_OPTIONS = [
  {
    value: 0,
    label: "What is your oldest cousin's first and last name?",
  },
  {
    value: 1,
    label: "What primary school did you attend?",
  },
  {
    value: 2,
    label: "What was the street name you lived in as a child?",
  },
  {
    value: 3,
    label: "In what city or town was your first job?",
  },
  {
    value: 4,
    label: "What was the make and model of your first car?",
  },
];

const emailCheck =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

const isValidVerificationCode = (code) => {
  const regex = /^\d{6}$/;
  return regex.test(code);
};

const ROLES = ["hcp-admin", "hcp"];

const DEBUG = false;

export default function EmailRegistrationForm(props) {
  const [step, setStep] = useState(1);
  const [fname, setFname] = useState(DEBUG ? "First Name" : "");
  const [lname, setLname] = useState(DEBUG ? "Last Name" : "");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState(undefined);
  const [showRoleInfo, setShowRoleInfo] = useState(false);

  const [emailValid, setEmailValid] = useState(false);
  const [buttonHit, setButtonHit] = useState(false);
  const [agreement1Checked, setAgreement1Checked] = useState(
    DEBUG ? true : false
  );
  const [agreement2Checked, setAgreement2Checked] = useState(
    DEBUG ? true : false
  );
  const [emailUpdateChecked, setEmailUpdateChecked] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(
    passwordValid && password === confirmPassword
  );
  const [passwordRules, setPasswordRules] = useState({
    uppercase: false,
    lowercase: false,
    digit: false,
    length: false,
    specialCharactor: false,
  });
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [question1Filled, setQuestion1Filled] = useState(false);
  const [question2Filled, setQuestion2Filled] = useState(false);
  const [question1, setQuestion1] = useState("");
  const [question2, setQuestion2] = useState("");
  const [question1Id, setQuestion1Id] = useState(undefined);
  const [question2Id, setQuestion2Id] = useState(undefined);

  const [hasVoucher, setHasVoucher] = useState(false);
  const [voucherCode, setVoucherCode] = useState("");

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setPasswordsMatch(newPassword === confirmPassword);
    validatePassword(newPassword, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const newPassword = e.target.value;
    setConfirmPassword(newPassword);
    setPasswordsMatch(newPassword === password);
    validatePassword(password, newPassword);
  };

  const validatePassword = (password1, password2) => {
    const hasUpperCase = /[A-Z]/.test(password1);
    const hasLowerCase = /[a-z]/.test(password1);
    const hasDigit = /[0-9]/.test(password1);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password1); // Rule for special characters
    const isLongEnough = password1.length >= 10;
    // const isLongEnough = password1.length >= 8;
    const passwordsMatch = password1 === password2;

    setPasswordValid(
      hasUpperCase &&
        hasLowerCase &&
        hasDigit &&
        isLongEnough &&
        passwordsMatch &&
        hasSpecialChar
    );

    setPasswordRules({
      uppercase: hasUpperCase,
      lowercase: hasLowerCase,
      digit: hasDigit,
      length: isLongEnough,
      specialCharactor: hasSpecialChar,
    });
  };
  const renderIcon = (isMet) => {
    return isMet ? (
      <span style={{ color: "green" }}>✔</span>
    ) : (
      <span style={{ color: "red" }}>✘</span>
    );
  };

  const registerUser = async (registerObj) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const response = await axios.post(`${USER_CLOUD}/register`, registerObj);
      console.log("Registration successful:", response);
      setStep(step + 1);
    } catch (err) {
      if (err.response) {
        const { status, data } = err.response;
        if (status === 400) {
          console.error("Bad Request:", data.err);
        } else if (status === 422) {
          setErrorMsg(data.errors.email[0]);
          console.error("Unprocessable Entity:", data.errors.email[0]);
        } else {
          console.error("Unexpected error:", status, data);
        }
      } else if (err.request) {
        console.error("Request error:", err.request);
      } else {
        console.error("Error:", err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const setUserPassword = async (passwordObj) => {
    setLoading(true);
    setErrorMsg("");
    try {
      const response = await axios.post(
        `${USER_CLOUD}/auth/set-password`,
        passwordObj
      );
      const _uuid = response.data?.user?.uuid;
      const r = ROLES[role - 1];
      updateRole(_uuid, r);
      setStep(step + 1);
    } catch (err) {
      console.log("ERRE", err);

      if (err.response) {
        const { status, data } = err.response;
        if (status === 400) {
          console.error("Bad Request:", data.errors);
        } else if (status === 422) {
          const firstKey = Object.keys(data.errors)[0];
          const firstValue = data.errors[firstKey];
          setErrorMsg(firstValue);
          console.error("Unprocessable Entity:", data.errors);
        } else {
          console.error("Unexpected error:", status, data);
        }
      } else if (err.request) {
        console.error("Request error:", err.request);
      } else {
        console.error("Error:", err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      {step === 1 && (
        <InnerBox>
          <Row>
            <Label>{hasVoucher ? "Voucher Code" : "Your email:"}</Label>
            <Input
              placeholder="Enter your email"
              // type={hasVoucher ? "text" : "email"}
              type="email"
              id="email"
              value={email}
              onChange={(evt) => {
                // setButtonHit(false);
                let s = evt.target.value;
                if (buttonHit) {
                  setEmailValid(emailCheck.test(s));
                }
                if (hasVoucher) {
                  setVoucherCode(s.trim());
                } else {
                  setEmail(s.trim());
                }
              }}
            />
            {buttonHit && !emailValid ? (
              <span style={{ color: "red" }}>Email is not valid.</span>
            ) : null}
          </Row>
          <Row>
            <Label>First Name:</Label>
            <Input
              type="text"
              id="fname"
              name="fname"
              value={fname}
              placeholder="Your first name"
              onChange={(evt) => {
                let s = evt.target.value;
                setFname(s);
              }}
            />
            {buttonHit && fname.trim() == "" ? (
              <span style={{ color: "red", marginBottom: "1rem" }}>
                Please enter your first name.
              </span>
            ) : null}
          </Row>
          <Row>
            <Label>Last Name:</Label>
            <Input
              type="text"
              id="lname"
              name="lname"
              placeholder="Your last name"
              value={lname}
              onChange={(evt) => {
                let s = evt.target.value;
                setLname(s);
              }}
            />
            {buttonHit && lname.trim() == "" ? (
              <div style={{ color: "red", marginBottom: "1rem" }}>
                Please enter your last name.
              </div>
            ) : null}
          </Row>
          <Row style={{ marginBottom: "2em" }}>
            <Label style={{ display: "flex", alignItems: "center" }}>
              Please indicate how you want to register:
              <Icon
                src={infoImg}
                className="bp-info-icon"
                onClick={() => {
                  setShowRoleInfo(!showRoleInfo);
                }}
              />
            </Label>
            {showRoleInfo ? (
              <div
                className="normal-text"
                style={{
                  maxWidth: "400px",
                  cursor: "grab",
                  position: "absolute",
                  border: "1px solid grey",
                  borderRadius: "2px",
                  boxShadow:
                    "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                  background: "#efefef",
                  opacity: 1,
                  zIndex: 100,
                  right: "11em",
                  top: "18em",
                  marginBottom: "0.5em",
                  padding: "0.5em 1em",
                }}
                // dangerouslySetInnerHTML={{ __html: RolesInfo }}
              >
                <p>
                  When registering as a Healthcare Provider (HCP) on our
                  platform, you have two options: HCP Admin or HCP.
                  Understanding the difference between these roles is crucial
                  for ensuring you select the one that best fits your
                  responsibilities and access needs.
                </p>
                <h3>HCP Admin</h3>
                <p>
                  An HCP Admin is typically someone who holds a managerial or
                  administrative position within a healthcare organization. This
                  role is designed for individuals who need to oversee and
                  manage multiple users and operations within the platform.
                  Please select this role if you are a practice manager,
                  department head, or any position requiring administrative
                  control over other healthcare providers.
                </p>
                <h3>HCP</h3>
                <p>
                  An HCP, on the other hand, is an individual healthcare
                  provider who uses the platform primarily for their own
                  practice and patient interactions. Please select this role
                  when you are a physician, nurse, therapist, or other clinical
                  staff directly involved in patient care and do not require
                  extensive administrative capabilities.
                </p>
                <div
                  style={{
                    margin: "0 auto",
                    display: "flex",
                    justifyContent: "flex-end",
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "blue",
                  }}
                  onClick={() => {
                    setShowRoleInfo(!showRoleInfo);
                  }}
                >
                  Close
                </div>
              </div>
            ) : null}
            {buttonHit && !role ? (
              <div style={{ color: "red", marginBottom: "1rem" }}>
                Please choose a role.
              </div>
            ) : null}

            <div style={{ display: "flex" }}>
              <CheckBoxDiv>
                <CheckBox
                  type="checkbox"
                  id="hcp-admin"
                  name="hcp-admin"
                  checked={role === 1}
                  onChange={() => {
                    setRole(1);
                  }}
                />
                <label style={{ marginLeft: ".4em" }}>HCP Admin</label>
              </CheckBoxDiv>
              <CheckBoxDiv style={{ marginLeft: "2em" }}>
                <CheckBox
                  type="checkbox"
                  id="hcp"
                  name="hcp"
                  checked={role === 2}
                  onChange={() => {
                    setRole(2);
                  }}
                />
                <label style={{ marginLeft: ".4em" }}>HCP</label>
              </CheckBoxDiv>
            </div>
            {buttonHit && lname.trim() == "" ? (
              <div style={{ color: "red", marginBottom: "1rem" }}>
                Please enter your last name.
              </div>
            ) : null}
          </Row>
          <CheckBoxDiv>
            <CheckBox
              type="checkbox"
              id="agreement1"
              name="agreement1"
              checked={agreement1Checked}
              onChange={() => {
                setAgreement1Checked(!agreement1Checked);
              }}
            />
            <label>
              I agree to the{" "}
              <a
                href="https://corsano.com/wp-content/uploads/2024/02/CORSANO-General-Terms-and-License-08-02-2024_v2.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
            </label>
          </CheckBoxDiv>
          <CheckBoxDiv>
            <CheckBox
              type="checkbox"
              id="agreement2"
              name="agreement2"
              checked={agreement2Checked}
              onChange={() => {
                setAgreement2Checked(!agreement2Checked);
              }}
            />
            <label>
              I agree to the{" "}
              <a
                href="https://corsano.com/privacy-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </label>
          </CheckBoxDiv>
          <CheckBoxDiv>
            <CheckBox
              type="checkbox"
              id="update"
              name="update"
              checked={emailUpdateChecked}
              onChange={() => {
                setEmailUpdateChecked(!emailUpdateChecked);
              }}
            />
            <label>Keep me updated on news, event and offers</label>
          </CheckBoxDiv>
          {buttonHit && (!agreement1Checked || !agreement2Checked) ? (
            <div style={{ color: "red", opacity: "90%" }}>
              Please agree to Privacy Policy and Terms of Service.
            </div>
          ) : null}

          <br />
          <Button
            disabled={
              !emailCheck.test(email) ||
              fname.trim() === "" ||
              lname.trim() === "" ||
              !agreement1Checked ||
              !agreement2Checked ||
              !role
            }
            onClick={() => {
              setLoading(true);
              setButtonHit(true);
              const emailIsValid = emailCheck.test(email);
              setEmailValid(emailIsValid);
              if (
                emailIsValid &&
                fname.trim() !== "" &&
                lname.trim() !== "" &&
                agreement1Checked &&
                agreement2Checked &&
                role
              ) {
                setButtonHit(false);
              }
              const registerObj = {
                first_name: fname,
                last_name: lname,
                email: email,
                agreement_to_terms_of_use:
                  agreement1Checked && agreement2Checked,
              };
              registerUser(registerObj);
            }}
          >
            Sign Up
          </Button>
          {errorMsg ? (
            <div>
              <span style={{ color: "#ee3333", marginBottom: "1rem" }}>
                {errorMsg}
              </span>{" "}
              <span
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={() => {
                  setErrorMsg("");
                  CommonHelper.linkTo("/password_reset");
                  // setStep(2);
                }}
              >
                Need to reset password?
              </span>
            </div>
          ) : null}
          {/* {hasVoucher ? null : (
            <div>
              If you already have a voucher code,{" "}
              <Span
                onClick={() => {
                  setHasVoucher(true);
                }}
              >
                sign up here
              </Span>
            </div>
          )} */}
        </InnerBox>
      )}
      {step === 2 && (
        <InnerBox>
          <div style={{ marginBottom: "1rem" }}>
            Hi {fname + " " + lname}, please create a password and enter the
            code sent to your email address {email}.
          </div>
          <Row>
            <Label>Password</Label>
            <Input
              type="password"
              id="password"
              name="password"
              value={password}
              placeholder="Password"
              onChange={handlePasswordChange}
            />
          </Row>
          <Row>
            <Label>Confirm Password</Label>
            <Input
              type="password"
              id="password-confirm"
              name="password-confirm"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
            {!passwordsMatch && password !== "" ? (
              <div style={{ color: "red" }}>Passwords don't match!</div>
            ) : null}
          </Row>
          {!passwordValid && password !== "" ? (
            <>
              <div style={{ border: "none" }}>
                <span>
                  At least 10 characters: {renderIcon(passwordRules.length)}
                </span>
                <br />
                <span>
                  At least one uppercase letter:{" "}
                  {renderIcon(passwordRules.uppercase)}
                </span>
                <br />
                <span>
                  At least one lowercase letter:{" "}
                  {renderIcon(passwordRules.lowercase)}
                </span>
                <br />
                <span>
                  At least one digit: {renderIcon(passwordRules.digit)}
                </span>
                <br />
                <span>
                  At least one special character:{" "}
                  {renderIcon(passwordRules.specialCharactor)}
                </span>
                <br />
              </div>
            </>
          ) : null}
          <Row>
            <Label>Verification Code </Label>
            <span style={{ fontSize: "0.9em", opacity: "70%" }}>
              We have sent a verification code to you email address.
            </span>
            <Input
              type="text"
              id="verification-code"
              name="password-confirm"
              placeholder="6-digit code"
              value={verificationCode}
              onChange={(evt) => {
                let s = evt.target.value;
                setVerificationCode(s);
              }}
            />
          </Row>
          <Button
            disabled={
              !passwordValid || !isValidVerificationCode(verificationCode)
            }
            onClick={() => {
              setLoading(true);

              const passwordObj = {
                token: verificationCode,
                password: password,
                password_confirmation: confirmPassword,
              };
              setUserPassword(passwordObj);
            }}
          >
            <Spin theme={"alfa-on-color"} visible={loading} />
            {loading == true ? null : "Continue"}
          </Button>
          {errorMsg ? (
            <div>
              <span style={{ color: "#ee3333", marginBottom: "1rem" }}>
                {errorMsg}
              </span>{" "}
            </div>
          ) : null}

          <div style={{ display: "flex", marginTop: "1rem" }}>
            <Span
              onClick={() => {
                setStep(1);
                setEmail("");
              }}
            >
              Use another email
            </Span>
            <Span
              style={{ display: "none" }}
              onClick={() => {
                setResendLoading(true);
                axios
                  .get(
                    `${USER_CLOUD}/auth/resend-email-confirmation/${email}`
                    // {
                    //   email: email,
                    // }
                  )
                  .then((res) => {
                    setResendLoading(false);
                    if (res.status === 201) {
                      console.log("Registration successful:", res);
                      return;
                    } else {
                      console.log("Unexpected success status:", res);
                      return;
                    }
                  })
                  .catch((error) => console.error(error));
                // resend confirmation
              }}
            >
              {"Resend email confirmation"}
            </Span>
          </div>
        </InnerBox>
      )}
      {step === 3 && (
        <InnerBox>
          <div>
            <img height={150} width={150} src={checkedIcon}></img>
          </div>
          <Label style={{ color: "green" }}>
            Your Corsano
            <i>{role ? (role === 1 ? " HCP Admin " : " HCP ") : " "}</i>
            account has been registered!
          </Label>
          {role === 2 ? (
            <div style={{ width: "75%", margin: "0 auto" }}>
              {
                "Please contact you HCP admin to activate your account for the Department in your hospital."
              }
            </div>
          ) : null}
          <Button
            onClick={() => {
              CommonHelper.linkTo("/");
            }}
          >
            Back to Login Page
          </Button>
        </InnerBox>
      )}
      {step === 999 && (
        // no questions for web
        <InnerBox>
          <div style={{ marginBottom: "1rem" }}>
            To finish your registration, answer 2 security questions for future
            password recovery.
          </div>
          <Row>
            <Label>Secret Question 1</Label>
            <Select
              className="secret-question-selector"
              options={QUESTION_OPTIONS.filter((q) => {
                return q.value !== question2Id;
              })}
              onChange={(choice) => {
                setQuestion1Id(choice.value);
              }}
            />
            <Input
              type="text"
              id="q1"
              name="q1"
              placeholder="Answer:"
              // value={verificationCode}
              onChange={(evt) => {
                let s = evt.target.value;
              }}
            />
          </Row>
          <Row>
            <Label>Secret Question 2</Label>
            <Select
              className="secret-question-selector"
              options={QUESTION_OPTIONS.filter((q) => {
                return q.value !== question1Id;
              })}
              onChange={(choice) => {
                setQuestion2Id(choice.value);
              }}
            />
            <Input
              type="text"
              id="q2"
              name="q2"
              placeholder="Answer:"
              // value={verificationCode}
              onChange={(evt) => {
                let s = evt.target.value;
              }}
            />
          </Row>
          <Button
            onClick={() => {
              // setStep(step + 1);
            }}
          >
            Create Corsano Account
          </Button>
        </InnerBox>
      )}
    </Wrapper>
  );
}

async function updateRole(uuid, role) {
  const url = `${DOCTORS_API_ENDPOINT}/signup/set-role`;
  // const headers = {
  //   "Content-Type": "application/json",
  // };
  const data = {
    uuid: uuid,
    role: role,
  };

  try {
    const response = await axios.post(
      url,
      data
      // { headers: headers }
    );
    console.log("Response:", response.data);
  } catch (error) {
    console.error(
      "Error:",
      error.response ? error.response.data : error.message
    );
  }
}

const CheckBoxDiv = styled.div`
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
`;

const Span = styled.span`
  margin-bottom: 1rem;
  float: right;
  font-size: small;
  text-decoration: underline;
  opacity: 70%;
  margin-left: auto;
  margin-right: auto;
  text-decoration: underline;
  cursor: pointer;
`;

const Row = styled.div`
  margin-bottom: 10px;
  text-align: left;
`;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 20px;
  opacity: 0.9;
`;

const InnerBox = styled.div`
  padding: 20px;
  box-sizing: border-box;
  width: 520px;
  margin: 0 auto;
  text-align: center;
  @media (max-width: 720px) {
    width: 100%;
    padding: 10px;
  }
`;

const Wrapper = styled.div`
  // background: white;
  margin-top: 2rem;
  width: 80%;
  // height: 100%;
  box-sizing: border-box;
  // overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  height: 56px;
  width: 100%;
  box-shadow: none !important;
  margin-bottom: 1rem;
  margin-top: 1rem;
  background: ${(props) =>
    props.background == undefined ? "#147AFF" : props.background};
  border-radius: 8px;
  border: none !important;
  cursor: ${(props) => (props.disabled == true ? "default" : "pointer")};
  opacity: ${(props) => (props.disabled == true ? 0.5 : 1)};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  :hover {
    opacity: ${(props) => (props.disabled == true ? 0.5 : 0.8)};
  }
  :disabled {
    opacity: 90%;
    background: grey;
  }
`;

const RolesInfo = `
<p>When registering as a Healthcare Provider (HCP) on our platform, you have two options: HCP Admin or
CP. Understanding the difference between these roles is crucial for ensuring you select the one that
best fits your responsibilities and access needs.</p>
<h3>HCP Admin</h3>
<p>An HCP Admin is typically someone who holds a managerial or administrative position within a
healthcare organization. This role is designed for individuals who need to oversee and manage multiple
users and operations within the platform. Please select this role if you are a practice manager,
department head, or any position requiring administrative control over other healthcare providers.</p>
<h3>HCP</h3>
<p>An HCP, on the other hand, is an individual healthcare provider who uses the platform primarily for their
own practice and patient interactions. Please select this role when you are a physician, nurse, therapist,
or other clinical staff directly involved in patient care and do not require extensive administrative
capabilities.</p>`;

const Icon = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-left: 0.2em;
  opacity: 1;
  :hover {
    opacity: 0.8;
  }
`;

const CheckBox = styled.input`
  &[type="checkbox"] {
    accent-color: #1e7efa;
  }
`;
