import { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import Spin from "arui-feather/spin";
import { Input } from "../ui";
import UiHelper from "../../../helpers/UiHelper";
import CommonHelper from "../../../helpers/CommonHelper";
import Select from "react-select";

// deprecated because questions are only for apps

const USER_CLOUD = "https://api.users.cloud.corsano.com";
const FORGOT_PASSWORD = "/auth/forgot-password";

const emailCheck =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

export default function PasswordRecoveryForm(props) {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [buttonHit, setButtonHit] = useState(false);

  const [verificationCode, setVerificationCode] = useState("");

  const [question1Filled, setQuestion1Filled] = useState(false);
  const [question2Filled, setQuestion2Filled] = useState(false);
  const [question1, setQuestion1] = useState("");
  const [question2, setQuestion2] = useState("");
  const [question1Id, setQuestion1Id] = useState(undefined);
  const [question2Id, setQuestion2Id] = useState(undefined);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(
    newPassword === confirmPassword
  );
  const [passwordValid, setPasswordValid] = useState(false);

  const [passwordRules, setPasswordRules] = useState({
    uppercase: false,
    lowercase: false,
    digit: false,
    length: false,
  });

  const questions = ["Where was your...", "What was your..."];

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setNewPassword(password);
    setPasswordsMatch(password === confirmPassword);
    validatePassword(newPassword, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const password = e.target.value;
    setConfirmPassword(password);
    setPasswordsMatch(password === newPassword);
    validatePassword(newPassword, confirmPassword);
  };

  const validatePassword = (password1, password2) => {
    const hasUpperCase = /[A-Z]/.test(password1);
    const hasLowerCase = /[a-z]/.test(password1);
    const hasDigit = /[0-9]/.test(password1);
    const isLongEnough = password1.length >= 8;
    const passwordsMatch = password1 === password2;

    setPasswordValid(
      hasUpperCase && hasLowerCase && hasDigit && isLongEnough && passwordsMatch
    );

    setPasswordRules({
      uppercase: hasLowerCase,
      lowercase: hasLowerCase,
      digit: hasDigit,
      length: isLongEnough,
    });
  };

  const renderIcon = (isMet) => {
    return isMet ? (
      <span style={{ color: "green" }}>✔</span>
    ) : (
      <span style={{ color: "red" }}>✘</span>
    );
  };

  const handleSendVerification = (email) => {
    const _api = `${USER_CLOUD}${FORGOT_PASSWORD}`;
    axios
      .post(_api, {
        email: email,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Wrapper>
      {step === 1 && (
        <InnerBox>
          <Row>
            <Label>
              Please enter your email to receive your verification code:
            </Label>
            <Input
              autoFocus={true}
              type="email"
              id="email"
              value={email}
              placeholder="Your email"
              onChange={(evt) => {
                let s = evt.target.value;
                let email = s.trim();
                if (buttonHit) {
                  setEmailValid(emailCheck.test(email));
                }
                setEmail(email);
              }}
            />
            {buttonHit && !emailValid ? (
              <span style={{ color: "red" }}>Email is not valid.</span>
            ) : null}
          </Row>
          <Button
            onClick={() => {
              setButtonHit(true);
              const emailIsValid = emailCheck.test(email);
              setEmailValid(emailIsValid);

              if (emailIsValid) {
                handleSendVerification(email);
                setStep(step + 1);
                setButtonHit(false);
              }
            }}
          >
            Continue
          </Button>
        </InnerBox>
      )}
      {step === 2 && (
        <InnerBox>
          <Row>
            <Label>Verification Code </Label>
            <Input
              type="text"
              id="password-confirm"
              name="password-confirm"
              placeholder="6-digit code"
              value={verificationCode}
              onChange={(evt) => {
                let s = evt.target.value;
                setVerificationCode(s);
              }}
            />
          </Row>
          <Row>
            <Label>New password:</Label>
            <Input
              type="password"
              id="password"
              autocomplete={"new-password"}
              className="search-input"
              value={newPassword}
              onChange={handlePasswordChange}
              // onChange={(evt) => {
              //   const psd = evt.target.value;
              //   setNewpassword(psd);
              //   if (confirmPassword !== "") {
              //     setPasswordsMatch(psd === confirmPassword);
              //   }
              // }}
            />
          </Row>
          <Row>
            <Label>Repeat new password:</Label>
            <Input
              id="password-confirm"
              type="password"
              autocomplete={"off"}
              className="search-input"
              onChange={handleConfirmPasswordChange}
              // onChange={(evt) => {
              //   const psd = evt.target.value;
              //   setConfirmPassword(psd);
              //   if (newpassword !== "") {
              //     setPasswordsMatch(psd === newpassword);
              //   }
              // }}
            />
            {!passwordsMatch ? (
              <span style={{ opacity: "80%", fontSize: "small", color: "red" }}>
                Passwords don't match!
              </span>
            ) : null}
          </Row>
          {!passwordValid && newPassword !== "" ? (
            <>
              <div style={{ border: "none" }}>
                <span>
                  At least 10 characters: {renderIcon(passwordRules.length)}
                </span>
                <br />
                <span>
                  At least one uppercase letter:{" "}
                  {renderIcon(passwordRules.uppercase)}
                </span>
                <br />
                <span>
                  At least one lowercase letter:{" "}
                  {renderIcon(passwordRules.lowercase)}
                </span>
                <br />
                <span>
                  At least one digit: {renderIcon(passwordRules.digit)}
                </span>
                <br />
              </div>
            </>
          ) : null}
          <Button
            onClick={() => {
              setStep(step + 1);
            }}
          >
            Finish
          </Button>
          <div style={{ display: "flex", marginTop: "1rem" }}>
            <Span
              style={{ marginLeft: "auto", marginRight: "auto" }}
              onClick={() => setStep(step - 1)}
            >
              Enter a new email
            </Span>
            <Span
              style={{ marginLeft: "auto", marginRight: "auto" }}
              onClick={() => CommonHelper.linkTo("/")}
            >
              Cancel resetting password
            </Span>
          </div>
        </InnerBox>
      )}
      {step === 3 && (
        <InnerBox>
          <div style={{ fontSize: "large" }}>Password reset successfully!</div>
        </InnerBox>
      )}
    </Wrapper>
  );
}

const Span = styled.span`
  text-decoration: underline;
  cursor: pointer;
  opacity: "70%";
`;

const Row = styled.div`
  margin-bottom: 10px;
  text-align: left;
`;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 20px;
  opacity: 0.9;
`;

const InnerBox = styled.div`
  padding: 20px;
  box-sizing: border-box;
  width: 620px;
  margin: 0 auto;
  text-align: center;
  @media (max-width: 720px) {
    width: 100%;
    padding: 10px;
  }
`;

const Wrapper = styled.div`
  background: white;
  width: 100vw;
  height: 100%;
  box-sizing: border-box;
  // overflow: hidden;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  height: 56px;
  width: 100%;
  box-shadow: none !important;
  margin-bottom: 1rem;
  margin-top: 1rem;
  background: ${(props) =>
    props.background == undefined ? "#147AFF" : props.background};
  border-radius: 8px;
  border: none !important;
  cursor: ${(props) => (props.disabled == true ? "default" : "pointer")};
  opacity: ${(props) => (props.disabled == true ? 0.5 : 1)};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  :hover {
    opacity: ${(props) => (props.disabled == true ? 0.5 : 0.8)};
  }
`;
