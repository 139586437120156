import WeightTool from "../../../spiro/tools/WeightTool";
import { TabHeading } from "../UiComponents";

export default function WeightPanel(props) {
  const { id } = props;
  return (
    <div>
      <TabHeading className="patient-statistics-heading">Weight</TabHeading>
      <div style={{ width: "100%" }}>
        {/*<EmptyChartTool/>*/}
        <WeightTool uuid={id} />
      </div>
    </div>
  );
}
