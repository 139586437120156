import {useState, useEffect} from 'react';
import styled from 'styled-components';
import {gup} from "../../api/DoctorAPI";
import ls from 'local-storage'

function setTokensFromUrl() {
    let health_token = gup('health_token', window.location.hash);
    let users_token = gup('users_token', window.location.hash);
    if (health_token == undefined || users_token == undefined || health_token == '' || users_token == '') {
        window.alert('tokens are not defined in the url!');
        return false;
    }
    ls('health_cloud_token', health_token);
    ls('users_cloud_token', users_token);
    return true;
}


export default function MmtAuthApp(props) {

    useEffect(() => {
        let r = setTokensFromUrl();
        if (r == true){
            window.location.href = '/';
            // reload the page
            // window.location.reload();
        }
    }, [])

    return (
        <Wrapper>

            <div className={'p-2'} >
                {`loading...`}
            </div>

            <div className={'p-2'} >
                {`users_token = ${gup('users_token')}`}
            </div>

            <div className={'p-2'} >
                {`health_token = ${gup('health_token')}`}
            </div>

        </Wrapper>
    );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 15px;
`;

const InnerPlaceholder = styled.div`
  margin: 0 auto;
  box-sizing: border-box;
  //width: 1420px;
  width: 1020px;
  padding: 40px;
`;