import { TabHeading } from "../UiComponents";
import PatientMPIToolBaseline from "../../../mpi/tools/PatientMPIToolBaseline";
import moment from "moment";

export default function RiskPanel(props) {
  const { id, unit, date } = props;
  return (
    <div>
      <TabHeading className="patient-statistics-heading">RISK</TabHeading>

      <div style={{ width: "100%" }}>
        {/*<EmptyChartTool/>*/}
        {/* <PatientMPITool uuid={id} t={+moment(date).endOf("day")} /> */}
        {/* {isDebugMode() &&
            (window.location.href.indexOf(".xyz") ||
              window.location.href.indexOf("localhost")) && (
              <CorEventsTool />
            )} */}

        <PatientMPIToolBaseline
          uuid={id}
          t={+moment(date).endOf("day")}
          unit={unit}
        />
      </div>
    </div>
  );
}
