import { useState, useEffect } from "react";
import styled from "styled-components";
import Spin from "arui-feather/spin";

import ch_void from "../../../assets/images/checkbox.svg";
import ch_checked from "../../../assets/images/chbx.svg";
import { useDispatch } from "redux-react-hook";
import * as UiActions from "../../../redux/actions/UiActions";
import Select from "react-select";
import { isDebugMode } from "../../../helpers/UiHelper";
import NiceModal from "../../modals/NiceModal";
import DashboardSettingsEditor, {
  getEnhSettings,
} from "./DashboardSettingsEditor";
import UserAlarmsTool from "../../settings/tools/UserAlarmsTool";
import UserAlarmParametersTools from "../../settings/tools/UserAlarmParametersTool";
// import { defineLocale, duration } from "moment";
import toast from "react-hot-toast";
import UserAlarmBaseTool from "../../settings/tools/UserAlarmBaseTool";
import InfoToolBadgeGrey from "../../info/tools/InfoToolBadgeGrey";
import SingleMultiUsersTool from "../../settings/tools/SingleMultiUsersTool";
// import { Button } from "../../ui/templates";

const NON_RAW_NAMES = [
  "activity",
  "heart_rate",
  "respiration_rate",
  "spo2",
  "body_temperature",
];

const NAND_NAME = ["emography", "nibp"];
const DISABLE_WHEN_5MINS = ["sleep_stage", "rr_intervals", "afib", "emography"];
export const ITEMS = [
  {
    label: "Activity",
    name: "activity",
    defaultValue: 0,
    options: [
      {
        value: 4, // new
        label: "1/5min",
        daysNumber: 6.5,
        isDebug: true,
        sort: 0,
        batteryDay: 7,
      },
      {
        value: 0,
        label: "1/min",
        daysNumber: 15,
        sort: 1,
        batteryDay: 5,
      },
      {
        value: 3, // new
        label: "1/30sec",
        daysNumber: 6.5,
        isDebug: true,
        sort: 2,
        batteryDay: 5,
      },
      {
        value: 1, // delete!
        label: "1/10sec",
        daysNumber: 6.5,
        isOld: true,
        batteryDay: 5,
      },
      {
        value: 2,
        label: "1/sec",
        daysNumber: 6,
        v1: false,
        sort: 4,
        isOld: true,
        batteryDay: 5,
      },
      {
        value: 9,
        label: "Disabled",
        batteryDay: 7,
      },
    ],
  },
  {
    label: "ACC xyz",
    defaultValue: 9,
    name: "acc_xyz",
    isRawData: true,
    options: [
      {
        value: 0,
        label: "32Hz",
        daysNumber: 6.5,
        v1: false,
        bateryDayRaw: 5,
        batteryDeduction: 0,
      },
      {
        value: 0,
        label: "25Hz",
        daysNumber: 6.5,
        v1: true,
      },
      {
        value: 9,
        label: "Disabled",
        batteryDeduction: 0,
      },
    ],
  },
  {
    label: "Pulse Rate",
    defaultValue: 0,
    name: "heart_rate",
    options: [
      {
        value: 4, // new
        label: "1/5min",
        daysNumber: 6.5,
        isDebug: true,
        sort: 0,
        batteryDay: 7,
      },
      {
        value: 0,
        label: "1/min",
        daysNumber: 7.5,
        sort: 1,
        batteryDay: 5,
      },
      {
        value: 3, // new
        label: "1/30sec",
        daysNumber: 6.5,
        isDebug: true,
        sort: 2,
        batteryDay: 5,
      },
      {
        value: 1,
        label: "1/10sec",
        daysNumber: 6.5,
        isOld: true,
        batteryDay: 5,
      },
      {
        value: 2,
        label: "1/sec",
        daysNumber: 6.5,
        v1: false,
        isOld: true,
        sort: 4,
        batteryDay: 5,
      },
      {
        value: 9,
        label: "Disabled",
        batteryDay: 7,
      },
    ],
  },

  {
    label: "BRPM",
    defaultValue: 0,
    name: "respiration_rate",
    options: [
      {
        value: 0,
        label: "1/30min",
        daysNumber: 7.5,
        sort: 0,
        batteryDay: 7,
      },
      {
        value: 5, // new
        label: "1/5min",
        daysNumber: 6.5,
        v1: false,
        isDebug: true,
        sort: 1,
        batteryDay: 7,
      },
      {
        value: 4,
        label: "1/min",
        daysNumber: 7.5,
        isDebug: true,
        sort: 2,
        batteryDay: 5,
      },
      {
        value: 3, // new
        label: "1/30sec",
        daysNumber: 6.5,
        v1: false,
        isDebug: true,
        sort: 3,
        batteryDay: 5,
      },
      {
        value: 1,
        label: "1/10sec",
        daysNumber: 6.5,
        isOld: true,
        sort: 4,
        batteryDay: 5,
      },
      {
        value: 2,
        label: "1/sec",
        daysNumber: 6.5,
        v1: false,
        isOld: true,
        sort: 6,
        batteryDay: 5,
      },
      {
        value: 9,
        label: "Disabled",
        batteryDay: 7,
      },
    ],
  },
  {
    label: "RR-Intervals",
    defaultValue: 9,
    name: "rr_intervals",
    options: [
      {
        value: 1,
        label: "Bedtime-Risetime",
        daysNumber: 6.5,
        // batteryDay: 5,
        batteryDeduction: 1,
      },
      {
        value: 0,
        label: "Continuous",
        daysNumber: 6.5,
        // batteryDay: 4,
        batteryDeduction: 2,
      },
      {
        value: 9,
        label: "Disabled",
        batteryDay: 7,
        batteryDeduction: 0,
      },
    ],
  },

  {
    label: "Spo2",
    defaultValue: 0,
    name: "spo2",
    v1: false,
    options: [
      {
        value: 0,
        label: "1/30min",
        daysNumber: 6,
        sort: 0,
        batteryDay: 7,
      },
      {
        value: 6, // new
        label: "1/5min",
        daysNumber: 4,
        isDebug: true,
        sort: 1,
        batteryDay: 7,
      },
      {
        value: 3,
        label: "1/min",
        daysNumber: 3,
        isDebug: true,
        sort: 3,
        batteryDay: 5,
      },
      {
        value: 5, // new
        label: "1/30sec",
        daysNumber: 4,
        isDebug: true,
        sort: 4,
        batteryDay: 5,
      },
      {
        value: 4,
        label: "1/10sec",
        daysNumber: 3,
        isDebug: true,
        isOld: true,
        sort: 5,
        batteryDay: 5,
      },
      {
        value: 1,
        label: "1/sec",
        daysNumber: 4,
        isOld: true,
        sort: 6,
        batteryDay: 5,
      },
      {
        value: 9,
        label: "Disabled",
        batteryDay: 7,
      },
    ],
  },

  {
    label: "PPGG",
    defaultValue: 9,
    name: "ppg1",
    isRawData: true,
    options: [
      {
        value: 0,
        label: "32Hz",
        daysNumber: 6.5,
        v1: false,
        batteryDayRaw: 5,
        batteryDeduction: 1,
      },
      {
        value: 0,
        label: "25Hz",
        daysNumber: 6.5,
        v1: true,
      },

      {
        value: 3,
        label: "64Hz",
        daysNumber: 2,
        v1: false,
        batteryDayRaw: 4,
        batteryDeduction: 2,
      },
      {
        value: 1,
        label: "128Hz",
        daysNumber: 2,
        v1: false,
        batteryDayRaw: 3,
        batteryDeduction: 3,
      },

      {
        value: 9,
        label: "Disabled",
        batteryDeduction: 0,
      },
    ],
  },

  {
    label: "PPG G/R/Ir",
    defaultValue: 9,
    name: "ppg2",
    v1: false,
    isRawData: true,
    options: [
      {
        value: 0,
        label: "32Hz",
        daysNumber: 6,
        batteryDayRaw: 4,
        batteryDeduction: 1,
      },
      {
        value: 3,
        label: "64Hz",
        daysNumber: 2,
        batteryDayRaw: 3,
        batteryDeduction: 3,
      },
      {
        value: 1,
        label: "128Hz",
        daysNumber: 2,
        batteryDayRaw: 2,
        batteryDeduction: 3,
      },
      {
        value: 9,
        label: "Disabled",
        batteryDeduction: 0,
      },
    ],
  },

  {
    label: "Sleep",
    defaultValue: 0,
    name: "sleep_stage",
    options: [
      {
        value: 0,
        label: "Bedtime-Risetime",
        daysNumber: 7.5,
        // batteryDay: 5,
        batteryDeduction: 1,
      },
      {
        value: 1,
        label: "Continuous",
        daysNumber: 3.5,
        // batteryDay: 4,
        batteryDeduction: 2,
      },
      {
        value: 9,
        label: "Disabled",
        batteryDay: 7,
        batteryDeduction: 0,
      },
    ],
  },

  {
    label: "Skin Temperature during night",
    defaultValue: 0,
    name: "skin_temperature",
    v1: true,
    v2: false,
    options: [
      {
        value: 0,
        label: "1/min",
        daysNumber: 15,
      },
      {
        value: 1,
        label: "1/10sec",
      },
      {
        value: 2,
        label: "1/sec",
        isOld: true,
        v1: false,
      },
      {
        value: 9,
        label: "Disabled",
      },
    ],
  },

  {
    v1: false,
    label: "Temperature",
    defaultValue: 0,
    name: "body_temperature",
    options: [
      {
        value: 4, // new
        label: "1/5min",
        isDebug: true,
        sort: 2,
        batteryDay: 7,
      },
      {
        value: 0,
        label: "1/min",
        daysNumber: 15,
        sort: 3,
        batteryDay: 5,
      },
      {
        value: 3, // new
        label: "1/30sec",
        isDebug: true,
        sort: 4,
        batteryDay: 5,
      },
      {
        value: 1,
        label: "1/10sec",
        isOld: true,
        sort: 5,
        batteryDay: 5,
      },
      {
        value: 2,
        label: "1/sec",
        sort: 6,
        isOld: true,
        batteryDay: 5,
      },
      {
        value: 9,
        label: "Disabled",
        batteryDay: 7,
      },
    ],
  },

  {
    label: "Emography",
    defaultValue: 9,
    name: "emography",
    v1: false,
    options: [
      {
        value: 0,
        label: "Continuous",
        daysNumber: 5,
        batteryDay: 7,
      },
      {
        value: 9,
        label: "Disabled",
        batteryDay: 7,
      },
    ],
  },

  {
    label: "NIBP",
    defaultValue: 9,
    name: "nibp",
    v1: false,
    options: [
      {
        value: 0,
        label: "1/30min",
        daysNumber: 6,
        batteryDay: 7,
      },
      {
        value: 9,
        label: "Disabled",
        batteryDay: 7,
      },
    ],
  },

  {
    label: "BioZ",
    defaultValue: 9,
    name: "bioz",
    isRawData: true,
    v1: false,
    options: [
      {
        value: 0,
        label: "32Hz",
        daysNumber: 5,
        // batteryDeduction: 1,
      },
      {
        value: 9,
        label: "Disabled",
        batteryDeduction: 0,
      },
    ],
  },

  {
    label: "AFib",
    defaultValue: 9,
    name: "afib",
    isRawData: false,
    v1: false,
    options: [
      {
        value: 0,
        label: "Continuous",
        daysNumber: 5,
        batteryDay: 7,
      },
      {
        value: 9,
        label: "Disabled",
        batteryDay: 7,
      },
    ],
  },
  {
    label: "High Precision Accelerometer",
    defaultValue: 9,
    name: "high_precision_accelerometer",
    isRawData: false,
    v1: false,
    options: [
      {
        value: 0,
        label: "Enabled",
        daysNumber: 5,
        batteryDay: 7,
      },
      {
        value: 9,
        label: "Disabled",
        batteryDay: 7,
      },
    ],
  },

  {
    label: "Raw Temperature",
    defaultValue: 9,
    name: "temperature_raw",
    isRawData: true,
    v1: false,
    options: [
      {
        value: 0,
        label: "Enabled",
        daysNumber: 5,
      },
      {
        value: 9,
        label: "Disabled",
      },
    ],
  },
];

export const TABS_OPTIONS = [
  {
    label: "Activity",
    name: "activity",
    defaultValue: true,
  },
  {
    label: "Sleep",
    name: "sleep",
    defaultValue: true,
  },
  {
    label: "Pulse Rate",
    name: "heart_rate",
    defaultValue: true,
  },
  {
    label: "Stress",
    name: "stress",
    defaultValue: false,
  },
  {
    label: "WebView",
    name: "webview",
    defaultValue: false,
  },
];

const BRACELET_CONFIG_OPTIONS = [
  {
    label: "C10 (Max Battery)",
    value: 1,
  },
  {
    label: "C20 (Typical)",
    value: 2,
  },
  {
    label: "C30 (High resolution)",
    value: 3,
  },
  {
    label: "C40 (Typical Multicolor)",
    value: 7,
  },
  {
    label: "C50 (High resolution multicolor)",
    value: 8,
  },
  {
    label: "C60 (multicolor intermittent)",
    value: 9,
  },
  {
    label: "C70 (Hospital nights)",
    value: 4,
  },
  {
    label: "C80 (Hospital full)",
    value: 5,
  },
];

const DASHBOARD_OPTIONS = [
  {
    label: "RPM",
    name: "rpm",
    value: 0,
    isDefault: true,
  },
  {
    label: "Start/Stop",
    name: "start_stop",
    value: 1,
  },
  {
    label: "AFIB",
    name: "afib",
    value: 2,
  },
  {
    label: "Emography",
    name: "emography",
    value: 3,
    v1: false,
  },
  {
    label: "USA",
    name: "usa",
    value: 4,
  },
  {
    label: "Custom",
    name: "custom",
    value: 5,
  },
  {
    label: "In-Hospital Monitoring",
    name: "in_hospital_monitoring",
    value: 6,
  },
];

const BRACELETS_OPTIONS = [
  {
    label: "287-1",
    value: "287-1",
  },
  {
    label: "287-2",
    value: "287-2",
  },
];

const DEFAULT_VITAL_ALARM_SETTINGS = {
  heart_rate: true,
  spo2: true,
  respiration_rate: false,
  systolic_blood_pressure: false,
  temperature: true,
};

const VITAL_ALARM_SETTINGS_ALL_ON = {
  heart_rate: true,
  spo2: true,
  respiration_rate: true,
  systolic_blood_pressure: true,
  temperature: true,
};

const VALID_ALARM_SYSTEMS = ["none", "news", "between-flags", "corrisk"];
const DEFAULT_ALARM_SYSTEM = "none";
const DEFAULT_ALARM_BASE = "raw";
const DEFAULT_SINGLE_OR_MULTI = "single";

const newLineBreak = "%0D%0A";

const generateEmailLineByItems = (keyName, prev, curr) => {
  const keepSame = false;
  let text = "";

  let itemOptions = ITEMS.find((x) => x.name === keyName).options;
  let itemLabel = ITEMS.find((x) => x.name === keyName).label;
  let pre = itemOptions.find((x) => x.value === prev[keyName]).label;
  let cur = itemOptions.find((x) => x.value === curr[keyName]).label;
  let isSame = pre === cur;
  if (isSame) {
    // return `${itemLabel} ${newLineBreak}`;
    return "";
  }
  return `${itemLabel}: ${pre} => ${cur} ${newLineBreak}`;
};

const vitalParamsKeys = [
  "activity",
  "heart_rate",
  "respiration_rate",
  "rr_intervals",
  "spo2",
  "sleep_stage",
  "body_temperature",
  "emography",
  "nibp",
  "afib",
  "high_precision_accelerometer",
];

const rawDataParamsKeys = [
  "acc_xyz",
  "ppg1",
  "ppg2",
  "bioz",
  "temperature_raw",
];

const generateEmailBody = (prev, curr) => {
  console.log(prev, curr);

  let text = "";
  const checkVitalAllSame = vitalParamsKeys.every((x) => {
    const _line = generateEmailLineByItems(x, prev, curr);
    return _line.length === 0;
  });
  const checkRawDataAllSame = rawDataParamsKeys.every((x) => {
    const _line = generateEmailLineByItems(x, prev, curr);
    return _line.length === 0;
  });
  // console.log({ checkVitalAllSame });

  const vitalText = `
${newLineBreak}--- Vital Parameters ---${newLineBreak}
${generateEmailLineByItems("activity", prev, curr)}
${generateEmailLineByItems("heart_rate", prev, curr)}
${generateEmailLineByItems("respiration_rate", prev, curr)}
${generateEmailLineByItems("rr_intervals", prev, curr)}
${generateEmailLineByItems("spo2", prev, curr)}
${generateEmailLineByItems("sleep_stage", prev, curr)}
${generateEmailLineByItems("body_temperature", prev, curr)}
${generateEmailLineByItems("emography", prev, curr)}
${generateEmailLineByItems("nibp", prev, curr)}
${generateEmailLineByItems("afib", prev, curr)}
${generateEmailLineByItems("high_precision_accelerometer", prev, curr)}
`;
  const rawText = `
${newLineBreak}--- Raw Data ---${newLineBreak}
${generateEmailLineByItems("acc_xyz", prev, curr)}
${generateEmailLineByItems("ppg1", prev, curr)}
${generateEmailLineByItems("ppg2", prev, curr)}
${generateEmailLineByItems("bioz", prev, curr)}
${generateEmailLineByItems("temperature_raw", prev, curr)}${newLineBreak}
`;

  text = `${checkVitalAllSame ? "" : vitalText}
  ${checkRawDataAllSame ? "" : rawText}`;

  return text;
};

const generateEmailLineByDashboardItems = (keyName, prev, curr) => {
  let text = "";

  // let itemOptions = DASHBOARD_ITEMS.find((x) => x.value === keyName).options;
  let itemLabel = DASHBOARD_ITEMS.find((x) => x.value === keyName).label;
  let pre = prev[keyName];
  let cur = curr[keyName];
  let isSame = pre === cur;
  if (isSame) {
    // return `${itemLabel} ${newLineBreak}`;
    return "";
  }
  return `${itemLabel}: ${pre} => ${cur} ${newLineBreak}`;
};

const dashboardKeys = [
  "activity",
  "ecg",
  "heart_rate",
  "insights",
  "nibp",
  "respiration",
  "sleep",
  "spirometer",
  "spo2",
  "stress",
  "temperature",
  "weight",
  "workout",
];
const emailBodyWithDashboard = (prev, curr) => {
  console.log(prev, curr);
  // if (!curr) {
  //   curr = prev;
  // }
  const checkDashboardAllSame = dashboardKeys.every((x) => {
    const _line = generateEmailLineByDashboardItems(
      x,
      prev.settings,
      curr.settings
    );
    return _line.length === 0;
  });
  const oldDashboardLabel = DASHBOARD_OPTIONS.find(
    (x) => x.value === prev.config
  ).label;
  const newDashboardLabel = DASHBOARD_OPTIONS.find(
    (x) => x.value === curr.config
  ).label;

  let text = "";
  const dashboardText = `
${newLineBreak}--- Dashboard ---${newLineBreak}
Dashdoard: ${oldDashboardLabel} => ${newDashboardLabel}${newLineBreak}
${generateEmailLineByDashboardItems("activity", prev.settings, curr.settings)}
${generateEmailLineByDashboardItems("ecg", prev.settings, curr.settings)}
${generateEmailLineByDashboardItems("heart_rate", prev.settings, curr.settings)}
${generateEmailLineByDashboardItems("insights", prev.settings, curr.settings)} 
${generateEmailLineByDashboardItems("nibp", prev.settings, curr.settings)}
${generateEmailLineByDashboardItems(
  "respiration",
  prev.settings,
  curr.settings
)}
${generateEmailLineByDashboardItems("sleep", prev.settings, curr.settings)}
${generateEmailLineByDashboardItems("spirometer", prev.settings, curr.settings)}
${generateEmailLineByDashboardItems("spo2", prev.settings, curr.settings)}
${generateEmailLineByDashboardItems("stress", prev.settings, curr.settings)}
${generateEmailLineByDashboardItems(
  "temperature",
  prev.settings,
  curr.settings
)}
${generateEmailLineByDashboardItems("weight", prev.settings, curr.settings)}
${generateEmailLineByDashboardItems("workout", prev.settings, curr.settings)}
${newLineBreak}
`;
  text = `${
    checkDashboardAllSame && oldDashboardLabel === newDashboardLabel
      ? ""
      : dashboardText
  }`;
  return text;
};

const emailBodyWithAlarms = (prev, curr) => {
  // console.log(prev, curr);
  let text = "";

  const activityText =
    prev.tab["activity"] !== curr.tab["activity"]
      ? ` Activity: ${prev.tab["activity"]} => ${curr.tab["activity"]}${newLineBreak}`
      : ``;
  const sleepText =
    prev.tab["sleep"] !== curr.tab["sleep"]
      ? ` Sleep: ${prev.tab["sleep"]} => ${curr.tab["sleep"]}${newLineBreak}`
      : ``;
  const pulseRateText =
    prev.tab["heart_rate"] !== curr.tab["heart_rate"]
      ? ` Pulse Rate: ${prev.tab["heart_rate"]} => ${curr.tab["heart_rate"]}${newLineBreak}`
      : ``;
  const stressText =
    prev.tab["stress"] !== curr.tab["stress"]
      ? ` Stress: ${prev.tab["stress"]} => ${curr.tab["stress"]}${newLineBreak}`
      : ``;
  const webviewText =
    prev.tab["webview"] !== curr.tab["webview"]
      ? ` Webview: ${prev.tab["webview"]} => ${curr.tab["webview"]}${newLineBreak}`
      : ``;
  const alarmSettingText =
    prev.alarmSystem !== curr.alarmSystem
      ? `Alarm Setting: ${prev.alarmSystem} => ${curr.alarmSystem}${newLineBreak}`
      : ``;
  const vitalPulseRateText =
    prev.alarmParams["heart_rate"] !== curr.alarmParams["heart_rate"]
      ? ` Pulse Rate: ${prev.alarmParams["heart_rate"]} => ${curr.alarmParams["heart_rate"]}${newLineBreak}`
      : ``;
  const vitalSpO2Text =
    prev.alarmParams["spo2"] !== curr.alarmParams["spo2"]
      ? ` SpO2: ${prev.alarmParams["spo2"]} => ${curr.alarmParams["spo2"]}${newLineBreak}`
      : ``;
  const vitalRepisrationRateText =
    prev.alarmParams["respiration_rate"] !==
    curr.alarmParams["respiration_rate"]
      ? ` Respiration Rate: ${prev.alarmParams["respiration_rate"]} => ${curr.alarmParams["respiration_rate"]}${newLineBreak}`
      : ``;
  const vitalBloodPressureText =
    prev.alarmParams["systolic_blood_pressure"] !==
    curr.alarmParams["systolic_blood_pressure"]
      ? ` Blood Pressure: ${prev.alarmParams["systolic_blood_pressure"]} => ${curr.alarmParams["systolic_blood_pressure"]}${newLineBreak}`
      : ``;
  const vitalTemperatureText =
    prev.alarmParams["temperature"] !== curr.alarmParams["temperature"]
      ? ` Temperature: ${prev.alarmParams["temperature"]} => ${curr.alarmParams["temperature"]}${newLineBreak}`
      : ``;

  const alarmBaseText =
    prev.alarmBase !== curr.alarmBase
      ? `Alarm Base: ${prev.alarmBase} => ${curr.alarmBase}${newLineBreak}`
      : ``;

  const tabSettingsAllSame = [
    activityText,
    sleepText,
    pulseRateText,
    stressText,
    webviewText,
  ].every((x) => x.length === 0);

  const alarmVitalAllSame = [
    vitalPulseRateText,
    vitalSpO2Text,
    vitalRepisrationRateText,
    vitalBloodPressureText,
    vitalTemperatureText,
  ].every((x) => x.length === 0);

  const tabSettingsText = `
${newLineBreak}--- Tab Settings ---${newLineBreak}
Tab Settings:${newLineBreak}
${activityText} 
${sleepText}
${pulseRateText} 
${stressText} 
${webviewText} 
`;
  const alarmText = `
${newLineBreak}${alarmVitalAllSame ? "" : "--- Alarms ---"}${newLineBreak}
${alarmSettingText}
${alarmVitalAllSame ? "" : "Alarm Vital Parameters"} ${newLineBreak} 
${vitalPulseRateText}
${vitalSpO2Text}
${vitalRepisrationRateText}
${vitalBloodPressureText}
${vitalTemperatureText}
${alarmBaseText} 
`;
  text = `
${tabSettingsAllSame ? "" : tabSettingsText}
${alarmText}
`;

  return text;
};

export default function VitalParametersEditor(props) {
  const {
    onSave = (d) => {},
    loading = false,
    code,
    isPatientPortal = false,
    alarms_settings,
    alarms_system,
    alarms_base,
    isHCPAdmin,
    singleOrMulti,
    hcpEmail,
  } = props;
  const dispatch = useDispatch();
  console.log("props.params = ", props);

  const [params, setParams] = useState(props.params || {});
  const [tabs, setTabs] = useState(props.tabs || {});

  const [oldSleepValue, setOldSleepValue] = useState(
    props?.params?.sleep_stage || undefined
  );
  const [oldRrValue, setOldRrValue] = useState(
    props?.params?.rr_intervals || undefined
  );

  const [someOldValues, setSomeOldValues] = useState({
    rr_intervals: props?.params?.rr_intervals,
    sleep_stage: props?.params?.sleep_stage,
    emography: props?.params?.emography,
    afib: props?.params?.afib,
  });
  // console.log("setoldSleepValue", props?.params?.rr_intervals, someOldValues);

  const [dashboard_settings, setDashboard_settings] = useState(
    props.dashboard_settings || {}
  );

  const [alarmsSettings, setAlarmsSettings] = useState(
    alarms_settings || DEFAULT_VITAL_ALARM_SETTINGS
  );

  // console.log("alarm sys", alarms_system);

  const [alarmsSystem, setAlarmsSystem] = useState(
    alarms_system || DEFAULT_ALARM_SYSTEM
  );

  const [alarmsBase, setAlarmsBase] = useState(
    alarms_base || DEFAULT_ALARM_BASE
  );

  const [isSingleOrMulti, setIsSingleOrMulti] = useState(
    singleOrMulti || DEFAULT_SINGLE_OR_MULTI
  );

  const [bracelet_configuration, setBracelet_configuration] = useState(
    props.bracelet_configuration
  );
  const [dashboard_configuration, setDashboard_configuration] = useState(
    props.dashboard_configuration || 0
  );
  const [bracelet_version, setBracelet_version] = useState(
    props.bracelet_version || "287-2"
  );
  const [showMbAlarm, setShowMbAlarm] = useState(false);

  const [alarmsEnabled, setAlarmsEnabled] = useState(
    props.alarms_enabled == undefined
      ? window.location.href.indexOf("portal") > -1
        ? true
        : false
      : props.alarms_enabled
  );

  console.log({ props });

  const [emailSubject, setEmailSubject] = useState("Change Study Setting");

  const [emailBody, setEmailBody] = useState("");

  useEffect(() => {
    setAlarmsSettings(
      !alarms_settings || Object.keys(alarms_settings).length === 0
        ? DEFAULT_VITAL_ALARM_SETTINGS
        : alarms_settings
    );
    dispatch(UiActions.changeAlarmParams(alarms_settings));
  }, [props.alarms_settings]);

  useEffect(() => {
    setAlarmsSystem(props.alarms_system);
  }, [props.alarms_system]);

  useEffect(() => {
    setTabs(props.tabs || {});
  }, [props.tabs]);

  useEffect(() => {
    setDashboard_configuration(props.dashboard_configuration || 0);
  }, [props.dashboard_configuration]);

  useEffect(() => {
    if (dashboard_settings != undefined) {
      setDashboard_settings(props.dashboard_settings || {});
    }
    // console.log("dashboard_settings", dashboard_settings, props);
  }, [props.dashboard_settings]);

  useEffect(() => {
    setBracelet_version(props.bracelet_version || "287-2");
  }, [props.bracelet_version]);

  let isV2Mode = "287-2" == bracelet_version;
  let isV1Mode = "287-1" == bracelet_version;

  let xParams = {};
  let daysNumber = 15;

  let zeroItems = ITEMS;
  let initialItems = [];
  let xDashOptions = [];

  for (let i in zeroItems) {
    let item = zeroItems[i];
    let { v1, options = [], v2 } = item;
    if (bracelet_version == "287-1") {
      if (v1 == false) {
        continue;
      }
      let fOptions = options.filter((x) => x.v1 != false);
      initialItems.push({
        ...item,
        options: fOptions,
      });
    } else {
      if (v2 == false) {
        continue;
      }
      let fOptions = options.filter((x) => x.v1 != true);
      initialItems.push({
        ...item,
        options: fOptions,
      });
    }
  }

  for (let i in DASHBOARD_OPTIONS) {
    let item = DASHBOARD_OPTIONS[i];
    if (bracelet_version == "287-1") {
      if (item.v1 == false) {
        continue;
      }
    }
    xDashOptions.push(item);
  }
  // console.log("params", params);
  //   for (let i in initialItems) {
  //     let a = initialItems[i];
  //     let { options = [] } = a;
  //     let key = a.name;
  //     if (params[key] == undefined) {
  //       xParams[key] = a.defaultValue;
  //     } else {
  //       xParams[key] = params[key];
  //     }
  //     let val = xParams[key];
  //     let selOpt = options.filter((x) => x.value == val)[0];
  //     console.log("initialItems", a, options, selOpt);
  //     let dN = selOpt == undefined ? undefined : selOpt.daysNumber;

  //     if (dN != undefined && +dN < daysNumber) {
  //       daysNumber = +dN;
  //     }
  //   }

  let baseLife = 10;
  let deductions = [];
  let nonRawDeductions = [];
  let rawBatteryLife = [];
  for (let vitalItem of initialItems) {
    // console.log(vitalItem);
    let { options = [], name } = vitalItem;
    // console.log(name);
    if (params[name] == undefined) {
      xParams[name] = vitalItem.defaultValue;
    } else {
      xParams[name] = params[name];
    }
    // console.log("xParams", xParams);
    let val = xParams[name];
    let selOpt = options.filter((x) => x.value == val)[0];
    // console.log("selOpt", selOpt);
    let paramBatteryLife = selOpt?.batteryDay;

    // let paramBatteryDeduction = selOpt?.batteryDeduction;
    let paramBatteryDeduction = 0; // no more deduction
    let paramBatteryDayRaw = selOpt?.batteryDayRaw;
    rawBatteryLife.push(paramBatteryDayRaw);
    if (
      paramBatteryDeduction !== undefined &&
      name !== "rr_intervals" &&
      name !== "sleep_stage"
    ) {
      deductions.push(paramBatteryDeduction);
    }
    if (
      paramBatteryDeduction !== undefined &&
      (name === "rr_intervals" || name === "sleep_stage")
    ) {
      nonRawDeductions.push(paramBatteryDeduction);
    }
    if (paramBatteryLife < baseLife) {
      baseLife = paramBatteryLife;
    }
    // console.log(paramBatteryLife);
  }
  //   console.log("originalLife", baseLife);
  rawBatteryLife = rawBatteryLife.filter((x) => !isNaN(x));
  let minRawBatteryLife;
  if (rawBatteryLife.length > 0) {
    minRawBatteryLife =
      baseLife <= 4
        ? Math.min(...rawBatteryLife) - 1
        : Math.min(...rawBatteryLife);
  } else {
    minRawBatteryLife = baseLife;
  }

  baseLife = minRawBatteryLife;

  // let maxNonRawDeductions = Math.max(...nonRawDeductions);
  // if (baseLife === 4) {
  //   baseLife = maxNonRawDeductions >= 2 ? 3 : 4;
  // } else {
  //   baseLife =
  //     baseLife - maxNonRawDeductions >= 3 ? baseLife - maxNonRawDeductions : 3;
  // }

  // let totalDeduction = deductions.reduce((p, a) => p + a, 0);
  // if (totalDeduction > 0) {
  //   baseLife = baseLife <= 5 ? baseLife : 5;
  //   let deductedLife = baseLife - totalDeduction;
  //   baseLife = deductedLife < 2 ? 2 : deductedLife;
  // }

  let rawItems = initialItems.filter((x) => x?.isRawData == true);
  let nonRawItems = initialItems.filter((x) => x?.isRawData != true);
  // console.log(nonRawItems);
  // nonRawItems = nonRawItems.map((x) => {
  //     console.log(x.options);

  //     const sortedOptions = x.options.sort((a, b) => {
  //         let scoreA = a.value;
  //         let scoreB = b.value;
  //         console.log(scoreA);
  //         console.log(scoreB);

  //         return scoreA - scoreB;
  //     });
  //     console.log(sortedOptions);

  //     return {
  //         ...nonRawItems,
  //         options: sortedOptions,
  //     };
  // });
  // console.log(nonRawItems);

  let isPpg2Disabled = xParams?.ppg2 == 9;
  // console.log("render: isPpg2Disabled = ", isPpg2Disabled);
  let shouldDisableRawPpg1 = isV1Mode == false && isPpg2Disabled == false;

  let shouldDisableSleep =
    xParams["respiration_rate"] === 5 || xParams["respiration_rate"] === 0;

  // if (shouldShowPpg1Row == false) {
  //     // rawItems = rawItems.filter(x => (x.name != 'ppg1'));
  // }

  let xItems = initialItems.map((x) => ({
    name: x.name,
    defaultValue: x.defaultValue,
  }));
  // console.log("initialItems x: ", xItems);
  // console.log("rawItems x: ", rawItems);
  // console.log("render xParams: ", xParams);
  // console.log(
  //   "initialItems defaults: ",
  //   xItems.reduce((mp, x) => ({ ...mp, [x.name]: x.defaultValue })),
  //   {}
  // );
  let isDebug_ = isDebugMode();

  // console.log("VitalParametersEditor: render: code = ", code);

  return (
    <Wrapper>
      {/*{isDebugMode() == false ? null :*/}
      {/*    <TabOptionsSection>*/}
      {/*        <TabOptionsName>*/}
      {/*            Bracelet confguration*/}
      {/*        </TabOptionsName>*/}
      {/*        <div style={{width: '100%'}}>*/}
      {/*            <Select*/}
      {/*                options={BRACELET_CONFIG_OPTIONS}*/}
      {/*                value={bracelet_configuration == undefined ? undefined : BRACELET_CONFIG_OPTIONS.filter(x => (x.value == bracelet_configuration))[0]}*/}
      {/*                onChange={x => {*/}
      {/*                    if (x == undefined) {*/}
      {/*                        setBracelet_configuration(undefined);*/}
      {/*                    } else {*/}
      {/*                        setBracelet_configuration(x.value);*/}
      {/*                    }*/}
      {/*                }}*/}
      {/*            />*/}
      {/*        </div>*/}
      {/*    </TabOptionsSection>*/}
      {/*}*/}

      {!isHCPAdmin || isPatientPortal == true ? null : (
        <>
          <div>
            <Select
              value={
                BRACELETS_OPTIONS.filter(
                  (xx) => xx.value == bracelet_version
                )[0]
              }
              options={BRACELETS_OPTIONS}
              onChange={(x) => {
                setBracelet_version(x.value);
              }}
            />
          </div>

          <SectionHeading className="heading">Vital Parameters</SectionHeading>

          <ListPlaceholder>
            {nonRawItems.map((a, i) => {
              let val = xParams[a.name];
              if (val == undefined) {
                val = a.defaultValue;
              }
              let options = a.options;

              if (isDebug_ == false) {
                options = options.filter((x) => x.isOld != true);
              } else {
                options = options.filter((x) =>
                  isDebug_ == false ? x.isDebug != true : true
                );
              }
              return (
                <Row key={i}>
                  <Label className="setting-label">{a.label}</Label>
                  <OptionsPlaceholder>
                    {options.map((b, j) => {
                      let isDisabled =
                        shouldDisableSleep &&
                        DISABLE_WHEN_5MINS.includes(a.name) &&
                        b.value !== 9;

                      return (
                        <OptionItem
                          key={j}
                          style={isDisabled ? { opacity: "50%" } : {}}
                          selected={b.value == val}
                          onClick={() => {
                            if (isDisabled) {
                              return;
                            }
                            if (DISABLE_WHEN_5MINS.includes(a.name)) {
                              setSomeOldValues({
                                ...someOldValues,
                                [a.name]: b.value,
                              });
                            }

                            // if (a.name === "sleep_stage") {
                            //   setOldSleepValue(b.value);
                            // }
                            // if (a.name === "rr_intervals") {
                            //   setOldRrValue(b.value);
                            // }
                            let newParams = {
                              ...xParams,
                              [a.name]: b.value,
                            };
                            let disableSleep = false;

                            if (NON_RAW_NAMES.includes(a.name)) {
                              let _label = b.label;
                              // console.log("_label", _label);

                              for (let nrn of NON_RAW_NAMES) {
                                const _Options = nonRawItems.find(
                                  (x) => x.name === nrn
                                ).options;
                                const _Option = _Options.find(
                                  (x) => x.label === _label
                                );
                                if (!_Option) {
                                  continue;
                                }
                                let _value = _Option.value;
                                // if (_label.includes("5min")) {
                                newParams[nrn] = _value;
                                // }
                              }
                              if (
                                _label.includes("30min") ||
                                _label.includes("5min")
                              ) {
                                // console.log("oldSleepValue", oldSleepValue);
                                // newParams["sleep_stage"] = 9;
                                newParams[DISABLE_WHEN_5MINS[0]] = 9;
                                newParams[DISABLE_WHEN_5MINS[1]] = 9;
                                newParams[DISABLE_WHEN_5MINS[2]] = 9;
                                newParams[DISABLE_WHEN_5MINS[3]] = 9;
                                disableSleep = true;
                              } else {
                                // revert to original state for sleep
                                // newParams["sleep_stage"] =
                                //   oldSleepValue !== undefined
                                //     ? oldSleepValue
                                //     : 1;
                                DISABLE_WHEN_5MINS.forEach((key, index) => {
                                  newParams[key] =
                                    someOldValues[key] !== undefined
                                      ? someOldValues[key]
                                      : 9;
                                });

                                // newParams[DISABLE_WHEN_5MINS[0]] =
                                //   someOldValues[DISABLE_WHEN_5MINS[0]] !==
                                //   undefined
                                //     ? someOldValues[DISABLE_WHEN_5MINS[0]]
                                //     : 9;
                                // newParams[DISABLE_WHEN_5MINS[1]] =
                                //   someOldValues[DISABLE_WHEN_5MINS[1]] !==
                                //   undefined
                                //     ? someOldValues[DISABLE_WHEN_5MINS[1]]
                                //     : 9;
                                // newParams[DISABLE_WHEN_5MINS[2]] =
                                //   someOldValues[DISABLE_WHEN_5MINS[2]] !==
                                //   undefined
                                //     ? someOldValues[DISABLE_WHEN_5MINS[2]]
                                //     : 9;
                                // newParams[DISABLE_WHEN_5MINS[3]] =
                                //   someOldValues[DISABLE_WHEN_5MINS[3]] !==
                                //   undefined
                                //     ? someOldValues[DISABLE_WHEN_5MINS[3]]
                                //     : 9;
                              }
                            } else if (NAND_NAME.includes(a.name)) {
                              let currentNibp = newParams["nibp"];
                              let currentEmog = newParams["emography"];

                              let newNibp;
                              let newEmog;
                              if (a.name === "nibp") {
                                newNibp = b.value;
                                newEmog = b.value === 0 ? 9 : currentEmog;
                              }
                              if (a.name === "emography") {
                                newEmog = b.value;
                                newNibp = b.value === 0 ? 9 : currentNibp;
                              }
                              newParams["emography"] = newEmog;
                              newParams["nibp"] = newNibp;
                              setSomeOldValues({
                                ...someOldValues,
                                emography: newEmog,
                              });
                            } else {
                              newParams[a.name] = b.value;
                              // setParams(newParams);
                            }
                            // console.log(newParams);
                            const newEmailBody = generateEmailBody(
                              props.params,
                              newParams
                            );
                            setParams(newParams);
                            setEmailBody(newEmailBody);
                          }}
                        >
                          {b.label}
                        </OptionItem>
                      );
                    })}
                  </OptionsPlaceholder>
                </Row>
              );
            })}
          </ListPlaceholder>

          <SectionHeading>Raw Data</SectionHeading>

          <ListPlaceholder>
            {rawItems.map((a, i) => {
              let val = xParams[a.name];
              if (val == undefined) {
                val = a.defaultValue;
              }
              let isDisabled = shouldDisableRawPpg1 == true && a.name == "ppg1";
              let options = a.options;
              return (
                <Row key={i} style={{ opacity: isDisabled ? 0.3 : 1 }}>
                  <Label className="setting-label">{a.label}</Label>
                  <OptionsPlaceholder>
                    {options.map((b, j) => {
                      return (
                        <OptionItem
                          key={j}
                          selected={b.value == val && isDisabled != true}
                          onClick={() => {
                            if (isDisabled == true) {
                              return;
                            }
                            let newParams = {
                              ...xParams,
                              [a.name]: b.value,
                            };
                            const newEmailBody = generateEmailBody(
                              props.params,
                              newParams
                            );
                            setParams(newParams);
                            setEmailBody(newEmailBody);
                          }}
                        >
                          {b.label}
                        </OptionItem>
                      );
                    })}
                  </OptionsPlaceholder>
                </Row>
              );
            })}
          </ListPlaceholder>

          <div style={{ paddingBottom: 10, textAlign: "center" }}>
            <SectionHeading className="heading">Autonomy</SectionHeading>
            <span style={{ fontWeight: "bold" }} className="setting-label">
              {`Estimated battery life: ${baseLife} days`}
            </span>
          </div>
        </>
      )}

      {/* {emailBody && <div>{emailBody}</div>} */}

      {isHCPAdmin ? ( // 2024-12-13 put back dashboard on portal.corsano as M requests
        // {isHCPAdmin && window.location.href.indexOf("portal.corsano.com") < 0 ? (
        <div className="dashboard-container">
          <SectionHeading className="heading">Dashboard</SectionHeading>

          <TabOptionsSection>
            <div style={{ width: "100%" }}>
              <Select
                options={xDashOptions}
                value={
                  dashboard_configuration == undefined
                    ? undefined
                    : xDashOptions.filter(
                        (x) => x.value == dashboard_configuration
                      )[0]
                }
                onChange={(x) => {
                  // console.log("setDashboard_configuration", x, dashboard_settings);

                  if (x == undefined) {
                    setDashboard_configuration(undefined);
                  } else {
                    setDashboard_configuration(x.value);
                  }
                }}
              />
            </div>
          </TabOptionsSection>

          {
            // {dashboard_configuration != 5 ? null : (     // remove 2024 11 22
            <div style={{ marginTop: 15 }}>
              <DashboardSettingsEditor
                dashboard_settings={dashboard_settings}
                onChange={(d) => {
                  setDashboard_settings(d);
                }}
              />
            </div>
          }
        </div>
      ) : null}
      {isHCPAdmin ? (
        <TabOptionsSection>
          {dashboard_configuration === 5 ? null : (
            <div>
              <SectionHeading className="heading">Tab Settings</SectionHeading>
              <HeadingGuideText className="setting-label">
                Select maximum 4
              </HeadingGuideText>
              <TabOptionsPlaceholder>
                {TABS_OPTIONS.map((a, i) => {
                  let val = (tabs || {})[a.name];
                  if (val == undefined) {
                    val = a.defaultValue;
                  }
                  let isSelected = val == true;
                  return (
                    <TabOptItem key={i} className="setting-label">
                      <OptImg
                        src={isSelected == true ? ch_checked : ch_void}
                        onClick={() => {
                          let defaults = TABS_OPTIONS.reduce(
                            (mp, x) => ({
                              ...mp,
                              [x.name]: x.defaultValue,
                            }),
                            {}
                          );
                          setTabs({
                            ...defaults,
                            ...tabs,
                            [a.name]: !val,
                          });
                        }}
                      />
                      {a.label}
                    </TabOptItem>
                  );
                })}
              </TabOptionsPlaceholder>
            </div>
          )}
          <SectionHeading
            className="heading"
            style={{
              marginTop: "1rem",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            Alarm System Settings
            <InfoToolBadgeGrey
              show={true}
              width={"1em"}
              height={"1em"}
              type={"ALARM_SYSTEM_SETTINGS"}
            />
          </SectionHeading>
          <HeadingGuideText className="setting-label">
            Select one system
          </HeadingGuideText>
          <UserAlarmsTool
            alarms_system={alarmsSystem}
            onChange={(new_alarms_system) => {
              setAlarmsSystem(new_alarms_system);
            }}
          />
          <SectionHeading className="heading" style={{ marginTop: "1rem" }}>
            Alarm Vital Parameters
          </SectionHeading>
          <HeadingGuideText className="setting-label">
            Select maximum 5
          </HeadingGuideText>
          <UserAlarmParametersTools
            alarms_settings={alarmsSettings}
            alarms_system={alarmsSystem}
            onChange={(alarms_settings) => {
              setAlarmsSettings(alarms_settings);
            }}
          />
          <SectionHeading
            className="heading"
            style={{
              marginTop: "1rem",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            Alarm Base
            {/* <InfoToolBadgeGrey width={"1em"} height={"1em"} /> */}
          </SectionHeading>
          <HeadingGuideText className="setting-label">
            Select one
          </HeadingGuideText>
          <UserAlarmBaseTool
            alarmsBase={alarmsBase}
            onChange={(_alarmsBase) => {
              // console.log(alarmsSystem);
              // console.log(alarmsBase);
              // console.log(alarmsSettings);

              setAlarmsBase(_alarmsBase);
            }}
          />
          {window.location.href.indexOf("study.corsano") > 0 && isHCPAdmin && (
            <>
              <SectionHeading
                className="heading"
                style={{
                  marginTop: "1rem",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                Single-user or Multi-user
                {/* <InfoToolBadgeGrey width={"1em"} height={"1em"} /> */}
              </SectionHeading>
              <HeadingGuideText className="setting-label">
                Select one
              </HeadingGuideText>
              <SingleMultiUsersTool
                singleOrMulti={singleOrMulti}
                onChange={(_singleOrMulti) => {
                  setIsSingleOrMulti(_singleOrMulti);
                }}
              />
            </>
          )}
        </TabOptionsSection>
      ) : null}

      {/*{window.location.href.indexOf("portal") == -1 ? null : (*/}
      {/*    <TabOptionsSection>*/}
      {/*        <SectionHeading>Alarm Sounds</SectionHeading>*/}

      {/*        <TogglerComponent*/}
      {/*            options={[*/}
      {/*                {*/}
      {/*                    label: "Disabled",*/}
      {/*                    value: false,*/}
      {/*                },*/}
      {/*                {*/}
      {/*                    label: "Enabled",*/}
      {/*                    value: true,*/}
      {/*                },*/}
      {/*            ]}*/}
      {/*            value={alarmsEnabled}*/}
      {/*            onChange={(v) => {*/}
      {/*                setAlarmsEnabled(v);*/}
      {/*            }}*/}
      {/*        />*/}
      {/*    </TabOptionsSection>*/}
      {/*)}*/}
      {isHCPAdmin ? (
        <BottomPlaceholder>
          <Button
            onClick={() => {
              setEmailBody("");
              let suspense =
                !isDebugMode() &&
                window.location.href.includes("study.corsano");
              // let suspense = true;
              if (loading == true) {
                return;
              }

              if (suspense === true) {
                console.log("Suspending");

                // console.log(props.tabs, tabs);
                // console.log(
                //   {
                //     config: props.dashboard_configuration,
                //     settings: props.dashboard_settings,
                //   },
                //   {
                //     config: dashboard_configuration,
                //     settings: dashboard_settings,
                //   }
                // );
                // console.log(
                //   {
                //     tab: props.tabs,
                //     alarmSystem: alarms_system,
                //     alarmParams: alarms_settings,
                //     alarmBase: alarms_base,
                //   },
                //   {
                //     tab: tabs,
                //     alarmSystem: alarmsSystem,
                //     alarmParams: alarmsSettings,
                //     alarmBase: alarmsBase,
                //   }
                // );

                const d = emailBodyWithDashboard(
                  {
                    config: props.dashboard_configuration,
                    settings: props.dashboard_settings || {},
                  },
                  {
                    config: dashboard_configuration,
                    settings: dashboard_settings,
                  }
                );

                const t = emailBodyWithAlarms(
                  {
                    tab: props.tabs,
                    alarmSystem: alarms_system,
                    alarmParams: alarms_settings,
                    alarmBase: alarms_base,
                  },
                  {
                    tab: tabs,
                    alarmSystem: alarmsSystem,
                    alarmParams: alarmsSettings,
                    alarmBase: alarmsBase,
                  }
                );
                // console.log(t);

                setEmailBody((emailBody) => {
                  return `
Study Code: ${code} ${newLineBreak}${newLineBreak}
HCP email address: ${hcpEmail} ${newLineBreak}
${newLineBreak}
${emailBody === "" ? generateEmailBody(props.params, params) : emailBody}
${d}
${t}
${newLineBreak}${newLineBreak}${newLineBreak}`;
                });
                console.log({ emailBody });

                setShowMbAlarm(true);
                return;
              } else {
                let defaults = TABS_OPTIONS.reduce(
                  (mp, x) => ({
                    ...mp,
                    [x.name]: x.defaultValue,
                  }),
                  {}
                );
                console.log(
                  "onSave: xParams, tabs, bracelet_configuration = ",
                  xParams,
                  tabs,
                  bracelet_configuration,
                  alarmsSettings,
                  alarmsSystem,
                  alarmsBase
                );

                if (
                  (xParams.emography != 9 || xParams.bioz != 9) &&
                  bracelet_version == "287-2"
                ) {
                  console.log("--->>> STRICT MODE");
                  if (xParams.ppg2 == 1) {
                    return window.alert(
                      "Error! PPG G/R/IR 128HZ can not be enabled when BIOZ/Empgraphy is enabled. Please change your settings."
                    );
                  }
                  if (xParams.nibp != 9) {
                    return window.alert(
                      "Error! NIBP can not be enabled when Emography or BIOZ is enabled. Please change your settings."
                    );
                  }
                }
                let selTabsNumber = Object.keys(tabs).filter(
                  (xk) => tabs[xk] == true
                ).length;
                if (selTabsNumber > 4) {
                  return window.alert(
                    "Error! You can select maximum 4 tabs. Please change settings!"
                  );
                }
                onSave(
                  xParams,
                  { ...defaults, ...tabs },
                  dashboard_configuration,
                  bracelet_version,
                  alarmsEnabled,
                  getEnhSettings(dashboard_settings),
                  // alarmsSettings,
                  alarmsSystem === "news"
                    ? VITAL_ALARM_SETTINGS_ALL_ON
                    : alarmsSettings,
                  alarmsSystem,
                  alarmsBase,
                  isSingleOrMulti
                );
                toast.success(
                  `Alarm saved to ${ALARM_NAMES_MAP[alarmsSystem]}`
                );
              }
            }}
          >
            <Spin visible={loading} />
            {loading == true ? null : "Save"}
          </Button>
        </BottomPlaceholder>
      ) : null}

      {showMbAlarm && (
        <NiceModal
          onClose={() => {
            setShowMbAlarm(false);
          }}
        >
          <div
            style={{
              width: "41vw",
              fontSize: "1em",
              fontWeight: 500,
              margin: "10px 50px",
            }}
          >
            <div style={{ fontSize: "22px", fontWeight: 700 }}>
              Review of Requested Changes to Research Study Settings
            </div>
            <p>Dear Principal Investigator / HCP Admin,</p>
            <p>
              Corsano needs to review the requested changes to your research
              study settings. By clicking the link below, an email with the
              proposed changes will be sent to us automatically.
            </p>
            <p>
              Corsano will review and respond to your request within 24 hours
              (on working days).
            </p>
            <p>
              <a
                href={`mailto:mburel@corsano.com?subject=${"Review of Requested Changes to Research Study Settings"}&body=${emailBody}`}
                target="_self"
              >
                [Send Request Email]
              </a>{" "}
            </p>
            <p>Thank you for your cooperation.</p>
            <p>
              Best regards, <br></br>The Corsano Team{" "}
            </p>
          </div>
          <Button
            style={{ width: "30%", margin: "0 auto", marginTop: "1rem" }}
            onClick={() => {
              setShowMbAlarm(false);
            }}
          >
            Close
          </Button>
        </NiceModal>
      )}
    </Wrapper>
  );
}

const EmailLink = (props) => (
  <a href="mailto:example@example.com">{props.text}</a>
);

const ALARM_NAMES_MAP = {
  // between_
  news: "NEWS",
  none: "None",
  "between-flags": "Between-flags",
};

const OptImg = styled.img`
  height: 14px;
  margin-right: 5px;
  cursor: pointer;
  opacity: 0.9;

  :hover {
    opacity: 1;
  }
`;

const TabOptionsSection = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const TabOptionsName = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

const SectionHeading = styled.div`
  font-size: 16px;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  padding-top: 20px;
`;

const HeadingGuideText = styled.div`
  font-size: 14px;
  font-style: italic;
  opacity: 0.7;
  text-align: center;
  margin-top: -10px;
  margin-bottom: 10px;
`;

const TabOptionsPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const TabOptItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
`;

const ListPlaceholder = styled.div``;

const BottomPlaceholder = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const Wrapper = styled.div``;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 6px;
  padding-bottom: 6px;
  border-bottom: 1px solid lightgrey;
`;

const Label = styled.div`
  font-weight: bold;
`;

const OptionsPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OptionItem = styled.div`
  margin: 4px;
  padding: 5px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  background: ${(props) => (props.selected == true ? "#0072BC" : "white")};
  color: ${(props) => (props.selected == true ? "white" : "black")};
  cursor: ${(props) => (props.selected == true ? "default" : "pointer")};
  font-size: 14px;

  :hover {
    opacity: 0.7;
  }
`;

const Button = styled.div`
  height: 42px;
  background: ${(props) =>
    props.background == undefined ? "#0072BC" : props.background};
  border-radius: 8px;
  cursor: ${(props) => (props.disabled == true ? "default" : "pointer")};
  opacity: ${(props) => (props.disabled == true ? 0.5 : 1)};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;

  padding-left: 20px;
  padding-right: 20px;

  :hover {
    opacity: ${(props) => (props.disabled == true ? 0.5 : 0.8)};
  }
`;

//  t// const

const DASHBOARD_ITEMS = [
  {
    label: "Activity",
    value: "activity",
  },
  {
    label: "Sleep",
    value: "sleep",
  },
  {
    label: "Pulse Rate",
    value: "heart_rate",
  },
  {
    label: "ECG",
    value: "ecg",
  },
  {
    label: "Saturation",
    value: "spo2",
  },
  {
    label: "Respiration",
    value: "respiration",
  },
  {
    label: "Temperature",
    value: "temperature",
  },
  {
    label: "NIBP",
    value: "nibp",
  },
  {
    label: "Stress",
    value: "stress",
  },
  {
    label: "Workout",
    value: "workout",
  },
  {
    label: "Spirometer",
    value: "spirometer",
  },
  {
    label: "Weight",
    value: "weight",
  },
  {
    label: "Insights",
    value: "insights",
  },
];
