import React, { useState, useEffect } from "react";
import moment from "moment";
import uuid from "uuid";
import ls from "local-storage";
import styled from "styled-components";
import DoctorAPI from "../../../api/DoctorAPI";
import { isDebugMode } from "../../../helpers/UiHelper";
import {
  LineFilterCheckbox,
  LineFilterContainer,
  LineFilterItem,
  LineLabel,
} from "../../baselines/PatientDayPulseRateBaselineTool";
import { LINE_COLORS } from "../../ui/templates";
import {
  Legend,
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  ReferenceArea,
  XAxis,
  YAxis,
} from "recharts";

import { Code } from "react-content-loader";
import axios from "axios";

const LINES = [
  {
    value: "bpmRaw",
    label: "Raw BPM",
    color: LINE_COLORS.raw,
    width: 1,
    show: true,
  },
  {
    value: "bpmMa",
    label: "Baseline",
    color: LINE_COLORS.baseline,
    width: 2,
    show: true,
  },
  {
    value: "spo2Raw",
    label: "Raw SpO2",
    color: LINE_COLORS.raw,
    width: 1,
    show: true,
  },
  {
    value: "temperature2Raw",
    label: "Raw Temperature",
    color: LINE_COLORS.raw,
    width: 1,
    show: true,
  },
  {
    value: "rrRaw",
    label: "Raw",
    color: LINE_COLORS.raw,
    width: 1,
    show: true,
  },
  {
    value: "rrMa",
    label: "Baseline",
    color: LINE_COLORS.baseline,
    width: 2,
    show: true,
  },
  {
    value: "blood_pressure",
    label: "Systolic BP",
    color: "#3333FF",
    width: 1,
    show: true,
  },
  {
    value: "blood_pressure",
    label: "Diastolic BP",
    color: "#FF4E45",
    width: 2,
    show: true,
  },
];

function annotateDayNight(data) {
  const dataWithDayNight = [...data];

  for (let i = 0; i < dataWithDayNight.length; i++) {
    if (
      moment(dataWithDayNight[i]?.slot).hours() >= 0 &&
      moment(dataWithDayNight[i]?.slot).hours() < 8
    ) {
      dataWithDayNight[i] = { ...dataWithDayNight[i], ["daynight"]: 15 };
    } else {
      dataWithDayNight[i] = { ...dataWithDayNight[i], ["daynight"]: 150 };
    }
  }
  return dataWithDayNight;
}

function calculateMovingAverage(data, key, windowSize) {
  const dataWithMovingAverage = [...data];
  for (let i = 0; i < dataWithMovingAverage.length; i++) {
    if (!dataWithMovingAverage[i][key]) {
      continue;
    }
    let sum = 0;
    let count = 0;

    for (let j = Math.max(0, i - windowSize + 1); j <= i; j++) {
      if (
        dataWithMovingAverage[j][key] &&
        dataWithMovingAverage[j][key] !== undefined
      ) {
        if (
          dataWithMovingAverage[i]["daynight"] !==
          dataWithMovingAverage[j]["daynight"]
        ) {
          sum = 0;
          count = 0;
          // continue;
        }
        sum += dataWithMovingAverage[j][key];
        count++;
      }
    }

    const movingAverage = count > 0 ? sum / count : 0;
    // lastMovingAverage = movingAverage;
    dataWithMovingAverage[i] = {
      ...dataWithMovingAverage[i],
      [`${key}_moving_average`]: Number(movingAverage.toFixed(2)),
    };
  }
  return dataWithMovingAverage;
}

function calculateStd(data, key) {
  const movingAverages = data.map((obj) => obj[key]);
  const mean =
    movingAverages.reduce((acc, val) => acc + val, 0) / movingAverages.length;
  const squaredDifferences = movingAverages.map((x) => Math.pow(x - mean, 2));
  const sumSquaredDiff = squaredDifferences.reduce((acc, val) => acc + val, 0);
  const variance = sumSquaredDiff / movingAverages.length;
  const standardDeviation = Math.sqrt(variance);
  return standardDeviation;
}

function calculateDeviation(data, bpmstd, rrstd) {
  const bpmMovingAverages = data.map((obj) =>
    Number(obj["bpm_moving_average"])
  );
  const rrMovingAverages = data.map((obj) =>
    Number(obj["respiration_rate_moving_average"])
  );

  const bpmSigmasFromStd = bpmMovingAverages.map(
    (movingAvg) => movingAvg / bpmstd
  );
  const rrSigmasFromStd = rrMovingAverages.map(
    (movingAvg) => movingAvg / rrstd
  );

  const newData = data.map((obj, index) => ({
    ...obj,
    bpm_from_std: bpmSigmasFromStd[index],
    rr_from_std: rrSigmasFromStd[index],
  }));

  return newData;
}

function riskScoreAgainstStd(input) {
  // for pulse rate and rr
  if (!input) {
    return 0;
  }
  if (input >= 0 && input < 2) {
    return 0;
  } else if (input >= 2 && input < 3) {
    return 0.25;
  } else if (input >= 3 && input < 4) {
    return 0.5;
  } else if (input >= 4) {
    return 1;
  } else {
    return 0;
  }
}

function riskScoreSpo2(input) {
  if (!input) {
    return 0;
  }
  if (input >= 95) {
    return 0;
  } else if (input >= 93 && input < 95) {
    return 0.25;
  } else if (input >= 91 && input < 93) {
    return 0.5;
  } else if (input < 91) {
    return 1;
  } else {
    return 0;
  }
}

function riskScoreTemp(input) {
  if (!input) {
    return 0;
  }
  if (input < 37.5) {
    return 0;
  } else if (input >= 37.5 && input < 38) {
    return 0.25;
  } else if (input >= 38 && input < 38.5) {
    return 0.75;
  } else if (input >= 38.5) {
    return 1;
  }
}

function calculateCorRiskForAllParameters(data) {
  const bpmStds = data.map((obj) => obj.bpm_from_std);
  const rrStds = data.map((obj) => obj.rr_from_std);
  const bpmScore = bpmStds.map((bpm) => riskScoreAgainstStd(bpm));
  const rrScore = rrStds.map((rr) => riskScoreAgainstStd(rr));
  const spo2Series = data.map((obj) => obj.spo2);
  const spo2Score = spo2Series.map((spo2) => riskScoreSpo2(spo2));

  const newData = data.map((obj, index) => ({
    ...obj,
    bpm_score: bpmScore[index],
    rr_score: rrScore[index],
    spo2_score: spo2Score[index],
    sumScore: bpmScore[index] + rrScore[index] + spo2Score[index],
  }));
  return newData;
}

export default function PatientMPIToolBaseline(props) {
  const { uuid, t, windowSec = 10 * 60, unit = "C" } = props;
  let health_token = ls.get("health_cloud_token");
  const theme = ls.get("theme");
  const tickProps = {
    tick: { fill: theme === "dark" ? "white" : "#1f1f1f" },
    tickLine: { fill: theme === "dark" ? "white" : "#1f1f1f" },
  };

  const tooltipWrapper = {
    wrapperStyle: {
      background: theme === "dark" ? "#1c1c1cec" : "#fffe",
      color: theme === "dark" ? "#819a96ec" : "#212a26ec",
      padding: "1rem",
      zIndex: "100",
    },
    cursor: { stroke: "#ee5678", strokeWidth: 2 },
  };

  const [activityPoints, setActivityPoints] = useState([]);
  const [averagedActivityPoints, setAveragedActivityPoints] = useState([]);
  const [temperaturePoints, setTemperaturePoints] = useState([]);
  const [hrvPoints, setHrvPoints] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downActivityPoints, setDownActivityPoints] = useState([]);
  const [bpmStd, setBpmStd] = useState(0);
  const [rrStd, setRrStd] = useState(0);
  const [bpData, setBpData] = useState([]);
  const [xDomain, setXDomain] = useState([
    +moment(+t).endOf("day").valueOf(),
    +moment(+t).startOf("day").add(-10, "day").valueOf(),
  ]);

  const [showLine, setShowLine] = useState({
    bpmRaw: true,
    bpmMa: true,
    rrRaw: true,
    rrMa: true,
  });

  const handleLineCheckbox = (evt) => {
    const name = evt.target.name;
    let oldValue = showLine[name];
    const newSetting = { ...showLine, [name]: !oldValue };
    setShowLine(newSetting);
  };

  useEffect(() => {
    setLoading(true);
    let to = +moment(+t).endOf("day");
    let from = +moment(+t).startOf("day").add(-10, "day");
    setXDomain([to.valueOf(), from.valueOf()]);

    // axios
    //   .get(
    //     // `https://api.health.cloud.corsano.com/v2/users/${uuid}/raw-metrics/ppg1/aggregate`,
    //     `https://api.health.cloud.corsano.com/v2/raw-metrics/heart-rate-variability`,
    //     {
    //       headers: { authorization: `Bearer ${health_token}` },
    //       params: {
    //         // fields: "acc",
    //         // aggregation_window_seconds: "60",
    //         from_date: "2024-05-01T15:31:45.000",
    //         to_date: "2024-05-01T16:30:45.000",
    //       },
    //     }
    //   )
    //   .then((d) => {
    //     console.log("raw-metrics", d);
    //   })
    //   .catch((err) => {
    //     console.error("RAW_METRICS", err);
    //   });
    // DoctorAPI.getPatientsLatestRawMetrics(uuid).then((data) => {
      // console.log("getPatientsLatestRawMetrics", data);
    // });

    DoctorAPI.getAggregatedPoints(
      uuid,
      "activity",
      ["bpm", "last_steps", "respiration_rate", "spo2"],
      windowSec,
      from,
      to
    )
      .then((data) => {
        const aDN = annotateDayNight(data);
        const dataWithMovingAverage1 = calculateMovingAverage(aDN, "bpm", 18);
        const dataWithMovingAverage2 = calculateMovingAverage(
          dataWithMovingAverage1,
          "respiration_rate",
          18
        );
        const _bpmstd = calculateStd(data, "bpm");
        const _rrstd = calculateStd(data, "respiration_rate");
        setBpmStd(_bpmstd);
        setRrStd(_rrstd);
        const ff = calculateDeviation(dataWithMovingAverage2, _bpmstd, _rrstd);
        const dataWithRiskScore = calculateCorRiskForAllParameters(ff);
        setActivityPoints(dataWithRiskScore);
        return dataWithRiskScore;
      })
      .then((stage1Data) => {
        DoctorAPI.getAggregatedPoints(
          uuid,
          "temperature",
          ["temp_sk1", "temp_sk2", "temp_amb"],
          windowSec,
          from,
          to
        ).then((data) => {
          // const aDN = annotateDayNight(data);
          setTemperaturePoints(data);
          // const dataWithMovingAverage = calculateMovingAverage(aDN, "temp_sk1", 18);

          // setActivityPoints(newData)
          const tempSeries = data.map((obj) => obj["temp_sk1"]);

          const tempScore = tempSeries.map((temp) => riskScoreTemp(temp));
          // console.log("tmepodata", data);
          // console.log("tempSeries", tempSeries);
          // console.log("tempScore", tempScore);

          const newData = stage1Data.map((val, ind) => {
            if (val.slots === temperaturePoints[ind]?.slots) {
              return {
                ...val,
                temp: tempSeries[ind],
                temp_score: tempScore[ind],
                sumScore:
                  tempScore[ind] === undefined
                    ? val.sumScore
                    : val.sumScore + tempScore[ind],
              };
            }
          });
          setActivityPoints(newData);
          // console.log(activityPoints);

          setLoading(false);
        });
      });

    // const bp_api = `https://api.health.cloud.corsano.com/v2/users/blood-pressure-measurements?user_uuids=${uuid}&start_timestamp_from=${moment(
    //   from
    // ).toISOString()}&start_timestamp_to=${moment(to).toISOString()}`;
    // console.log("bp_pai", bp_api);

    // axios
    //   .get(bp_api, {
    //     headers: {
    //       "Content-Type": "application/json",
    //       // "x-health-token": ls.get("health_cloud_token"),
    //     },
    //   })
    //   .then((data) => {
    //     // setTemperaturePoints(data);
    //   });

    DoctorAPI.getAIBpData(uuid, from, to).then((data) => {
      // console.log("BLOOD PRESSURE:", data);
      setBpData(data);
    });

    DoctorAPI.getAggregatedPoints(
      uuid,
      "temperature",
      ["temp_sk1", "temp_sk2", "temp_amb"],
      windowSec,
      from,
      to
    ).then((data) => {
      setTemperaturePoints(data);
    });
    DoctorAPI.getAggregatedPoints(
      uuid,
      "heart-rate-variability",
      ["rmssd"],
      windowSec,
      from,
      to
    ).then((data) => {
      setHrvPoints(data);
    });
    DoctorAPI.getAggregatedPoints(
      uuid,
      "activity",
      ["bpm"],
      windowSec * 10,
      from,
      to
    ).then((data) => {
      setDownActivityPoints(data);
      setLoading(false);
    });
  }, []);

  let downBpmPoints = (downActivityPoints || [])
    .filter((x) => x?.bpm != undefined)
    .map((x) => x.bpm);
  let bpmPoints = (activityPoints || [])
    .filter((x) => x?.bpm != undefined)
    .map((x) => x.bpm);
  let maxBpm = bpmPoints.length == 0 ? 100 : Math.max(...bpmPoints);
  let bpmRange = [30, Math.ceil(Math.floor(maxBpm + 10) / 10.0) * 10];
  let bpRange = [40, 200];

  // let maxHr = (activityPoints == undefined || activityPoints.)

  if (loading == true) {
    return <Code />;
  }

  let scores = [];
  let scoreRange = [0, 4];
  for (let i = 0; i < 1000; i++) {
    let point = Math.floor(Math.random() * 5);
    scores.push(point);
  }

  return (
    <Wrapper>
      <Row>
        <RowHeading className="heading">Cor-RISK</RowHeading>
        <ChartPlaceholder>
          <LineChart
            width={1200}
            height={200}
            data={activityPoints}
            connectNulls={true}
            margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
          >
            <XAxis
              dataKey={"slot"}
              tickFormatter={(a) => {
                return moment(a).format("DD-MM");
              }}
              {...tickProps}
            />
            <Tooltip {...tooltipWrapper} content={<ScoreTooltip />} />
            {/* <Tooltip /> */}
            <YAxis
              type={"number"}
              domain={scoreRange}
              tickCount={5}
              {...tickProps}
            />

            <ReferenceArea y1={0} y2={1} />
            <ReferenceArea y1={1} y2={2} fill={"yellow"} strokeOpacity={0.6} />
            <ReferenceArea y1={2} y2={3} fill={"orange"} strokeOpacity={0.8} />
            <ReferenceArea y1={3} y2={4} fill={"red"} />
            <CartesianGrid stroke="#eee" />

            <Line
              type="monotone"
              dataKey={"sumScore"}
              name={"Risk"}
              // stroke="#111"
              stroke={LINE_COLORS.baseline}
              strokeWidth={2}
              animationDuration={1}
              dot={false}
              connectNulls={false}
            />
          </LineChart>
        </ChartPlaceholder>
      </Row>
      <Row>
        <RowHeading className="heading">Pulse Rate</RowHeading>
        <ChartPlaceholder>
          <LineChart
            width={1200}
            height={200}
            data={activityPoints}
            connectNulls={true}
            margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
          >
            <XAxis
              dataKey={"slot"}
              tickFormatter={(a) => {
                return moment(a).format("DD.MM");
              }}
              {...tickProps}
            />
            <Tooltip {...tooltipWrapper} content={<MetricsTooltip />} />
            <YAxis domain={bpmRange} {...tickProps} />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            {/* <Legend /> */}
            <Line
              type="monotone"
              name={"BPM"}
              dataKey={"bpm"}
              stroke={LINE_COLORS.raw}
              strokeWidth={1}
              animationDuration={1}
              dot={false}
              connectNulls={false}
              hide={!showLine["bpmRaw"]}
            />
            <Line
              type="monotone"
              dataKey={"bpm_moving_average"}
              name={"BPM-ma"}
              stroke={LINE_COLORS.baseline}
              strokeWidth={2}
              animationDuration={1}
              dot={false}
              connectNulls={true}
              hide={!showLine["bpmMa"]}
            />
          </LineChart>
        </ChartPlaceholder>
        <LineFilterContainer>
          {LINES.map((f, i) => {
            if (!f.value.includes("bpm")) {
              return null;
            }
            return (
              <LineFilterItem key={i}>
                <LineFilterCheckbox
                  type="checkbox"
                  name={f.value}
                  checked={showLine[f.value]}
                  onChange={handleLineCheckbox}
                />
                <LineLabel
                  $color={f.color}
                  $bstyle={f?.bstyle ? f.bstyle : "solid"}
                  $theme={theme}
                >
                  {f.label}
                </LineLabel>
              </LineFilterItem>
            );
          })}
        </LineFilterContainer>
      </Row>

      <Row>
        <RowHeading className="heading">HRV</RowHeading>
        <ChartPlaceholder>
          <LineChart
            width={1200}
            height={200}
            data={hrvPoints}
            connectNulls={true}
            margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
          >
            <XAxis
              dataKey={"slot"}
              tickFormatter={(a) => {
                return moment(a).format("DD.MM");
              }}
              {...tickProps}
            />
            <Tooltip {...tooltipWrapper} content={<MetricsTooltip />} />
            <YAxis {...tickProps} />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <Line
              type="monotone"
              dataKey={"rmssd"}
              stroke={LINE_COLORS.raw}
              strokeWidth={1}
              animationDuration={1}
              dot={false}
              connectNulls={false}
            />
          </LineChart>
        </ChartPlaceholder>
      </Row>

      <Row>
        <RowHeading className="heading">SPO2</RowHeading>
        <ChartPlaceholder>
          <LineChart
            width={1200}
            height={200}
            data={activityPoints}
            connectNulls={true}
            margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
          >
            <XAxis
              dataKey={"slot"}
              tickFormatter={(a) => {
                return moment(a).format("DD.MM");
              }}
              {...tickProps}
            />
            <Tooltip {...tooltipWrapper} content={<MetricsTooltip />} />
            <YAxis {...tickProps} domain={[70, 100]} />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            {/* <Legend /> */}
            <Line
              type="monotone"
              dataKey={"spo2"}
              name={"SpO2"}
              stroke={LINE_COLORS.raw}
              strokeWidth={1}
              animationDuration={1}
              // dot={{ stroke: "#D92A3E", strokeWidth: 1 }}
              dot={false}
              connectNulls={true}
            />
          </LineChart>
        </ChartPlaceholder>
        <LineFilterContainer>
          {LINES.map((f, i) => {
            if (!f.value.includes("spo2")) {
              return null;
            }
            return (
              <LineFilterItem key={i}>
                {/* <LineFilterCheckbox
                  type="checkbox"
                  name={f.value}
                  checked={showLine[f.value]}
                  onChange={handleLineCheckbox}
                /> */}
                <LineLabel
                  $color={f.color}
                  $bstyle={f?.bstyle ? f.bstyle : "solid"}
                  $theme={theme}
                >
                  {f.label}
                </LineLabel>
              </LineFilterItem>
            );
          })}
        </LineFilterContainer>
      </Row>

      <Row>
        <RowHeading className="heading">Respiration Rate</RowHeading>
        <ChartPlaceholder>
          <LineChart
            width={1200}
            height={200}
            data={activityPoints}
            connectNulls={true}
            margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
          >
            <XAxis
              dataKey={"slot"}
              tickFormatter={(a) => {
                return moment(a).format("DD.MM");
              }}
              {...tickProps}
            />
            <Tooltip {...tooltipWrapper} content={<MetricsTooltip />} />
            <YAxis {...tickProps} />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            {/* <Legend /> */}
            <Line
              type="monotone"
              dataKey={"respiration_rate"}
              name={"RR"}
              stroke={LINE_COLORS.raw}
              strokeWidth={1}
              animationDuration={1}
              dot={false}
              connectNulls={false}
              hide={!showLine["rrRaw"]}
            />
            <Line
              type="monotone"
              dataKey={"respiration_rate_moving_average"}
              name={"RR-ma"}
              stroke={LINE_COLORS.baseline}
              strokeWidth={2}
              animationDuration={1}
              dot={false}
              connectNulls={true}
              hide={!showLine["rrMa"]}
            />
          </LineChart>
        </ChartPlaceholder>
        <LineFilterContainer>
          {LINES.map((f, i) => {
            if (!f.value.includes("rr")) {
              return null;
            }
            return (
              <LineFilterItem key={i}>
                <LineFilterCheckbox
                  type="checkbox"
                  name={f.value}
                  checked={showLine[f.value]}
                  onChange={handleLineCheckbox}
                />
                <LineLabel
                  $color={f.color}
                  $bstyle={f?.bstyle ? f.bstyle : "solid"}
                  $theme={theme}
                >
                  {f.label}
                </LineLabel>
              </LineFilterItem>
            );
          })}
        </LineFilterContainer>
      </Row>

      <Row>
        <RowHeading className="heading">Blood Pressure</RowHeading>
        <ChartPlaceholder>
          <LineChart
            width={1200}
            height={200}
            // data={activityPoints}
            data={bpData}
            connectNulls={true}
            margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
          >
            <XAxis
              dataKey={"start_timestamp"}
              tickFormatter={(a) => {
                return moment(a).format("DD.MM");
              }}
              {...tickProps}
            />
            <Tooltip {...tooltipWrapper} content={<BPMetricsTooltip />} />
            <YAxis domain={bpRange} {...tickProps} />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            {/* <Legend /> */}
            <Line
              type="monotone"
              name={"Systolic BP"}
              dataKey={"sbp_ai"}
              stroke={"#3333FF"}
              strokeWidth={2}
              animationDuration={1}
              dot={false}
              connectNulls={false}
            />
            <Line
              type="monotone"
              dataKey={"dbp_ai"}
              name={"Diastolic BP"}
              stroke={"#FF4E45"}
              strokeWidth={2}
              animationDuration={1}
              dot={false}
              connectNulls={false}
            />
          </LineChart>
        </ChartPlaceholder>
        <LineFilterContainer>
          {LINES.map((f, i) => {
            if (!f.value.includes("blood_pressure")) {
              return null;
            }
            return (
              <LineFilterItem key={i}>
                <LineLabel
                  $color={f.color}
                  $bstyle={f?.bstyle ? f.bstyle : "solid"}
                  $theme={theme}
                >
                  {f.label}
                </LineLabel>
              </LineFilterItem>
            );
          })}
        </LineFilterContainer>
      </Row>
      <Row>
        <RowHeading className="heading">
          CBT {unit === "C" ? "(°C)" : "(F)"}
        </RowHeading>
        <ChartPlaceholder>
          <LineChart
            width={1200}
            height={200}
            data={temperaturePoints}
            connectNulls={true}
            margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
          >
            <XAxis
              dataKey="slot"
              tickFormatter={(a) => {
                return moment(a).format("DD.MM");
              }}
              {...tickProps}
            />
            <Tooltip {...tooltipWrapper} content={<MetricsTooltip />} />
            <YAxis
              {...tickProps}
              domain={[35, 42]}
              tickFormatter={(x) =>
                unit === "F" ? (+x * 1.8 + 32.0).toFixed(1) : x
              }
            />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            {/* <Legend verticalAlign="bottom" /> */}
            <Line
              type="monotone"
              dataKey={"temp_sk1"}
              name={"Temperature"}
              stroke={LINE_COLORS.raw}
              strokeWidth={2}
              animationDuration={1}
              dot={false}
              connectNulls={false}
            />
          </LineChart>
        </ChartPlaceholder>
        <LineFilterContainer>
          {LINES.map((f, i) => {
            if (!f.value.includes("tempe")) {
              return null;
            }
            return (
              <LineFilterItem key={i}>
                {/* <LineFilterCheckbox
                  type="checkbox"
                  name={f.value}
                  checked={showLine[f.value]}
                  onChange={handleLineCheckbox}
                /> */}
                <LineLabel
                  $color={f?.color}
                  $bstyle={f?.bstyle ? f.bstyle : "solid"}
                  $theme={theme}
                >
                  {f.label}
                </LineLabel>
              </LineFilterItem>
            );
          })}
        </LineFilterContainer>
      </Row>

      <Row>
        <RowHeading className="heading">Steps</RowHeading>
        <ChartPlaceholder>
          <LineChart
            width={1200}
            height={200}
            data={activityPoints}
            connectNulls={true}
            margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
          >
            <XAxis
              dataKey={"slot"}
              tickFormatter={(a) => {
                return moment(a).format("DD.MM");
              }}
              {...tickProps}
            />
            <Tooltip {...tooltipWrapper} content={<MetricsTooltip />} />
            <YAxis {...tickProps} />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <Line
              type="monotone"
              dataKey={"last_steps"}
              stroke={LINE_COLORS.raw}
              strokeWidth={2}
              animationDuration={1}
              dot={false}
              connectNulls={false}
            />
          </LineChart>
        </ChartPlaceholder>
      </Row>
    </Wrapper>
  );
}

const ScoreTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <div className="label">{`${moment(label).format("MM-DD HH:mm")}`}</div>
        <strong className="label">{`Cor-Risk : ${payload[0].value}`}</strong>
        <div className="label">{`Respiration Rate Score : ${payload[0]?.payload.rr_score}`}</div>
        <div className="label">{`SpO2 Score : ${payload[0]?.payload.spo2_score}`}</div>
        <div className="label">{`Pulse Rate Score : ${payload[0]?.payload.bpm_score}`}</div>
        <div className="label">{`Temperature Score : ${payload[0]?.payload.temp_score}`}</div>
      </div>
    );
  }

  return null;
};

const toFixedMap = {
  BPM: 0,
  SpO2: 0,
  blood_pressure: 0,
  RR: 1,
  rmssd: 1,
  Temperature: 1,
  last_steps: 0,
};

const MetricsTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    let name0 = payload ? payload[0]?.name : "";
    let showBaseline;
    let name1 = "Baseline";
    let toFixedLength = toFixedMap[name0];
    // console.log("name0", name0, toFixedLength);
    if (payload.length > 1 && payload[1].name.includes("ma")) {
      showBaseline = true;
      // let name1 = payload[1].name;
    } else {
      showBaseline = false;
    }
    return (
      <div className="custom-tooltip">
        <div className="label">{`${moment(label).format("MM-DD HH:mm")}`}</div>
        <div className="label">{`${name0} : ${payload[0].value.toFixed(
          toFixedLength
        )}`}</div>
        {showBaseline ? (
          <div className="label">{`${name1} : ${payload[1].value.toFixed(
            1
          )}`}</div>
        ) : null}
      </div>
    );
  }

  return null;
};

const BPMetricsTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <div className="label">{`${moment(label).format("MM-DD HH:mm")}`}</div>
        <div className="label">{`${
          payload[0].name
        } : ${payload[0].value.toFixed(0)}`}</div>
        <div className="label">{`${
          payload[1].name
        } : ${payload[1].value.toFixed(0)}`}</div>
      </div>
    );
  }
  return null;
};
const Row = styled.div`
  margin-bottom: 10px;
`;

const RowHeading = styled.div``;

const ChartPlaceholder = styled.div`
  width: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
`;
