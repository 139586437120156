import SimpleNoDataMessageTool from "../../../sleep/tools/SimpleNoDataMessageTool";
import { ComplianceHeading, TabHeading } from "../UiComponents";
import { isDebugMode } from "../../../../helpers/UiHelper";
import SabirBarChart from "../../../activity/tools/SabirBarChart";
import ContinuousMeasurementsPanel from "../ContinuousMeasurementsPanel";
import TempStepsTool from "../../tools/TempStepsTool";
import TarnsformerHelper from "../../../../helpers/TarnsformerHelper";
import InfoToolBadge from "../../../info/tools/InfoToolBadge";
import SummaryActivityGaitViewTool from "../../../gait/tools/SummaryActivityGaitViewTool";
import ActivityTrendsChartTool from "../../../trends/tools/ActivityTrendsChartTool";
import moment from "moment";

export default function ActivityPanel(props) {
  const {
    id,
    date,
    calorieByHour,
    caloriesToday,
    stepsByHalfHour,
    summary,
    summaryLoading,
    hasNoActivityData,
  } = props;
  return (
    <div className="activity-tab-container">
      {hasNoActivityData ? (
        <SimpleNoDataMessageTool
          loading={summaryLoading}
          message={"Sorry, there is no activity data for this day."}
          hasData={!hasNoActivityData}
        />
      ) : (
        <div>
          <div
            className="steps-area"
            style={{ width: "100%", height: "330px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <ComplianceHeading className="patient-statistics-heading">
                Steps
              </ComplianceHeading>
              <InfoToolBadge type={"ACTIVITY"} />
            </div>
            <SabirBarChart
              shouldChangeColorOfSelected={false}
              barColor={"#FF962A"}
            //   items={TarnsformerHelper.get24HoursStepsArray( summary.activity.slots )}
            //   type="steps"
              items={stepsByHalfHour}
              type="steps30"
              renderItem={(it) => (
                <span
                  style={{
                    fontWeight: "normal",
                    color: "grey",
                    fontSize: 10,
                    opacity: "50%",
                  }}
                >
                  {it.time}
                </span>
              )}
            />
          </div>

          {/* {isDebugMode() === false ? null : (
            <div
              className="steps-15min-area"
              style={{ width: "100%", height: "230px", marginTop: 80 }}
            >
              <TabHeading className="patient-statistics-heading">
                Steps
              </TabHeading>
              <SabirBarChart
                shouldChangeColorOfSelected={false}
                barColor={"#FF962A"}
                items={calorieByHour.map((x) => {
                  return { ...x, value: +(x.value / 1).toFixed(1) };
                })}
                type="steps_"
                renderItem={(it) => (
                  <span
                    style={{
                      fontWeight: "normal",
                      color: "grey",
                      fontSize: 10,
                      opacity: "50%",
                    }}
                  >
                    {it.time}
                  </span>
                )}
                caloriesToday={caloriesToday}
              />
            </div>
          )} */}
          <div
            className="energy-expenditure-area"
            style={{ width: "100%", height: "330px", marginTop: 80 }}
          >
            <TabHeading className="patient-statistics-heading">
              Energy Expenditure
            </TabHeading>
            <SabirBarChart
              shouldChangeColorOfSelected={false}
              barColor={"#FF962A"}
              items={calorieByHour.map((x) => {
                return { ...x, value: +(x.value / 1).toFixed(1) };
              })}
              type="energy_expenditure"
              renderItem={(it) => (
                <span
                  style={{
                    fontWeight: "normal",
                    color: "grey",
                    fontSize: 10,
                    opacity: "50%",
                  }}
                >
                  {it.time}
                </span>
              )}
              caloriesToday={caloriesToday}
            />
          </div>

          {isDebugMode() === false ? null : (
            <div className="activity-temp-area" style={{ marginTop: 80 }}>
              <TabHeading className="patient-statistics-heading">
                Activity Temp
              </TabHeading>

              <TempStepsTool
                uuid={id}
                from={+moment(date).startOf("day")}
                to={+moment(date).endOf("day")}
              />
            </div>
          )}

          {summary?.activity === undefined ? null : (
            <div className="activity-gait-area" style={{ marginTop: 80 }}>
              <TabHeading className="patient-statistics-heading">
                Gait
              </TabHeading>
              <SummaryActivityGaitViewTool {...(summary?.activity || {})} />
            </div>
          )}

          <div
            className="activity-trends-area"
            style={{ width: "100%", height: 400, marginTop: 50 }}
          >
            <TabHeading className="patient-statistics-heading">
              Trends
            </TabHeading>
            <ActivityTrendsChartTool uuid={id} />
          </div>
        </div>
      )}

      {isDebugMode() === false &&
      window.location.href.indexOf("wiff") === -1 ? null : (
        <ContinuousMeasurementsPanel
          type={"acc"}
          userId={id}
          from={+moment(date).startOf("day")}
          to={+moment(date).endOf("day")}
        />
      )}
    </div>
  );
}
