import PatientDayBasicBpTool from "../../../bp/PatientDayBasicBpTool";
import PatientDayBasicBpCombineTool from "../../../bp/PatientDayBasicBpCombineTool";
import DayBpAnalysisPanel from "../../../bp/panels/DayBpAnalysisPanel";
import { TabHeading } from "../UiComponents";
import moment from "moment";

export default function NibpPanel(props) {
  const {
    id,
    date,
    seltT,
    bpTimeMode,
    toggleBpMode,
    cloudBpPoints,
    cuffInit,
    activityPoints,
    activityPointsLoading,
    freshestData,
    freshestNotification,
    memoizedBp,
    showCloud,
    isDebugMode,
  } = props;
  return (
    <div>
      {isDebugMode() ? (
        // <PatientDayBpTool
        //   dayTimestamp={+moment(date).startOf("day")}
        //   uuid={id}
        //   min={+moment(date).startOf("day")}
        //   max={+moment(date).startOf("day").add(1, "day").valueOf()}
        //   cuffInit={cuffInit}
        //   activityPoints={activityPoints}
        //   activityPointsLoading={activityPointsLoading}
        // />
        memoizedBp
      ) : showCloud ? (
        <PatientDayBasicBpTool
          uuid={id}
          dayTimestamp={+moment(date).startOf("day")}
          min={
            bpTimeMode === "day"
              ? +moment(date).startOf("day")
              : +moment(date).startOf("day").subtract(12, "hours")
          }
          max={
            bpTimeMode === "day"
              ? +moment(date).startOf("day").add(1, "day").valueOf()
              : +moment(date).startOf("day").add(1, "day").subtract(12, "hours")
          }
          cloudBpPoints={cloudBpPoints}
          mode={bpTimeMode}
          toggleBpMode={toggleBpMode}
        />
      ) : (
        <PatientDayBasicBpCombineTool
          dayTimestamp={+moment(date).startOf("day")}
          uuid={id}
          min={+moment(date).startOf("day")}
          max={+moment(date).startOf("day").add(1, "day").valueOf()}
          cuffInit={cuffInit}
          activityPoints={activityPoints}
          cloudBpPoints={cloudBpPoints}
          activityPointsLoading={activityPointsLoading}
          mode={bpTimeMode}
          toggleBpMode={toggleBpMode}
          freshestData={freshestData}
          freshestNotification={freshestNotification}
        />
      )}

      <TabHeading className="patient-statistics-heading">
        Spot Blood Pressure Cuff Measurement
      </TabHeading>

      <DayBpAnalysisPanel
        dayTimestamp={seltT}
        uuid={id}
        inits={cuffInit}
        isDebugMode={isDebugMode}
      />
    </div>
  );
}
