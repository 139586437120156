import axios from "axios";
import moment from "moment";
import env from "react-dotenv";
import * as ls from "local-storage";
import CommonHelper from "../helpers/CommonHelper";
import { DEMO_FAKE_USERS } from "../constants/config";

const { DOCTORS_API_ENDPOINT = "https://api.study-integration.corsano.com" } =
  env;

const DoctorAPI = {
  async getNewestVersions() {
    try {
      let pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/latest-versions`)).data;
      return pld;
    } catch (exc) {}
  },

  async getPatientRriSleep(uuid, from, to) {
    const _endpoint = `${DOCTORS_API_ENDPOINT}/v2/ds/users/${uuid}/sleep-measurements-continuous/sleep-data?timestamp_from=${from}&timestamp_to=${to}`;
    let d = (await axios.get(_endpoint)).data;
    // d = d.map((x) => ({ t: x.timestamp, rr: x.rr, value: x.rr }));
    return d;
  },

  async getPatientNoonToNoonSleep(uuid, dayTimestamp) {
    const date_from = new Date(
      +moment(dayTimestamp).startOf("day").subtract(12, "hours")
    ).toJSON();
    const date_to = new Date(
      +moment(dayTimestamp).startOf("day").add(12, "hours")
    ).toJSON();
    // const type = "raw_sleeps";
    // console.log(date_from, date_to);

    const type = "raw_corsano_sleep";
    let d = (
      await axios.get(`${DOCTORS_API_ENDPOINT}/patients/${uuid}/raw-metrics`, {
        params: {
          date_to: date_to,
          date_from: date_from,
          only_metrics: [type],
        },
      })
    ).data.result[type];
    // d = d.map((x) => ({ t: x.timestamp, rr: x.rr, value: x.rr }));
    return d;
  },

  async getPatientDaySleep(uuid, dayTimestamp) {
    const date_from = new Date(+moment(dayTimestamp).startOf("day")).toJSON();
    const date_to = new Date(
      +moment(dayTimestamp).startOf("day").add(24, "hours")
    ).toJSON();
    // const type = "raw_sleeps";
    // console.log(date_from, date_to);

    const type = "raw_corsano_sleep";
    let d = (
      await axios.get(`${DOCTORS_API_ENDPOINT}/patients/${uuid}/raw-metrics`, {
        params: {
          date_to: date_to,
          date_from: date_from,
          only_metrics: [type],
        },
      })
    ).data.result[type];
    // d = d.map((x) => ({ t: x.timestamp, rr: x.rr, value: x.rr }));
    return d;
  },
  async getUserBaselineAllType(uuid, from, to) {
    const url = `${DOCTORS_API_ENDPOINT}/v2/adaptive/users/${uuid}/${"all"}/points?from=${from}&to=${to}`;
    // console.log(url);
    try {
      const pld = await axios.get(url);
      return pld.data;
    } catch (exc) {
      return exc;
    }
  },

  async getUserBaselineByType(uuid, type, from, to) {
    const url = `${DOCTORS_API_ENDPOINT}/adaptive/users/${uuid}/${type}/points?from=${from}&to=${to}`;
    // console.log(url);
    try {
      const pld = await axios.get(url);
      return pld.data;
    } catch (exc) {
      return exc;
    }
  },

  async postUserConsciousnessLevel(uuid, body) {
    try {
      const pld = await axios.post(
        `${DOCTORS_API_ENDPOINT}/v2/user/${uuid}/external_device_spot_measurement_consciousness_level`,
        {
          date: body?.date,
          consciousness_level: body?.consciousness_level,
        }
      );
      return pld;
    } catch (exc) {
      return exc;
    }
  },

  async postUserSupplementalOxygen(uuid, body) {
    try {
      const pld = await axios.post(
        `${DOCTORS_API_ENDPOINT}/v2/user/${uuid}/external_device_spot_measurement_supplemental_oxygen`,
        {
          supplemental_oxygen: body?.supplemental_oxygen,
          date: body?.date,
        }
      );
      return pld;
    } catch (exc) {
      return exc;
    }
  },

  async getUserRoleStatus() {
    try {
      const pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/role-settings`))
        .data;
      return pld;
    } catch (exc) {
      return exc;
    }
    return {};
  },

  async getUserRoleStatus1() {
    try {
      const pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/app/role-settings`))
        .data;
      return pld;
    } catch (exc) {
      return exc;
    }
    return {};
  },

  async getHcpAdmin() {
    try {
      const pld = (await axios.get(`${DOCTORS_API_ENDPOINT}//my-hcp-admin`))
        .data;

      return pld;
    } catch (exc) {
      return exc;
    }
  },

  async getUserCompliance(uuid, date_from, date_to) {
    try {
      const pld = (
        await axios.get(
          `${DOCTORS_API_ENDPOINT}/users/${uuid}/compliance-map`,
          {
            params: {
              date_from: date_from,
              date_to: date_to,
            },
          }
        )
      ).data;
      return pld;
    } catch (exc) {
      return exc;
    }
  },

  isVaaData() {
    if (window.currentUserObject != undefined) {
      const em = window.currentUserObject?.usersUser?.email;
      if (em != undefined && em.toLowerCase().indexOf("vaadata") > -1) {
        return true;
      }
    }
    return false;
  },

  getTokensGetParamFromLs() {
    return {
      users_token: ls.get("users_cloud_token"),
      health_token:
        window.location.href.indexOf("embed") > -1
          ? gup("health_token")
          : ls.get("health_cloud_token"),
    };
  },

  logout() {
    ls.clear();
    CommonHelper.linkTo("/");
    window.location.reload();
  },

  async getPatientBracelet(patientUUID) {
    const pld = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/bracelets/patients/${patientUUID}/bracelet`
      )
    ).data;
    return pld;
  },

  async getEmbedMe() {
    const pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/embed/me`)).data;
    return pld;
  },

  async getSubscription() {
    try {
      const pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/subscription`))
        .data;
      return pld;
    } catch (exc) {}
  },

  async getPatientsRawMetricsMap(tag) {
    // let tag = (window.STUDY_TAG == undefined) ? 'test1' : window.STUDY_TAG;
    const pld = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/patients/tag/${tag}/latest-raw-metrics`
      )
    ).data.result;
    return pld;
  },

  async getPatientsRawMetricsMapByUsersList(users) {
    // let tag = (window.STUDY_TAG == undefined) ? 'test1' : window.STUDY_TAG;
    const pld = (
      await axios.post(`${DOCTORS_API_ENDPOINT}/patients/latest-raw-metrics`, {
        users: users,
      })
    ).data.result;
    return pld;
  },

  async getPatientsSummariesMapByUsersList(users, isDemo = false) {
    if (isDemo == true) {
      return DEMO_FAKE_USERS;
    }
    // let tag = (window.STUDY_TAG == undefined) ? 'test1' : window.STUDY_TAG;
    const pld = (
      await axios.post(
        `${DOCTORS_API_ENDPOINT}/dashboard/users/latest-parameters-map`,
        {
          users_uuids: users.map((x) => x.uuid),
        }
      )
    ).data.result;
    return pld;
  },

  async getUsersInterfaceSettings(users) {
    const pld = (
      await axios.post(
        `${DOCTORS_API_ENDPOINT}/users/many-interface-settings`,
        {
          uuids: users.map((x) => x.uuid),
        }
      )
    ).data;
    return pld;
  },

  async getDashboardSettings() {
    const pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/dashboard/settings`))
      .data.result;
    return pld;
  },

  async getPatients() {
    const tag = window.STUDY_TAG == undefined ? "test1" : window.STUDY_TAG;
    const dd = { tag: tag };
    const pld = (
      await axios.get(`${DOCTORS_API_ENDPOINT}/patients`, {
        params: dd,
      })
    ).data.result;
    return pld;
  },

  async unlinkPatientFromGroup(uuid) {
    // let tag = (window.STUDY_TAG == undefined) ? 'test1' : window.STUDY_TAG;
    const dd = { ...this.getTokensGetParamFromLs() };
    const pld = (
      await axios.put(`${DOCTORS_API_ENDPOINT}/patients/${uuid}/unlink`, {
        ...dd,
      })
    ).data;
    return pld;
  },

  //todo: implement
  async hidePatientFromGroup(uuid) {
    // let tag = (window.STUDY_TAG == undefined) ? 'test1' : window.STUDY_TAG;
    // let dd = {...this.getTokensGetParamFromLs()};
    // let pld = (
    //     await axios.put(`${DOCTORS_API_ENDPOINT}/patients/${uuid}/unlink`, {
    //         ...dd,
    //     })
    // ).data;
    // return pld;
  },

  //todo: implement
  async unhidePatientFromGroup(uuid) {
    // let tag = (window.STUDY_TAG == undefined) ? 'test1' : window.STUDY_TAG;
    // let dd = {...this.getTokensGetParamFromLs()};
    // let pld = (
    //     await axios.put(`${DOCTORS_API_ENDPOINT}/patients/${uuid}/unlink`, {
    //         ...dd,
    //     })
    // ).data;
    // return pld;
  },

  async getPatientsByTag(tag) {
    // let tag = (window.STUDY_TAG == undefined) ? 'test1' : window.STUDY_TAG;
    const dd = { tag: tag };
    const pld = (
      await axios.get(`${DOCTORS_API_ENDPOINT}/v2/users/by-tag/${tag}`, {
        params: dd,
      })
    ).data.result;

    return pld;
  },

  async getPatientsMetricsByTag(tag) {
    const pld = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/users/by-tag/${tag}/latest-values`
      )
    ).data;
    return pld;
  },

  async getPatientLatestRawValues(uuid) {
    const pld = (
      await axios.get(`${DOCTORS_API_ENDPOINT}/users/${uuid}/latest-raw-values`)
    ).data;
    return pld;
  },

  async getPatient(uuid) {
    if (uuid.indexOf("demo") > -1) {
      const u = DEMO_FAKE_USERS.filter((xx) => xx.uuid == uuid)[0];
      return u;
    }
    const pld = (
      await axios.get(`${DOCTORS_API_ENDPOINT}/patients/${uuid}/profile`)
    ).data.result;
    return pld;
  },

  async getPatientDeviceAndAppInfo(uuid) {
    const pld = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/a/users/${uuid}/app-and-bracelet-info`
      )
    ).data;
    return pld;
  },

  async getPatientDevice(uuid) {
    if (uuid.indexOf("demo") > -1) {
      return {
        _id: "60b483a16112db3099509a96",
        user_id: "60b479bc6112db30991cfae4",
        created_at: "2021-05-31 06:35:13",
        updated_at: "2021-06-18 10:53:06",
        wearables: {
          "0102028A": {
            device_type: "287B1",
            serial_number: "0102028A",
            firmware_version: "3.61",
            battery_level: 24,
            updated_at: "2021-06-18 10:53:06",
          },
        },
      };
    }
    try {
      const pld = (
        await axios.get(`${DOCTORS_API_ENDPOINT}/patients/${uuid}/device`)
      ).data.result;
      return pld;
    } catch (exc) {
      return exc;
    }
  },

  async getUserDaySummary(uuid, date, isDemo) {
    const dd = { ...this.getTokensGetParamFromLs() };
    if (isDemo == true) {
      dd.demo = "yes";
    }
    const pld = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/patients/${uuid}/summary/${date}`
      )
    ).data.result;
    return pld;
  },

  async getUserDayWorkouts(uuid, date, isDemo) {
    const dd = { ...this.getTokensGetParamFromLs() };
    if (isDemo == true) {
      dd.demo = "yes";
    }
    const pld = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/patients/${uuid}/workouts/${date}`
      )
    ).data;
    return pld;
  },

  async getUserLightSummariesInRange(uuid, date_from, date_to, isDemo) {
    const dd = { ...this.getTokensGetParamFromLs() };
    if (isDemo == true) {
      dd.demo = "yes";
    }
    const pld = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/users/${uuid}/summaries-in-range/${date_from}/${date_to}`
      )
    ).data;
    return pld;
  },

  async getUserWeekSummaries(uuid, end, isDemo) {
    const dd = { ...this.getTokensGetParamFromLs() };
    if (isDemo == true) {
      dd.demo = "yes";
    }
    const from = +end - 6 * 86400000;
    const arr = Array.from({ length: 7 })
      .map((a, i) => i * 86400000 + +from)
      .map((x) => moment(x).format("YYYY-MM-DD"));
    const self = this;
    const promisses = arr.map((x) => self.getUserDaySummary(uuid, x, isDemo));
    const results = await Promise.all(promisses);
    return results;
  },

  async getUserWeekRecoveries(uuid, end, isDemo) {
    const items = await this.getUserWeekSummaries(uuid, end, isDemo);
    console.log("getUserWeekRecoveries: got items = ", items);
    return items.map((x) => {
      if (x == undefined || x.recovery == undefined) {
        return undefined;
      }
      return {
        date: x.recovery.date,
        t: +moment(x.recovery.date),
        recovery: x.recovery.value,
      };
    });
  },

  async getPatientComplianceItems(uuid, monthTimestamp) {
    if (uuid.indexOf("demo") > -1) {
      const from = moment(monthTimestamp).startOf("month").startOf("day");
      let arr = Array.from({ length: 31 }).map((a, i) => ({
        date: moment(i * 86400000 + +from).format("YYYY-MM-DD"),
        measurements_count: Math.floor(
          25 * 60 * 60 * 24 * (0.9 + Math.random() * 0.1)
        ),
      }));
      const randIndexes = [
        Math.floor(Math.random() * 10),
        Math.floor(Math.random() * 20),
        Math.floor(Math.random() * 30),
      ];
      arr = arr.map((a, i) =>
        randIndexes.indexOf(i) > -1
          ? {
              ...a,
              measurements_count: Math.floor(
                (0.3 + 0.7 * Math.random()) * a.measurements_count
              ),
            }
          : a
      );

      return arr;
    }
    const pld = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/patients/${uuid}/ppg-month-stats/${monthTimestamp}`
      )
    ).data.result;
    return pld;
  },

  async getPatientComplianceSlotStats(uuid, slotId) {
    if (uuid.indexOf("demo") > -1) {
      const arr = [];
      for (let i = 0; i < 24; i++) {
        const hrs = `${i}`.padStart(2, "0");
        for (let j = 0; j < 12; j++) {
          const mins = `${5 * j}`.padStart(2, "0");
          arr.push({
            time: `${hrs}:${mins}`,
            measurements_count: Math.floor(
              (Math.random() * 0.5 + 0.5) * 5 * 60 * 25
            ),
          });
        }
      }
      return arr;
    }
    const pld = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/patients/${uuid}/ppg-slot-stats/${slotId}`
      )
    ).data.result;
    return pld;
  },

  async getBattery(uuid) {
    console.log("getBattery occured! uuid = ", uuid);
    try {
      const pld = (
        await axios.get(
          `${DOCTORS_API_ENDPOINT}/patients/${uuid}/latest-battery`
        )
      ).data;
      return pld.battery;
    } catch (exc) {}
  },

  async getMonthSummariesWithSlots(uuid, t) {
    const pld = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/users/${uuid}/month-summaries/${t}`
      )
    ).data;
    return pld;
  },

  async getRawActivitiesInRange(uuid, from, to) {
    const pld = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/export/user/${uuid}/activity-json/from/${from}/to/${to}`
      )
    ).data;
    return pld;
  },

  async getPatientsDevicesMap(uuid, slotId) {
    const pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/devices-map`)).data
      .result;
    return pld;
  },

  async updateUsersProfile(uuid, d) {
    const dd = {};
    const pld = (
      await axios.put(
        `${DOCTORS_API_ENDPOINT}/patients/${uuid}/users-profile`,
        {
          ...d,
        },
        {
          params: dd,
        }
      )
    ).data.result;
    return pld;
  },

  async joinGroup(uuid, code) {
    const pld = (
      await axios.post(`${DOCTORS_API_ENDPOINT}/join/group/${code}/${uuid}`)
    ).data;
    return pld;
  },

  async updateHealthProfile(uuid, d) {
    console.log("updateHealthProfile: uuid, d = ", uuid, d);
    const pld = (
      await axios.put(
        `${DOCTORS_API_ENDPOINT}/patients/${uuid}/health-profile`,
        {
          ...d,
        }
      )
    ).data.result;
    return pld;
  },

  async getPatientsRawRRIntervalsForDay(uuid, dayTimestamp) {
    const date_from = new Date(+moment(dayTimestamp).startOf("day")).toJSON();
    const date_to = new Date(+moment(dayTimestamp).endOf("day")).toJSON();
    let d = (
      await axios.get(`${DOCTORS_API_ENDPOINT}/patients/${uuid}/raw-metrics`, {
        params: {
          date_to: date_to,
          date_from: date_from,
          only_metrics: ["raw_rr_intervals"],
        },
      })
    ).data.result["raw_rr_intervals"];
    d = d.map((x) => ({ t: x.timestamp, rr: x.rr, value: x.rr }));
    return d;
  },

  async getActivitiesDataPointsForDay(uuid, dayTimestamp) {
    const date_from = new Date(+moment(dayTimestamp).startOf("day")).toJSON();
    const date_to = new Date(+moment(dayTimestamp).endOf("day")).toJSON();
    const d = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/ds/patients/${uuid}/raw-activities-with-temp`,
        {
          params: {
            date_to: date_to,
            date_from: date_from,
          },
        }
      )
    ).data;
    return d;
  },

  async getPatientsRawBPSpotsForDay(uuid, dayTimestamp) {
    const dd = { ...this.getTokensGetParamFromLs() };
    const date_from = new Date(+moment(dayTimestamp).startOf("day")).toJSON();
    const date_to = new Date(+moment(dayTimestamp).endOf("day")).toJSON();
    const d = (
      await axios.get(`${DOCTORS_API_ENDPOINT}/patients/${uuid}/raw-metrics`, {
        params: {
          ...dd,
          date_to: date_to,
          date_from: date_from,
          only_metrics: ["raw_blood_pressure_spot"],
        },
      })
    ).data.result["raw_blood_pressure_spot"];
    // d = d.map((x) => ({t: x.timestamp, rr: x.rr, value: x.rr}));
    return d;
  },

  async getHappitechMeasurements(uuid, from, to) {
    // let from = +moment(dayTimestamp).startOf('day');
    // let to = +moment(dayTimestamp).endOf('day');
    const d = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/v2/user/${uuid}/happitech-measurements`,
        {
          params: {
            from: from,
            to: to,
          },
        }
      )
    ).data;
    return d;
  },

  async getAllEcgMeasurements(uuid, from, to) {
    const d = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/ds/user/${uuid}/ecg-measurements`
      )
    ).data;
    return d;
  },

  async getTempActivityPoints(uuid, from, to) {
    const d = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/v2/ds/user/${uuid}/temp-activity-points`,
        {
          params: {
            from: +from,
            to: +to,
          },
        }
      )
    ).data;
    return d;
  },

  async getPatientsRawBPSpotsEnhancedFromZero(uuid, dayTimestamp) {
    const dd = { ...this.getTokensGetParamFromLs() };
    const date_from = 0;
    const date_to = new Date(+moment(dayTimestamp).endOf("day")).toJSON();
    const d = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/ds/user/${uuid}/blood-pressure-spots`,
        {
          params: {
            date_to: date_to,
            date_from: date_from,
          },
        }
      )
    ).data;
    return d;
  },

  async getPatientsRawBPSpotsEnhancedForDay(uuid, dayTimestamp) {
    const dd = { ...this.getTokensGetParamFromLs() };
    const date_from = new Date(+moment(dayTimestamp).startOf("day")).toJSON();
    const date_to = new Date(+moment(dayTimestamp).endOf("day")).toJSON();
    const d = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/ds/user/${uuid}/blood-pressure-spots`,
        {
          params: {
            // ...dd,
            date_to: date_to,
            date_from: date_from,
          },
        }
      )
    ).data;
    // d = d.map((x) => ({t: x.timestamp, rr: x.rr, value: x.rr}));
    return d;
  },

  async getPatientsLatestRawMetrics(uuid) {
    const dd = { ...this.getTokensGetParamFromLs() };
    const d = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/patients/${uuid}/raw-metrics/latest`,
        {
          params: {
            ...dd,
          },
        }
      )
    ).data;
    return d;
  },

  async getEmographyData(uuid, from, to) {
    const dd = { ...this.getTokensGetParamFromLs() };
    const d = (
      await axios.get(`${DOCTORS_API_ENDPOINT}/users/${uuid}/emography`, {
        params: {
          from: from,
          to: to,
        },
      })
    ).data.result;
    return d;
  },

  async getSpiroMeasurements(uuid) {
    const d = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/ds/user/${uuid}/spirometer-measurements`,
        {}
      )
    ).data;
    return d;
  },

  async getWeightMeasurements(uuid) {
    const d = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/ds/user/${uuid}/weight-measurements`,
        {}
      )
    ).data;
    return d;
  },

  async getAISpo2Data(uuid, from, to) {
    const d = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/ds/user/${uuid}/spo2-measurements`,
        {
          params: {
            start_timestamp_from: from,
            start_timestamp_to: to,
          },
        }
      )
    ).data;
    return d;
  },

  async getAIRespirationData(uuid, from, to) {
    const d = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/ds/user/${uuid}/respiration-rate-measurements`,
        {
          params: {
            start_timestamp_from: from,
            start_timestamp_to: to,
          },
        }
      )
    ).data;
    return d;
  },

  async getFreshestAIBpData(uuid, n) {
    const d = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/v2/ds/user/${uuid}/blood-pressure-measurements/latest/${n}`
      )
    ).data;
    return d;
  },
  async getAIBpData(uuid, from, to) {
    const d = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/v2/ds/user/${uuid}/blood-pressure-measurements`,
        {
          params: {
            start_timestamp_from: from,
            start_timestamp_to: to,
          },
        }
      )
    ).data;
    return d;
  },

  async getAIBpInitDataMeasurements(uuid) {
    const d = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/v2/ds/user/${uuid}/init-pressure-measurements`
      )
    ).data;
    return d;
  },

  async recalculateSpo2(uuid, from, to) {
    const d = (
      await axios.post(
        `${DOCTORS_API_ENDPOINT}/ds/user/${uuid}/spo2-measurements/recalculate`,
        {},
        {
          params: {
            timestamp_from: from,
            timestamp_to: to,
          },
        }
      )
    ).data;
    return d;
  },

  async recalculateRespiration(uuid, from, to) {
    const d = (
      await axios.post(
        `${DOCTORS_API_ENDPOINT}/ds/user/${uuid}/respiration-rate-measurements/recalculate`,
        {},
        {
          params: {
            timestamp_from: from,
            timestamp_to: to,
          },
        }
      )
    ).data;
    return d;
  },

  async recalculateBp(uuid, from, to) {
    const d = (
      await axios.post(
        `${DOCTORS_API_ENDPOINT}/v2/ds/user/${uuid}/blood-pressure-measurements/recalculate`,
        {},
        {
          params: {
            timestamp_from: from,
            timestamp_to: to,
          },
        }
      )
    ).data;
    return d;
  },

  async getPatientSessions(uuid, isDemo) {
    const dd = {};
    if (isDemo) {
      dd.demo = "yes";
    }
    const d = (
      await axios.get(`${DOCTORS_API_ENDPOINT}/patients/${uuid}/sessions`, {
        params: {
          ...dd,
        },
      })
    ).data.result;
    // console.log("d = ", d);
    return d;
  },

  async getStudyHealthUserSettings(code, isDemo) {
    const dd = { ...this.getTokensGetParamFromLs() };
    if (isDemo) {
      dd.demo = "yes";
    }
    const d = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/studies/${code}/health-user-settings`,
        {
          params: { ...dd },
        }
      )
    ).data;
    // console.log("d = ", d);
    return d;
  },

  async saveStudyHealthUserSettings(code, data) {
    const dd = { ...this.getTokensGetParamFromLs() };
    await axios.put(
      `${DOCTORS_API_ENDPOINT}/studies/${code}/health-user-settings`,
      {
        ...data,
      },
      {
        params: { ...dd },
      }
    );
  },

  async saveStudyDashboardSettings(code, data) {
    await axios.put(
      `${DOCTORS_API_ENDPOINT}/studies/${code}/dashboard-settings`,
      {
        ...data,
      }
    );
  },

  async saveStudyBraceletVersion(code, version) {
    await axios.put(
      `${DOCTORS_API_ENDPOINT}/studies/${code}/bracelet-version`,
      {
        bracelet_version: version,
      }
    );
  },

  async getPatientHealthUserSettings(uuid, isDemo) {
    const dd = {};
    if (isDemo) {
      dd.demo = "yes";
    }
    const d = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/users/${uuid}/health-user-settings`
      )
    ).data;
    // console.log("d = ", d);
    return d;
  },

  async savePatientHealthUserSettings(uuid, data) {
    console.log("savePatientHealthUserSettings: uuid, data = ", uuid, data);
    await axios.put(
      `${DOCTORS_API_ENDPOINT}/users/${uuid}/health-user-settings`,
      {
        ...data,
      }
    );
  },

  async getPatientGoals(uuid, isDemo) {
    const dd = {};
    if (isDemo) {
      dd.demo = "yes";
    }
    const d = (
      await axios.get(`${DOCTORS_API_ENDPOINT}/patients/${uuid}/goals`)
    ).data;
    // console.log("getPatientGoals: d = ", d);
    return d;
  },

  async getPatientSurveys(uuid, isDemo) {
    const dd = {};
    if (isDemo) {
      dd.demo = "yes";
    }
    const d = (
      await axios.get(`${DOCTORS_API_ENDPOINT}/patients/${uuid}/surveys`)
    ).data.result;
    // console.log("getPatientSurveys: d = ", d);
    return d;
  },

  async getPatientSessionById(uuid, sessionId) {
    const d = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/patients/${uuid}/sessions/${sessionId}`
      )
    ).data.result;
    // console.log("d = ", d);
    return d;
  },

  async sendPush(uuid, title, body) {
    const d = (
      await axios.post(`${DOCTORS_API_ENDPOINT}/patients/${uuid}/notify`, {
        title: title,
        body: body,
      })
    ).data.result;
    console.log("d = ", d);
    return d;
  },

  async getSessionPpgExportStatus(uuid, sessionId) {
    const d = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/export/user/${uuid}/sessions/${sessionId}`
      )
    ).data.result;
    return d;
  },

  async getPpgFromToExportStatus(uuid, from, to) {
    const d = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/export/user/${uuid}/ppg-export/${from}/${to}`
      )
    ).data.result;
    return d;
  },

  async checkIfThereIsWorkoutDataInSpecifiedRange(uuid, from, to) {
    console.log(
      "checkIfThereIsWorkoutDataInSpecifiedRange: uuid, from, to",
      uuid,
      from,
      to
    );
    const d = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/patients/${uuid}/workouts-latest-point/from/${from}/to/${to}`
      )
    ).data.result;
    console.log(
      "checkIfThereIsWorkoutDataInSpecifiedRange: got latest point = ",
      d
    );
    return d != undefined;
  },

  getSessionPpgExportCsvLink(uuid, sessionId) {
    const dd = { ...this.getTokensGetParamFromLs() };
    if (this.isVaaData() == true) {
      return `${DOCTORS_API_ENDPOINT}/export/user/${uuid}/sessions/${sessionId}/csv`;
    }
    return `${DOCTORS_API_ENDPOINT}/export/user/${uuid}/sessions/${sessionId}/csv?users_token=${dd.users_token}&health_token=${dd.health_token}`;
  },

  getPpgFromToExportCsvLink(uuid, from, to, name) {
    const dd = { ...this.getTokensGetParamFromLs() };
    if (this.isVaaData() == true) {
      return `${DOCTORS_API_ENDPOINT}/export/user/${uuid}/ppg/from/${from}/to/${to}/csv`;
    }
    return `${DOCTORS_API_ENDPOINT}/export/user/${uuid}/ppg/from/${from}/to/${to}/csv?users_token=${dd.users_token}&health_token=${dd.health_token}&name=${name}`;
  },

  getSessionRrsExportCsvLink(uuid, sessionId) {
    const dd = { ...this.getTokensGetParamFromLs() };
    if (this.isVaaData() == true) {
      return `${DOCTORS_API_ENDPOINT}/export/user/${uuid}/sessions/${sessionId}/rrs-csv`;
    }
    return `${DOCTORS_API_ENDPOINT}/export/user/${uuid}/sessions/${sessionId}/rrs-csv?users_token=${dd.users_token}&health_token=${dd.health_token}`;
  },

  getSessionSleepExportCsvLink(uuid, sessionId) {
    const dd = { ...this.getTokensGetParamFromLs() };
    if (this.isVaaData() == true) {
      return `${DOCTORS_API_ENDPOINT}/export/user/${uuid}/sessions/${sessionId}/sleep-csv`;
    }
    return `${DOCTORS_API_ENDPOINT}/export/user/${uuid}/sessions/${sessionId}/sleep-csv?users_token=${dd.users_token}&health_token=${dd.health_token}`;
  },

  getSessionActivityExportCsvLink(uuid, sessionId) {
    const dd = { ...this.getTokensGetParamFromLs() };
    if (this.isVaaData() == true) {
      return `${DOCTORS_API_ENDPOINT}/export/user/${uuid}/sessions/${sessionId}/activity-csv`;
    }
    return `${DOCTORS_API_ENDPOINT}/export/user/${uuid}/sessions/${sessionId}/activity-csv?users_token=${dd.users_token}&health_token=${dd.health_token}`;
  },

  getSessionTemperatureExportCsvLink(uuid, sessionId) {
    const dd = { ...this.getTokensGetParamFromLs() };
    if (this.isVaaData() == true) {
      return `${DOCTORS_API_ENDPOINT}/export/user/${uuid}/sessions/${sessionId}/temperature-csv`;
    }
    return `${DOCTORS_API_ENDPOINT}/export/user/${uuid}/sessions/${sessionId}/temperature-csv?users_token=${dd.users_token}&health_token=${dd.health_token}`;
  },

  // export from/to

  getRrsFromToExportCsvLink(uuid, from, to) {
    const dd = { ...this.getTokensGetParamFromLs() };
    const name = `rrs-${moment(from).format("YYYY-MM-DD_HH:mm:ss")}-${moment(
      to
    ).format("YYYY-MM-DD_HH:mm:ss")}.csv`;
    if (this.isVaaData() == true) {
      return `${DOCTORS_API_ENDPOINT}/export/user/${uuid}/rrs-csv/from/${from}/to/${to}`;
    }
    return `${DOCTORS_API_ENDPOINT}/export/user/${uuid}/rrs-csv/from/${from}/to/${to}?users_token=${dd.users_token}&health_token=${dd.health_token}&name=${name}`;
  },

  getSleepFromToExportCsvLink(uuid, from, to) {
    const dd = { ...this.getTokensGetParamFromLs() };
    const name = `sleep-${moment(from).format("YYYY-MM-DD_HH:mm:ss")}-${moment(
      to
    ).format("YYYY-MM-DD_HH:mm:ss")}.csv`;
    if (this.isVaaData() == true) {
      return `${DOCTORS_API_ENDPOINT}/export/user/${uuid}/sleep-csv/from/${from}/to/${to}`;
    }
    return `${DOCTORS_API_ENDPOINT}/export/user/${uuid}/sleep-csv/from/${from}/to/${to}?users_token=${dd.users_token}&health_token=${dd.health_token}&name=${name}`;
  },

  getActivityFromToExportCsvLink(uuid, from, to) {
    const dd = { ...this.getTokensGetParamFromLs() };
    const name = `activity-${moment(from).format(
      "YYYY-MM-DD_HH:mm:ss"
    )}-${moment(to).format("YYYY-MM-DD_HH:mm:ss")}.csv`;
    if (this.isVaaData() == true) {
      return `${DOCTORS_API_ENDPOINT}/export/user/${uuid}/activity-csv/from/${from}/to/${to}`;
    }
    return `${DOCTORS_API_ENDPOINT}/export/user/${uuid}/activity-csv/from/${from}/to/${to}?users_token=${dd.users_token}&health_token=${dd.health_token}&name=${name}`;
  },

  getTemperatureFromToExportCsvLink(uuid, from, to) {
    const dd = { ...this.getTokensGetParamFromLs() };
    const name = `temperature-${moment(from).format(
      "YYYY-MM-DD_HH:mm:ss"
    )}-${moment(to).format("YYYY-MM-DD_HH:mm:ss")}.csv`;
    if (this.isVaaData() == true) {
      return `${DOCTORS_API_ENDPOINT}/export/user/${uuid}/temperature-csv/from/${from}/to/${to}`;
    }
    return `${DOCTORS_API_ENDPOINT}/export/user/${uuid}/temperature-csv/from/${from}/to/${to}?users_token=${dd.users_token}&health_token=${dd.health_token}&name=${name}`;
  },

  getSpo2FromToExportCsvLink(uuid, from, to) {
    const dd = { ...this.getTokensGetParamFromLs() };
    const name = `temperature-${moment(from).format(
      "YYYY-MM-DD_HH:mm:ss"
    )}-${moment(to).format("YYYY-MM-DD_HH:mm:ss")}.csv`;
    if (this.isVaaData() == true) {
      return `${DOCTORS_API_ENDPOINT}/export/user/${uuid}/spo2-csv/from/${from}/to/${to}`;
    }
    return `${DOCTORS_API_ENDPOINT}/export/user/${uuid}/spo2-csv/from/${from}/to/${to}?users_token=${dd.users_token}&health_token=${dd.health_token}&name=${name}`;
  },

  getWorkoutsFromToExportCsvLink(uuid, from, to) {
    const dd = { ...this.getTokensGetParamFromLs() };
    const name = `workout-${moment(from).format(
      "YYYY-MM-DD_HH:mm:ss"
    )}-${moment(to).format("YYYY-MM-DD_HH:mm:ss")}.csv`;
    if (this.isVaaData() == true) {
      return `${DOCTORS_API_ENDPOINT}/export/user/${uuid}/workouts-csv/from/${from}/to/${to}`;
    }
    return `${DOCTORS_API_ENDPOINT}/export/user/${uuid}/workouts-csv/from/${from}/to/${to}?users_token=${dd.users_token}&health_token=${dd.health_token}&name=${name}`;
  },

  async getHRVPoints(uuid, from, to) {
    const pld = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/v2/battery/user/${uuid}/hrv-points`,
        {
          params: {
            from: from,
            to: to,
          },
        }
      )
    ).data;
    return pld;
  },

  async getActivityPoints(uuid, from, to) {
    const pld = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/v2/battery/user/${uuid}/activity-points`,
        {
          params: {
            from: from,
            to: to,
          },
        }
      )
    ).data;
    return pld;
  },

  async getBatteryAggregatedPoints(uuid, from, to, minutesInterval = 10) {
    const pld = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/v2/battery/user/${uuid}/aggregated-points`,
        {
          params: {
            from: from,
            to: to,
            minutesInterval: minutesInterval,
          },
        }
      )
    ).data;
    return pld;
  },

  async getBatteryAggregatedPointsForDate(uuid, date, minutesInterval = 10) {
    const pld = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/app2/users/${uuid}/energy-reserve/${date}`,
        {
          params: {
            minutesInterval: minutesInterval,
          },
        }
      )
    ).data;
    return pld;
  },

  async getGroupToken() {
    const pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/v1/api_token`)).data;
    return pld.token;
  },

  async resetGroupToken() {
    const pld = (
      await axios.post(`${DOCTORS_API_ENDPOINT}/v1/api_token/regenerate`)
    ).data;
    return pld.token;
  },

  async getAppLogs(uuid, from, to) {
    const pld = (
      await axios.get(`${DOCTORS_API_ENDPOINT}/app-logs/${uuid}/find`, {
        params: {
          from: from,
          to: to,
        },
      })
    ).data;
    return pld;
  },

  async getVivalinkItems(uuid, from, to) {
    const pld = (
      await axios.get(`${DOCTORS_API_ENDPOINT}/vivalink/${uuid}/items`, {
        params: {
          from: from,
          to: to,
        },
      })
    ).data;
    return pld;
  },

  async askAi(uuid, text) {
    try {
      const pld = (
        await axios.post(`${DOCTORS_API_ENDPOINT}/gpt/users/${uuid}/ask`, {
          text: text,
        })
      ).data;
      return pld;
    } catch (exc) {}
  },

  async getSleepRRIntervals(uuid, date) {
    const pld = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/v2/ds/users/${uuid}/sleep-rr-intervals/${date}`
      )
    ).data;
    return pld;
  },

  async getAggregatedPoints(
    uuid,
    dataType,
    fields = [],
    aggr_window,
    from,
    to
  ) {
    const pad = (num) => num.toString().padStart(2, "0");
    const getDateString = (x) => {
      const date = new Date(x);
      return (
        date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) + // Months are 0-based
        "-" +
        pad(date.getDate()) +
        "T" +
        pad(date.getHours()) +
        ":" +
        pad(date.getMinutes()) +
        ":" +
        pad(date.getSeconds()) +
        "." +
        (date.getMilliseconds() / 1000).toFixed(3).slice(2, 5)
      );
    };
    const tz = "+02:00";
    // console.log("from_date", getDateString(from), getDateString(to));

    try {
      const pld = (
        await axios.post(
          `${DOCTORS_API_ENDPOINT}/v2/users/${uuid}/aggregated-points/${dataType}`,
          {
            fields: fields,
            aggregation_window_seconds: aggr_window,
            // from_date: getDateString(from) + tz,
            // to_date: getDateString(to) + tz,
            from_client_date: getDateString(from),
            to_client_date: getDateString(to),
          }
        )
      ).data;
      return pld;
    } catch (exc) {}
    return [];
  },

  async getAggregatedPointsWithAbsoluteTime(
    uuid,
    dataType,
    fields = [],
    aggr_window,
    from,
    to
  ) {
    const pad = (num) => num.toString().padStart(2, "0");
    const getDateString = (x) => {
      const date = new Date(x);
      return (
        date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) + // Months are 0-based
        "-" +
        pad(date.getDate()) +
        "T" +
        pad(date.getHours()) +
        ":" +
        pad(date.getMinutes()) +
        ":" +
        pad(date.getSeconds()) +
        "." +
        (date.getMilliseconds() / 1000).toFixed(3).slice(2, 5)
      );
    };
    const tz = "+02:00";
    console.log("from_date", getDateString(from), getDateString(to));

    try {
      const pld = (
        await axios.post(
          `${DOCTORS_API_ENDPOINT}/v2/users/${uuid}/aggregated-points/${dataType}`,
          {
            fields: fields,
            aggregation_window_seconds: aggr_window,
            from_date: getDateString(from) + tz,
            to_date: getDateString(to) + tz,
          }
        )
      ).data;
      return pld;
    } catch (exc) {}
    return [];
  },

  async getAfNsrData(uuid, from, to) {
    const FAKE_UUIDS = ["de31e702-a3be-47ef-ac39-d626e92149b5"];
    let additionalMeasurements = [];
    let additionalAlerts = [];
    if (FAKE_UUIDS.indexOf(uuid) > -1) {
      additionalMeasurements = [
        {
          _id: "m-1",
          uuid: "m-1",
          start_timestamp: 1709107311000,
          forceUrl: `https://app.ecg.corsano.com/#/folder/30f42745b8704ed575d246336501da1a1ddb60b6/report/RCM012-06`,
        },
        {
          _id: "m-2",
          uuid: "m-2",
          start_timestamp: 1709283711000,
          forceUrl: `https://app.ecg.corsano.com/#/folder/30f42745b8704ed575d246336501da1a1ddb60b6/report/RCM012-09`,
        },
        {
          _id: "m-3",
          uuid: "m-3",
          start_timestamp: 1709622111000,
          forceUrl: `https://app.ecg.corsano.com/#/folder/30f42745b8704ed575d246336501da1a1ddb60b6/report/RCM012-10`,
        },
        {
          _id: "m-4",
          uuid: "m-4",
          start_timestamp: 1709812911000,
          forceUrl: `https://app.ecg.corsano.com/#/folder/30f42745b8704ed575d246336501da1a1ddb60b6/report/RCM012-12`,
        },
        {
          _id: "m-5",
          uuid: "m-5",
          start_timestamp: 1710608511000,
          forceUrl: `https://app.ecg.corsano.com/#/folder/30f42745b8704ed575d246336501da1a1ddb60b6/report/RCM012-13`,
        },
        {
          _id: "m-6",
          uuid: "m-6",
          start_timestamp: 1711260111000,
          forceUrl: `https://app.ecg.corsano.com/#/folder/30f42745b8704ed575d246336501da1a1ddb60b6/report/RCM012-14`,
        },
      ];
      additionalAlerts = [
        {
          _id: "m-1",
          timestamp: 1709107311000 - 30 * 60 * 1000,
          file_ids: ["660aee8e7dc9455e9340f384"],
          isImage: true,
        },
        {
          _id: "m-2",
          timestamp: 1709283711000 - 30 * 60 * 1000,
          file_ids: ["660aeea2cc34f61bda48fdd1"],
          isImage: true,
        },
        {
          _id: "m-3",
          timestamp: 1709622111000 - 30 * 60 * 1000,
          file_ids: ["660aeeb14470cc1fbd163703"],
          isImage: true,
        },
        {
          _id: "m-4",
          timestamp: 1709812911000 - 30 * 60 * 1000,
          file_ids: ["660aeebf6891e76fb2705783"],
          isImage: true,
        },
        {
          _id: "m-5",
          timestamp: 1710608511000 - 30 * 60 * 1000,
          file_ids: ["660aeecb75ff3113ad1e8be3"],
          isImage: true,
        },
        {
          _id: "m-6",
          timestamp: 1711260111000 - 30 * 60 * 1000,
          file_ids: ["660aeed9ddde291e2c308c73"],
          isImage: true,
        },
      ];
    }
    try {
      const pld = (
        await axios.get(
          `${DOCTORS_API_ENDPOINT}/v2/users/${uuid}/af-nsr-points`,
          {
            params: {
              from: from,
              to: to,
            },
          }
        )
      ).data;
      return {
        ...pld,
        measurements: [...pld.measurements, ...additionalMeasurements].filter(
          (x) => +x.start_timestamp > +from && x.start_timestamp < +to
        ),
        alerts: [...pld.alerts, ...additionalAlerts].filter(
          (x) => x.timestamp > +from && x.timestamp < +to
        ),
      };
    } catch (exc) {}
  },
};

export function gup(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regexS = "[\\?&]" + name + "=([^&#]*)";
  var regex = new RegExp(regexS);
  var results = regex.exec(url);
  return results == null ? null : results[1];
}

export default DoctorAPI;
