import { useState, useEffect } from "react";
import styled from "styled-components";
import TemplatesAPI from "../../../api/TemplatesAPI";
import { Textarea } from "../../bracelets/forms/UpdateCorsanoPatientForm";
import { Code } from "react-content-loader";
import { toast } from "react-hot-toast";
import CommonHelper from "../../../helpers/CommonHelper";
import GroupsAPI from "../../../api/GroupsAPI";

export default function VoucherLetterTool(props) {
  const { doctorUUID, code, isHCPAdmin, voucherCodes = [] } = props;

  const [loading, setLoading] = useState(false);
  const [oldContent, setOldContent] = useState(undefined);
  const [content, setContent] = useState(undefined);

  useEffect(() => {
    setLoading(true);
    // if (!isHCPAdmin) {
    //   console.log("is not admin");
    //   DoctorAPI.getHcpAdmin()
    //     .then((d) => {
    //       console.log("hcpadmin ", d);
    //       const hcpAdminUUID = d.hcpAdmin.uuid;
    //       // setHcpAdmin(d.hcpAdmin);
    //       console.log(hcpAdminUUID);

    //       TemplatesAPI.getStudyGroupTemplate(doctorUUID, code).then((d) => {
    //         console.log(d);
    //         setContent(d.content);
    //         setLoading(false);
    //       });
    //     })
    //     .catch((e) => console.error(e));
    // }
    TemplatesAPI.getStudyGroupTemplate(doctorUUID, code).then((d) => {
      console.log(d);
      setOldContent(d);
      setContent(d.content);
      setLoading(false);
    });
    // GroupsAPI.getGroupByTag(code).then((gr) => {
    //   console.log({ gr });
    //   if (!gr.hasOwnProperty("studyLetter")) {
    //     console.log("No letter found in group setting, using default");
    //     setContent(defaultLetterTemplate);
    //   } else {
    //     const { studyLetter } = gr;
    //     setContent(studyLetter.content);
    //   }
    //   setLoading(false);
    // });
  }, [doctorUUID, code]);

  // useEffect(() => {
  //   const {
  //     createdTimestamp = null,
  //     timestamp = null,
  //     updatedTimestamp = null,
  //   } = oldContent ?? {};
  //   if (!updatedTimestamp) {
  //     return;
  //   }
  //   if (+updatedTimestamp < 1733749575879) {
  //   }
  // }, [oldContent, content]);

  if (content == undefined) {
    return <Code />;
  }

  return (
    <Wrapper>
      <h2>Patient Letter</h2>
      <div style={{ marginBottom: "1rem" }}>
        We have a invitation letter template here, which you can tailor below
        and easily export it as a PDF for all participants in your study. The QR
        code is included for new participants register for this study.
      </div>
      <div style={{ marginBottom: "1rem" }}>
        <Textarea
          style={{ border: "1px solid grey" }}
          value={content}
          rows={22}
          placeholder={""}
          onChange={(evt) => {
            setContent(evt.target.value);
          }}
        />
      </div>

      {content.indexOf("[QR CODE]") != -1 ? null : (
        <HintPlaceholder>
          {`Your message must contain the "[QR CODE]" placeholder`}
        </HintPlaceholder>
      )}

      <ButtonPlaceholder>
        <Button
          onClick={async () => {
            if (content.indexOf("[QR CODE]") == -1) {
              toast.error(
                'Your message must contain the "[QR CODE]" placeholder'
              );
              return;
            }
            setLoading(true);
            // const letterObj = {
            //   content: content,
            //   updatedTimestamp: Date.now(),
            // };
            // console.log(letterObj);

            // await GroupsAPI.updateGroup({ studyLetter: letterObj, code: code });
            // let arr = await GroupsAPI.getGroupByTag(code);
            // console.log({ arr });

            await TemplatesAPI.saveStudyGroupTemplate(
              doctorUUID,
              code,
              content
            );
            setLoading(false);
            toast.success("Template Letter Saved");
          }}
        >
          {loading == true ? "Saving..." : "Save"}
        </Button>
      </ButtonPlaceholder>

      {voucherCodes.length == 0 || content == "" ? null : (
        <PrintSpanPlaceholder>
          <PrintSpan
            onClick={() => {
              CommonHelper.linkTo(`/groups/${code}/print`, true);
            }}
          >
            Print
          </PrintSpan>
        </PrintSpanPlaceholder>
      )}
    </Wrapper>
  );
}

const HintPlaceholder = styled.div`
  text-align: center;
  opacity: 0.6;
  color: red;
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 5px;
  font-weight: bold;
`;

const PrintSpanPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
`;

const PrintSpan = styled.span`
  cursor: pointer;
  opacity: 0.7;

  :hover {
    opacity: 1;
  }
`;

const ButtonPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Button = styled.div`
  width: 200px;

  background: #147aff;
  border-radius: 5px;
  height: 48px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;

  font-weight: 800;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;

  :hover {
    opacity: 0.85;
  }
`;

const Wrapper = styled.div`
  // margin-top: 1rem;
  // border: 1px solid blue
`;

// const defaultLetterTemplate = `
// Dear Patient,

// Thank you for your participation in the study.

// Please find instructions to download the Corsano App and register your anonymized voucher code. Please follow these steps:

// 1) Take your mobile phone and scan the QR Code with your camera:

// [QR CODE]

// 2) You will be directed to the Corsano App on the store. Please download and install the Corsano App on your phone.

// 3) Open the Corsano App on your mobile phone and press the [VOUCHER] button. You can now scan the QR Code again and your voucher code will be registered.

// 4) Please follow all instructions your receive in the Corsano App to create a password and then pair the bracelet

// Please contact us any time if you are having questions via: https://corsano.com/knowledge-base/

// Best regards,

// Your Research Team
// `;
