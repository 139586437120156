import DayHrvAnalysisPanel from "../../../hrv/panels/DayHrvAnalysisPanel";

export default function HrvPanel(props) {
  const { id, theme, seltT } = props;
  return (
    <div key={`zz_${seltT}`}>
      <DayHrvAnalysisPanel dayTimestamp={seltT} uuid={id} theme={theme} />
    </div>
  );
}
