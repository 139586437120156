import { useState, useEffect } from "react";
import styled from "styled-components";
import { Input, Button } from "../ui/templates";
import Select from "react-select";
import IntegrationAPI from "../../api/IntegrationAPI";
import ls from "local-storage";

const TABS = [
  {
    label: "WEBHOOK",
    value: "webhook",
  },
  {
    label: "HIX",
    value: "hix",
  },
  {
    label: "IQ MESSENGER",
    value: "iqmessenger",
  },
  {
    label: "EPIC",
    value: "epic",
  },
];

export default function IntegrationPanel() {
  const [address, setAddress] = useState("");
  const [format, setFormat] = useState("JSON");
  const [authenticationType, setAuthenticationType] = useState("basic");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [selectedTab, setSelectedTab] = useState("webhook");
  const theme = ls.get("theme");

  return (
    <Wrapper className={"integration-wrapper"}>
      <TabNamesPlaceholder>
        {TABS.map((a, i) => {
          let isSelected = a.value === selectedTab;

          return (
            <TabNameItem
              className="statistics-tab-name"
              theme={theme}
              key={i}
              selected={isSelected}
              onClick={() => {
                setSelectedTab(a.value);
              }}
              style={{ flexDirection: a?.value === "mpi" ? "row" : "" }}
            >
              {a.label}
              {a.value === "mpi" && (
                <i style={{ fontSize: "small", fontWeight: 400 }}>
                  &nbsp;{`beta`}
                </i>
              )}
            </TabNameItem>
          );
        })}
      </TabNamesPlaceholder>
      {selectedTab === "webhook" && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            gap: "1rem",
            padding: "2rem",
          }}
        >
          <OptionWrapper>
            <div
              className="normal-text"
              style={{
                width: "100%",
                // display: "flex",
                // alignItems: "center",
                // gap: "1rem",
              }}
            >
              <Label className="normal-text">Address</Label>
              <InputWrapper>
                <StyledInput
                  type="text"
                  name="address-input"
                  id="address-input"
                  placeholder="https://"
                  onChange={(evt) => {
                    setAddress(evt.target.value);
                  }}
                />
              </InputWrapper>
            </div>
          </OptionWrapper>
          <div style={{ display: "flex", flexDirection: "row", gap: "2rem" }}>
            <OptionWrapper>
              <div style={{ width: "100%" }}>
                <Label className="normal-text" htmlFor="format">
                  Format
                </Label>
                <InputWrapper>
                  <Selector
                    id="format"
                    name="format"
                    onChange={(evt) => {
                      setFormat(evt.target.value);
                    }}
                  >
                    {FORMAT_OPTIONS.map((x, i) => {
                      return <option key={i}>{x.label}</option>;
                    })}
                  </Selector>
                </InputWrapper>
              </div>
            </OptionWrapper>
            <OptionWrapper>
              <div style={{ width: "100%" }}>
                <Label className="normal-text" htmlFor="authType">
                  Authentication Type
                </Label>
                <InputWrapper>
                  <Selector
                    id="authType"
                    name="authType"
                    onChange={(evt) => {
                      console.log(evt.target.value);

                      setAuthenticationType(evt.target.value);
                    }}
                  >
                    {AUTHENTICATION_OPTIONS.map((x, i) => {
                      return <option key={i}>{x.label}</option>;
                    })}
                  </Selector>
                </InputWrapper>
              </div>
            </OptionWrapper>
          </div>

          <OptionWrapper>
            <div
              className="normal-text"
              style={{
                width: "100%",
                // display: "flex",
                // alignItems: "center",
                // gap: "1rem",
              }}
            >
              <Label className="normal-text">Log-in</Label>
              <InputWrapper>
                <StyledInput
                  type="text"
                  name="login-input"
                  id="login-input"
                  // placeholder=""
                  onChange={(evt) => {
                    setLogin(evt.target.value);
                  }}
                />
              </InputWrapper>
            </div>
          </OptionWrapper>
          <OptionWrapper>
            <div
              className="normal-text"
              style={{
                width: "100%",
                // display: "flex",
                // alignItems: "center",
                // gap: "1rem",
              }}
            >
              <Label className="normal-text">Password</Label>
              <InputWrapper>
                <StyledInput
                  type="password"
                  name="password-input"
                  id="password-input"
                  // placeholder=""
                  onChange={(evt) => {
                    setPassword(evt.target.value);
                  }}
                />
              </InputWrapper>
            </div>
          </OptionWrapper>
          <Button
            style={{ margin: "0px" }}
            onClick={() => {
              const webhookSettings = {
                address: address,
                format: format,
                authentication_type: authenticationType,
                login: login,
                password: password,
              };
              const integrationSettings = {
                integration_settings: {
                  webhook_settings: { ...webhookSettings },
                },
              };
              // console.log(integrationSettings);
              IntegrationAPI.saveIntegrationSettings(integrationSettings).then(
                (d) => {
                  console.log(d);
                }
              );
            }}
          >
            Connect
          </Button>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 10px;
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-bottom: 1rem;
  width: 200px;
`;

const FORMAT_OPTIONS = [
  {
    label: "JSON",
    value: "JSON",
  },
  {
    label: "FHIR HL7",
    value: "fhir-hl7",
  },
];

const AUTHENTICATION_OPTIONS = [
  {
    label: "Basic",
    value: "basic",
  },
  {
    label: "None",
    value: "none",
  },
];

const Label = styled.label`
  display: block;
  // font-size: 0.875rem; /* text-sm (14px) */
  font-size: 1rem; /* text-sm (14px) */
  // line-height: 1.5rem; /* text-sm/6 (24px line-height) */

  font-weight: 500; /* font-medium */
  color: #1a202c; /* text-gray-900 */
`;

const InputWrapper = styled.div`
  margin-top: 8px; /* mt-2 (2 * 4px = 8px) */
`;

const StyledInput = styled.input`
  display: block;
  width: 100%;
  border-radius: 0.375rem; /* rounded-md (6px radius) */
  border: none;
  padding: 6px 0 6px 8px; /* py-1.5 (6px), px-2 (8px) */
  color: #1a202c; /* text-gray-900 */
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); /* shadow-sm */
  outline: none;

  /* Ring styles */
  box-shadow: inset 0 0 0 1px #d1d5db; /* ring-1 ring-inset ring-gray-300 */
  &:focus {
    box-shadow: inset 0 0 0 2px #dfd6d5; /* focus:ring-2 focus:ring-inset focus:ring-indigo-600 */
  }

  font-size: 0.875rem; /* sm:text-sm (14px) */
  line-height: 1.5rem; /* sm:text-sm/6 (24px line-height) */

  ::placeholder {
    color: #a0aec0; /* placeholder:text-gray-400 */
  }
`;

const Selector = styled.select`
  display: block;
  width: 100%;
  max-width: 20rem; /* sm:max-w-xs (~320px) */
  border-radius: 0.375rem; /* rounded-md (6px) */
  border: none;
  padding: 6px 8px; /* py-1.5 (6px), px-2 (8px) */
  color: #1a202c; /* text-gray-900 */
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); /* shadow-sm */
  outline: none;

  /* Ring styles */
  box-shadow: inset 0 0 0 1px #d1d5db; /* ring-1 ring-inset ring-gray-300 */
  &:focus {
    box-shadow: inset 0 0 0 2px #dfd6d5; /* focus:ring-2 focus:ring-inset focus:ring-indigo-600 */
  }

  font-size: 0.875rem; /* sm:text-sm (14px) */
  line-height: 1.5rem; /* sm:text-sm/6 (24px line-height) */
`;

const TabNamesPlaceholder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TabNameItem = styled.div`
  flex: 1;
  text-align: center;
  color: ${(props) =>
    props.selected === true
      ? props.theme === "dark"
        ? "black"
        : "white"
      : props.theme === "dark"
      ? "white"
      : "gray"};
  background-color: ${(props) =>
    props.selected === true
      ? props.theme === "dark"
        ? "#f3f3f3"
        : "#000F4B;"
      : "transparent"};
  cursor: ${(props) => (props.selected === true ? "default" : "pointer")};
  font-weight: ${(props) => (props.selected === true ? "bold" : "bold")};

  font-size: 18px;
  line-height: 18px;

  box-sizing: border-box;
  border-radius: 4px 4px 0px 0px;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 900px) {
    font-size: 14px;
  }

  @media (max-width: 720px) {
    font-size: 12px;
  }
`;
