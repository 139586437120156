import styled from "styled-components";
import renderNewsOnTiles from "../renderNewsOnTiles";
import { NamePlaceholder } from "./ParamElements";

export default function NameSectionLarge(props) {
  const { p, alarm, lastMetricsLoading, outdated, xName, onPatientClick } =
    props;
  return (
    <NameSectionPlaceholderBigTiles className="name-section-placeholder">
      <NamePlaceholder
        style={{ fontSize: "18px" }}
        className="patient-name"
        onClick={() => {
          onPatientClick(p);
        }}
      >
        {xName}
      </NamePlaceholder>
      {window.location.href.indexOf("portal") > -1 && alarm.includes("news")
        ? renderNewsOnTiles(
            p?.newsScoreData,
            "NEWS2",
            "news2",
            true,
            lastMetricsLoading,
            true,
            outdated,
            "big"
          )
        : null}
    </NameSectionPlaceholderBigTiles>
  );
}

// for big tiles

export const NameSectionPlaceholderBigTiles = styled.div`
  display: flex;
  width: 100%;
  // width: 70px;
  // align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  margin-bottom: 8px;
`;
