import { TabHeading } from "../UiComponents";
import SpirometerTool from "../../../spiro/tools/SpirometerTool";
export default function SpiroPanel(props) {
  const { id } = props;
  return (
    <div>
      <TabHeading className="patient-statistics-heading">Spirometry</TabHeading>

      <SpirometerTool uuid={id} />
    </div>
  );
}
