import NewSurveysPanel from "../../../surveys/panels/NewSurveysPanel";
import moment from "moment";

export default function SurveyPanel(props) {
  const { date, id } = props;
  return (
    <div>
      <div style={{ width: "100%" }}>
        {/*<PatientSurveysPanel id={id}/>*/}
        <NewSurveysPanel
          uuid={id}
          from={+moment(date).startOf("day")}
          to={+moment(date).endOf("day")}
        />
      </div>
    </div>
  );
}
