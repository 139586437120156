import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import DoctorAPI from "../../../api/DoctorAPI";

import './style.css'

import {LineChart, XAxis, YAxis, CartesianGrid, Line, Tooltip, Legend} from 'recharts'
import useDimensions from "react-use-dimensions";

// CZ: Cognitive zone
// CZH: Cognitive Zone in One Hour
// PCZ: Predictive Cognitive Zone
// PCZT: Predictive Cognitive Zone Transition Time [mins]
// Stress Skin: Stress Level Skin Conductance
// CC: Cortisol Contribution

export default function PatientEmographyPanel(props) {
    const {uuid, from, to} = props;
    const [points, setPoints] = useState([]);
    const [ref, {x, y, width, height}] = useDimensions();

    useEffect(() => {
        DoctorAPI.getEmographyData(uuid, from, to).then(d => {
            // console.log('PatientEmographyPanel: d = ', d);
            setPoints(d);
        })
    }, [uuid, from, to]);

    // console.log('PatientEmographyPanel: render: points = ', points);
    let xPoints = points;
    for (let i in xPoints){
        if (xPoints[i]?.pczt == 255){
            delete xPoints[i]?.pczt;
        }
    }

    return (
        <Wrapper ref={ref}>

            {/*<Heading>*/}
            {/*    Cognitive Zone*/}
            {/*</Heading>*/}

            <NarrowChartPlaceholder>
                <LineChart width={width} height={150} data={points} connectNulls={true}
                           margin={{top: 5, right: 0, left: -20, bottom: 5}}>
                    <XAxis dataKey="date_without_tz"
                           tickFormatter={a => {
                               return (moment(a).format('HH:mm'))
                           }}
                    />
                    {/*<Tooltip />*/}
                    <YAxis/>
                    <Legend/>
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                    {/*<Line type="monotone" dataKey={'czh'} stroke="grey" strokeWidth={2} animationDuration={1}*/}
                    {/*      dot={false}/>*/}
                    <Line type="monotone" dataKey={'cz'} name={'Cognitive zone'} stroke="red" strokeWidth={2} animationDuration={1}
                          dot={false}/>
                    {/*<Line type="monotone" dataKey={'pcz'} stroke="blue" strokeWidth={2} animationDuration={1}*/}
                    {/*      dot={false}/>*/}
                </LineChart>
            </NarrowChartPlaceholder>

            <NarrowChartPlaceholder>
                <LineChart width={width} height={150} data={points} connectNulls={true}
                           margin={{top: 5, right: 0, left: -20, bottom: 5}}>
                    <XAxis dataKey="date_without_tz"
                           tickFormatter={a => {
                               return (moment(a).format('HH:mm'))
                           }}
                    />
                    {/*<Tooltip />*/}
                    <YAxis/>
                    <Legend/>
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                    <Line type="monotone" dataKey={'czh'} name={'Cognitive Zone in One Hour'} stroke="grey" strokeWidth={2} animationDuration={1}
                          dot={false}/>
                    {/*<Line type="monotone" dataKey={'cz'} stroke="red" strokeWidth={2} animationDuration={1}*/}
                    {/*      dot={false}/>*/}
                    {/*<Line type="monotone" dataKey={'pcz'} stroke="blue" strokeWidth={2} animationDuration={1}*/}
                    {/*      dot={false}/>*/}
                </LineChart>
            </NarrowChartPlaceholder>



            <NarrowChartPlaceholder>
                <LineChart width={width} height={150} data={points} connectNulls={true}
                           margin={{top: 5, right: 0, left: -20, bottom: 5}}>
                    <XAxis dataKey="date_without_tz"
                           tickFormatter={a => {
                               return (moment(a).format('HH:mm'))
                           }}
                    />
                    {/*<Tooltip />*/}
                    <YAxis/>
                    <Legend/>
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                    {/*/!*<Line type="monotone" dataKey={'czh'} stroke="grey" strokeWidth={2} animationDuration={1}*!/*/}
                    {/*/!*      dot={false}/>*!/*/}
                    {/*<Line type="monotone" dataKey={'cz'} stroke="red" strokeWidth={2} animationDuration={1}*/}
                    {/*      dot={false}/>*/}
                    <Line type="monotone" dataKey={'pcz'} name={'Predictive Cognitive Zone'} stroke="blue" strokeWidth={2} animationDuration={1}
                          dot={false}/>
                </LineChart>
            </NarrowChartPlaceholder>

            <NarrowChartPlaceholder>
                <LineChart width={width} height={150} data={points} connectNulls={true}
                           margin={{top: 5, right: 0, left: -20, bottom: 5}}>
                    <XAxis dataKey="date_without_tz"
                           tickFormatter={a => {
                               return (moment(a).format('HH:mm'))
                           }}
                    />
                    <YAxis/>
                    <Legend/>
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                    <Line type="monotone" dataKey={'pczt'} name={'Predictive Cognitive Zone Transition Time [mins]'} stroke="blue" strokeWidth={2} animationDuration={1}
                          dot={false}/>
                </LineChart>
            </NarrowChartPlaceholder>



            {/*<Heading>*/}
            {/*    /!*Transition, *!/*/}
            {/*    Stress and Cortisol*/}
            {/*</Heading>*/}

            <ChartPlaceholder>
                <LineChart width={width} height={400} data={points} connectNulls={true}
                           margin={{top: 5, right: 0, left: -20, bottom: 5}}>
                    <XAxis dataKey="date_without_tz"
                           tickFormatter={a => {
                               return (moment(a).format('HH:mm'))
                           }}
                    />
                    {/*<Tooltip />*/}
                    <YAxis/>
                    <Legend/>
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                    {/*<Line type="monotone" dataKey={'cc'} stroke="grey" strokeWidth={2} animationDuration={1}*/}
                    {/*      dot={false}/>*/}
                    <Line type="monotone" dataKey={'stress_skin'} name={'Stress Level Skin Conductance'} stroke="red" strokeWidth={2} animationDuration={1}
                          dot={false}/>
                    {/*<Line type="monotone" dataKey={'pczt'} stroke="blue" strokeWidth={2} animationDuration={1}*/}
                    {/*      dot={false}/>*/}
                </LineChart>
            </ChartPlaceholder>

            <ChartPlaceholder>
                <LineChart width={width} height={400} data={points} connectNulls={true}
                           margin={{top: 5, right: 0, left: -20, bottom: 5}}>
                    <XAxis dataKey="date_without_tz"
                           tickFormatter={a => {
                               return (moment(a).format('HH:mm'))
                           }}
                    />
                    {/*<Tooltip />*/}
                    <YAxis/>
                    <Legend/>
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                    <Line type="monotone" dataKey={'cc'} name={'Cortisol Contribution'} stroke="grey" strokeWidth={2} animationDuration={1}
                          dot={false}/>
                    {/*<Line type="monotone" dataKey={'stress_skin'} stroke="red" strokeWidth={2} animationDuration={1}*/}
                    {/*      dot={false}/>*/}
                    {/*<Line type="monotone" dataKey={'pczt'} stroke="blue" strokeWidth={2} animationDuration={1}*/}
                    {/*      dot={false}/>*/}
                </LineChart>
            </ChartPlaceholder>



        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const Heading = styled.div`
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
`;

const ChartPlaceholder = styled.div`
  margin-bottom: 25px;
  box-sizing: border-box;
  width: 100%;
  height: 400px;
`;

const NarrowChartPlaceholder = styled.div`
  margin-bottom: 25px;
  box-sizing: border-box;
  width: 100%;
  height: 150px;
`;

