import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

export default function CalcHrvParamsTool(props) {
    const {loading, hrvData = {}} = props;

    if (loading == true) {
        return (
            <>
            <Code/>
            <Code/>
            <Code/>
            </>
        )
    }

    return (
        <Wrapper>
            <BatchesPlaceholder>
                {HRV_PARAMS_OPTIONS.batches.map((batch, i) => {
                    let {params} = batch;
                    return (
                        <BatchItem key={i}>
                            {params.map((p, j) => {
                                return (
                                    <ParamItem key={j}>
                                        <Label>
                                            {p.label}
                                        </Label>
                                        <Value>
                                            {(+hrvData[p.name]).toFixed(p.precision)}
                                        </Value>
                                    </ParamItem>
                                )
                            })}
                        </BatchItem>
                    )
                })}
            </BatchesPlaceholder>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
`;

const BatchesPlaceholder = styled.div`
    width: 100%;
    box-sizing: border-box;
    
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
`;

const BatchItem = styled.div`
    margin: 3px;
`;

const ParamItem = styled.div`
    padding: 3px;
    border: 1px solid #e6e1de;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 3px;
`;

const Label = styled.div`
    width: 80px;
    font-weight: bold;
    font-size: 12px;
`;

const Value = styled.div`
    text-align: right;
    font-size: 12px;
`;

export const HRV_PARAMS_OPTIONS = {
    batches: [
        {
            params: [

                {
                    name: 'avrHR',
                    precision: 2,
                    label: 'Avr. HR'
                },
                {
                    name: 'minHR',
                    precision: 2,
                    label: 'Min. HR'
                },

                {
                    name: 'maxHR',
                    precision: 2,
                    label: 'Max. HR'
                },
                {
                    name: 'artifactsCount',
                    precision: 0,
                    label: 'artif. count'
                },
                {
                    name: 'count',
                    precision: 0,
                    label: 'n'
                },
                {
                    name: 'mRR',
                    precision: 1,
                    label: 'mRR'
                },


                // {
                //     name: 'WN1',
                //     precision: 2,
                //     label: 'WN1'
                // },
                // {
                //     name: 'WN4',
                //     precision: 2,
                //     label: 'WN4'
                // },
                // {
                //     name: 'WN5',
                //     precision: 2,
                //     label: 'WN5'
                // },


            ]
        },
        {
            params: [
                {
                    name: 'SDNN',
                    precision: 2,
                    label: 'SDNN'
                },
                {
                    name: 'RMSSD',
                    precision: 2,
                    label: 'RMSSD'
                },
                {
                    name: 'pNN50',
                    precision: 2,
                    label: 'pNN50'
                },
                {
                    name: 'Mo',
                    precision: 2,
                    label: 'Mo'
                },
                {
                    name: 'Amo',
                    precision: 2,
                    label: 'Amo'
                },
                {
                    name: 'SI',
                    precision: 2,
                    label: 'SI'
                },
                // {
                //     name: 'MxDMn',
                //     precision: 2,
                //     label: 'MxDMn'
                // },
                // {
                //     name: 'HRVTi',
                //     precision: 2,
                //     label: 'HRVTi'
                // }
                // {
                //     name: 'TP',
                //     precision: 2,
                //     label: 'TP'
                // },
                // {
                //     name: 'ln_TP',
                //     precision: 2,
                //     label: 'ln(TP)'
                // },
                // {
                //     name: 'VLF',
                //     precision: 1,
                //     label: 'VLF'
                // },
                // {
                //     name: 'VLF_percent',
                //     precision: 2,
                //     label: 'VLF%'
                // },
                // {
                //     name: 'ln_VLF',
                //     precision: 2,
                //     label: 'ln(VLF)'
                // },
                //
                //
                // {
                //     name: 'LF',
                //     precision: 2,
                //     label: 'LF'
                // },
                //
                // {
                //     name: 'LF_percent',
                //     precision: 2,
                //     label: 'LF%'
                // },
                //
                // {
                //     name: 'ln_LF',
                //     precision: 2,
                //     label: 'ln(LF)'
                // },
                // {
                //     name: 'HF',
                //     precision: 2,
                //     label: 'HF'
                // },
                // {
                //     name: 'HF_percent',
                //     precision: 2,
                //     label: 'HF%'
                // },
                // {
                //     name: 'ln_HF',
                //     precision: 2,
                //     label: 'ln(HF)'
                // },
                // {
                //     name: 'LF_over_HF',
                //     precision: 2,
                //     label: 'LF/HF'
                // },
                // {
                //     name: 'VLF_over_HF',
                //     precision: 2,
                //     label: 'VLF/HF'
                // },
                //
                // {
                //     name: 'LF_norm',
                //     precision: 2,
                //     label: 'LF norm.'
                // },
                // {
                //     name: 'HF_norm',
                //     precision: 2,
                //     label: 'HF norm.'
                // },
                // {
                //     name: 'IC',
                //     precision: 2,
                //     label: 'IC'
                // },
                // {
                //     name: 'A',
                //     precision: 2,
                //     label: 'A'
                // }
            ]
        }
    ]
}
