import { useState, useEffect, useMemo, useCallback, memo } from "react";
import { useMappedState } from "redux-react-hook";
import ls from "local-storage";
import moment from "moment";
import DoctorAPI from "../../../api/DoctorAPI";

import BinaryMeasurementsPanel from "../binary/panels/BinaryMeasurementsPanel";
// import PatientSurveysPanel from "../../surveys/panels/PatientSurveysPanel";
import Sidebar from "arui-feather/sidebar";
import ContinuousMeasurementsPanel from "./ContinuousMeasurementsPanel";
import NiceModal from "../../modals/NiceModal";
import PpgTypesSelector from "../../export/tools/PpgTypesSelector";
// import BinaryMeasurementsAPI from "../../../api/BinaryMeasurementsAPI";
import ExportServiceTool from "../../export/tools/ExportServiceTool";

// import UserWeeklyRecoveryPanel from "../../recovery/panels/UserWeeklyRecoveryPanel";
import NewExportServiceTool from "../../export/tools/NewExportServiceTool";

import { getLabelsInfoData } from "../../groups/panels/GroupsPanel";
// import PatientDaySpo2Tool from "../../spo2/tools/PatientDaySpo2Tool";
import { isDebugMode, isSleepDebugMode } from "../../../helpers/UiHelper";
// import SimpleTrendsChartTool from "../../trends/tools/SimpleTrendsChartTool";
import PatientDayBpTool from "../../bp/PatientDayBpTool";

import HealthBatteryWidgetTool from "../../health_battery/tools/HealthBatteryWidgetTool";

import AfNsrTool from "../../afib/tools/AfNsrTool";
import AppLogsTool from "../../logs/tools/AppLogsTool";

import { getFromToFromOption } from "../../trends/tools/TrendsUtils";
import WeightPanel from "./tabs/WeightPanel";
import RiskPanel from "./tabs/RiskPanel";
import SurveyPanel from "./tabs/SurveyPanel";
import SpiroPanel from "./tabs/SpiroPanel";
import OverviewPanel from "./tabs/OverviewPanel";
import {
  TabHeading,
  LogsInnerPlaceholder,
  ContentPlaceholder,
  Wrapper,
  TabNameItem,
  TopPlaceholder,
  TabNamesPlaceholder,
} from "./UiComponents";
import NibpPanel from "./tabs/NibpPanel";
import ActivityPanel from "./tabs/ActivityPanel";
import HrvPanel from "./tabs/HrvPanel";
import AfPanel from "./tabs/AfPanel";
import PulseRatePanel from "./tabs/PulseRatePanel";
import BiozPanel from "./tabs/BiozPanel";
import SleepTabPanel from "./tabs/SleepTabPanel";
import CbtPanel from "./tabs/CbtPanel";
import Spo2Panel from "./tabs/Spo2Panel";
import BrpmPanel from "./tabs/BrpmPanel";

const isAFMode = window.location.href.indexOf("af.") > -1;
const hasTopTabs = isAFMode === false;

export const isCorsanoMode = () => {
  try {
    return window.location.href.indexOf("corsano") > -1;
  } catch (exc) {}
  return false;
};

const TABS = [
  window.location.href.indexOf("portal") > -1
    ? undefined
    : {
        label: "Overview",
        value: "overview",
      },
  {
    label: "Pulse Rate",
    value: "heart_rate",
  },

  window.location.href.indexOf("portal") > -1
    ? undefined
    : {
        label: "HRV",
        value: "hrv",
      },

  // window.location.href.indexOf("portal") > -1
  //   ? undefined
  //   : {
  //       label: "AF",
  //       value: "af",
  //     },
  { label: "AF", value: "af" },

  {
    label: "SPO2",
    value: "spo2",
  },
  {
    // label: "Respiration",
    label: "BRPM",
    value: "respiration",
  },

  {
    // label: "Temperature",
    label: "CBT",
    value: "temperature",
  },

  getLabelsInfoData().bioz_enabled === false
    ? undefined
    : {
        label: "NIBP",
        value: "blood_pressure",
      },
  getLabelsInfoData().spiro_enabled === false ||
  window.location.href.indexOf("portal") > -1
    ? undefined
    : {
        label: "Spiro",
        value: "spiro",
      },
  getLabelsInfoData().weight_enabled === false ||
  window.location.href.indexOf("portal") > -1
    ? undefined
    : {
        label: "Weight",
        value: "weight",
      },
  getLabelsInfoData().bioz_enabled === false ||
  window.location.href.indexOf("portal") > -1
    ? undefined
    : {
        label: "BIOZ",
        value: "bioz",
      },
  window.location.href.indexOf("portal") > -1
    ? undefined
    : {
        label: "Sleep",
        value: "sleep",
      },

  getLabelsInfoData().surveys_enabled === false ||
  window.location.href.indexOf("portal") > -1
    ? undefined
    : {
        label: "Surveys",
        value: "surveys",
      },

  // window.location.href.indexOf("portal") > -1
  //   ? undefined
  //   :
  {
    label: "Activity",
    value: "activity",
  },
  {
    label: "RISK",
    value: "mpi",
  },
  // (getLabelsInfoData().health_battery_enabled === false ? undefined : {
  //     label: 'ER',
  //     value: 'health_battery'
  // }),
].filter((x) => x !== undefined);

function getTemperaturePoints(summary) {
  let pts = summary?.temperature?.slots || [];
  let isSk1 = pts.reduce(
    (res, pt) => (pt.temp_sk1 !== undefined && pt.temp_sk1 !== 0) || res,
    false
  );
  let newPoints = pts
    .map((x) => ({
      ...x,
      // temperature: isSk1 ? x.temp_sk1 : x.temp_sk2,
      temperature: x.temp_sk1,
    }))
    .filter((x) => x.temperature !== undefined && +x.temperature > 25);
  return newPoints;
}

export default function PatientDayAnalyticsPanel(props) {
  const {
    user,
    hasSleepTab = true,
    isDemo = false,
    patientName,
    isEmbedMode = false,
    appAndDeviceInfo,
    id,
    date, // in format YYYY-MM-DD
  } = props;

  const [rrsDownloading, setRrsDownloading] = useState(false);

  const d = new Date();
  let diff = Math.round(d.getTimezoneOffset() / 60);

  let default_tab =
    window.location.href.indexOf("portal") > -1 ? "heart_rate" : "overview";
  // window.location.href.indexOf("portal") > -1 ? "heart_rate" : "blood_pressure";
  const [selectedTab, setSelectedTab] = useState(default_tab);
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState(undefined);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [aiSleepLoading, setAiSleepLoading] = useState(false);
  const [aiSleepLoading3, setAiSleepLoading3] = useState(false);
  const [aiSleepStages, setAiSleepStages] = useState(undefined);
  const [aiSleepStages2, setAiSleepStages2] = useState(undefined);
  const [aiSleepStages3, setAiSleepStages3] = useState(undefined);

  const [ntrainSleepStages, setNtrainSleepStages] = useState(undefined);
  const [ntrainId, setNtrainId] = useState(undefined);

  const [goals, setGoals] = useState(undefined);
  const [canShowPhilipsSpo2, setCanShowPhilipsSpo2] = useState(false);

  const [workouts, setWorkouts] = useState([]);

  const [exportPpg2Visible, setExportPpg2Visible] = useState(false);

  const [exportToolData, setExportToolData] = useState(undefined);
  const [newExportToolData, setNewExportToolData] = useState(undefined);

  const [debugVisible, setDebugVisible] = useState(false);
  const [baselineWindow, setBaselineWindow] = useState(15); // in minutes

  const [tz, setTz] = useState(moment().utcOffset() / 60);
  const [tempActivityPoints, setTempActivityPoints] = useState([]); // for debug Pulse rate
  const [tempActivityPointsLoading, setTempActivityPointsLoading] =
    useState(false);

  const [cloudBpPoints, setCloudBpPoints] = useState([]);
  const [activityPoints, setActivityPoints] = useState([]); // for day bp
  const [activityPointsLoading, setActivityPointsLoading] = useState(false);
  const [bpTimeMode, setBpTimeMode] = useState("day");
  const [freshestData, setFreshestData] = useState([]);
  const [freshestNotification, setFreshestNotification] = useState([]);

  const [tempPoints, setTempPoints] = useState([]); // for temperature
  const [tempPointsLoading, setTempPointsLoading] = useState(false);

  const [bpmBaselinePoints, setBpmBaselinePoints] = useState([]);
  const defaultEmptyAllTypesMap = {
    bpm: [],
    respiration_rate: [],
    spo2: [],
    temperature: [],
  };
  const [allTypeBaselineMap, setAllTypeBaselineMap] = useState(
    defaultEmptyAllTypesMap
  );
  const [loadingAllTypeBaseline, setLoadingAllTypeBaseline] = useState(false);

  const [cuffInit, setCuffInit] = useState([]);

  const [sessionMode, setSessionMode] = useState("current-day");
  const [vitalParams, setVitalParams] = useState({});

  const [offsetActivityPoints, setOffsetActivityPoints] = useState([]); // for step activity under sleep
  const [vitalSummaryInRange, setVitalSummaryInRange] = useState([]);

  const [noonToNoonSleepData, setNoonToNoonSleepData] = useState([]);
  const [noonToNoonSleepDataLoading, setNoonToNoonSleepDataLoading] =
    useState(false);

  const { unit } = useMappedState(
    useCallback((state) => {
      return {
        unit: state.ui.unit,
      };
    })
  );
  const theme = ls.get("theme");
  const displayFullDay = true;

  const fetchData = async (setLoading, tz) => {
    /**
     * load bpm, respiration rate, spo2 data from api
     */
    // console.log("fetching data...");

    let rrDataFrom = moment(date).startOf("day").subtract(1, "hour").valueOf();
    let rrDataTo = moment(date).endOf("day").subtract(0, "hour").valueOf();
    if (setLoading) {
      setLoadingAllTypeBaseline(true);
    }
    setAllTypeBaselineMap(defaultEmptyAllTypesMap);
    DoctorAPI.getUserBaselineAllType(id, rrDataFrom, rrDataTo)
      .then((d) => {
        // console.log("alltypes", d);
        setAllTypeBaselineMap(d);
        // TODO: cache
        // const nowTs = Date.now()
        // ls.set(`${nowTs}_${id}_types`, d)
        if (setLoading) {
          setLoadingAllTypeBaseline(false);
        }
      })
      .catch((e) => console.error(e));
  };

  const toggleBpMode = (newMode) => {
    if (newMode !== "day" && newMode !== "12-12") {
      return;
    }
    setBpTimeMode(newMode);
  };
  let { customer_tag, healthUser } = user;

  useEffect(() => {
    if (customer_tag == undefined) {
      return;
    }
    DoctorAPI.getStudyHealthUserSettings(customer_tag).then((d) => {
      setVitalParams(d?.vital_parameters);
    });
  }, [user]);

  useEffect(() => {
    const secondsPerUpdate = 50 * 1000;
    let dateObj = moment(date);
    let isToday = dateObj.isSame(new Date(), "day");
    // console.log("istoday", isToday);
    let tz = 0; // reserved for timezone
    fetchData(true, tz);
    if (!isToday) {
      return;
    }
    const interval = setInterval(() => {
      fetchData(false, tz);
    }, secondsPerUpdate);
    return () => clearInterval(interval);
  }, [date, id]);

  useEffect(() => {
    if (window.location.href.indexOf("study.corsano.com") > -1) {
      DoctorAPI.getPatientGoals(id, isDemo).then((d) => {
        setGoals(d);
      });
    }
  }, [id]);

  useEffect(() => {
    if (date === undefined) {
      return;
    }

    // setLoading(true);
    setSummary(undefined);
    setSummaryLoading(true);
    DoctorAPI.getUserDaySummary(id, date, isDemo).then((pld) => {
      setLoading(false);
      setSummaryLoading(false);
      setSummary(pld);
      setNtrainSleepStages(undefined);
      setNtrainId(undefined);

      if (
        !(
          pld === undefined ||
          pld.sleep === undefined ||
          pld.sleep.slots === undefined
        )
      ) {
        // if (isDebugMode() === true || isSleepDebugMode() === true) {
        //   CalcAPI.getNTrainSleepStages3(id, date)
        //     .then((d) => {
        //       setNtrainSleepStages(
        //         (d.points || []).map((x) => ({ ...x, sleepStage: x.value }))
        //       );
        //       setNtrainId(d.id);
        //       setAiSleepLoading(false);
        //     })
        //     .catch((e) => console.error(e));
        // }
      }
    });
    setWorkouts([]);
    DoctorAPI.getUserDayWorkouts(id, date).then((arr) => {
      setWorkouts(arr);
    });
  }, [date]);

  useEffect(() => {
    if (date === undefined) {
      return;
    }
    let dayTimestamp = +moment(date).startOf("day");
    let activityDataFrom = moment(+dayTimestamp)
      .startOf("day")
      .subtract(0, "hour")
      .valueOf();
    let activityDataTo = moment(+dayTimestamp)
      .startOf("day")
      .add(24.5 * 60, "minute")
      .valueOf();
    let cloudDataFrom = +moment(+dayTimestamp).startOf("day").add(-1, "hours");
    let cloudDatato = +moment(+dayTimestamp).endOf("day");

    if (bpTimeMode != "day") {
      activityDataFrom = +moment(+dayTimestamp)
        .startOf("day")
        .add(-12, "hours");
      activityDataTo = +moment(+dayTimestamp).startOf("day").add(12, "hours");
      cloudDataFrom = +moment(+dayTimestamp).startOf("day").add(-12, "hours");
      cloudDatato = +moment(+dayTimestamp).startOf("day").add(12, "hours");
    }

    // console.log(moment(rrDataFrom).format("DD HH:mm"));
    // console.log(moment(rrDataTo).format("DD HH:mm"));

    setActivityPoints([]);
    setActivityPointsLoading(true);
    setCuffInit([]);
    DoctorAPI.getAIBpInitDataMeasurements(id).then((arr) => {
      setCuffInit(arr);
    });
    const start = performance.now();

    DoctorAPI.getActivityPoints(id, activityDataFrom, activityDataTo).then(
      (d) => {
        // console.log("DoctorAPI.getActivityPoints", d);

        console.debug(
          `getActivityPoints took ${(performance.now() - start).toFixed(2)} ms`
        );
        setActivityPointsLoading(false);
        if (!d || d.length === 0) {
          return;
        }
        let _ActivityPoints = d;
        _ActivityPoints = _ActivityPoints.map((s) => {
          return {
            ...s,
            t: moment(s.timestamp).valueOf(),
            slot: moment(s.timestamp).valueOf(),
            date: moment(s.timestamp).format("HH:mm:ss"),
          };
        });

        setActivityPoints(_ActivityPoints);
      }
    );
    // setCloudBpPoints
    DoctorAPI.getAIBpData(id, cloudDataFrom, cloudDatato).then((arr) => {
      setCloudBpPoints(arr);
    });
  }, [date, id, bpTimeMode]);

  useEffect(() => {
    // getting freshest ai bp
    let n = 10;
    setFreshestData([]);
    setFreshestNotification([]);
    DoctorAPI.getFreshestAIBpData(id, n).then((arr) => {
      if (arr.length === 0) {
      } else {
        let _freshestNotification = arr[0].notification_codes;
        setFreshestData(arr[0]);
        setFreshestNotification(_freshestNotification || []);
      }
    });
  }, [date, id]);

  useEffect(() => {
    if (date === undefined) {
      return;
    }
    let dataFrom = moment(date).startOf("day").subtract(1, "hour").valueOf();
    let dataTo = moment(date).endOf("day").add(tz, "hour").valueOf();
    DoctorAPI.getAggregatedPoints(
      id,
      "temperature",
      ["temp_sk1", "temp_sk2"],
      10,
      dataFrom,
      dataTo
    ).then((d) => {
      if (!d || d.length === 0) {
        return;
      }
      // console.log("DoctorAPI.getAggregatedPoints.temperature", d);
      let _Temperature = d;
      _Temperature = _Temperature.map((s) => {
        return {
          temp_sk1:
            s?.temp_sk1 && s?.temp_sk1 >= 33 && s?.temp_sk1 <= 45
              ? s.temp_sk1.toFixed(1)
              : null,

          slot: s.slot,
          t: moment(s.slot).valueOf(),
        };
      });
      setTempPoints(_Temperature);
      setTempPointsLoading(false);
    });
  }, [date, id]);

  useEffect(() => {
    if (date === undefined) {
      return;
    }
    // if (!isDebugMode()) {
    //   return;
    // }
    let dataFrom = moment(date).startOf("day").subtract(1, "hour").valueOf();
    let dataTo = moment(date).endOf("day").add(tz, "hour").valueOf();
    let rrDataFrom = moment(date).startOf("day").subtract(0, "hour").valueOf();
    let rrDataTo = moment(date).endOf("day").subtract(0, "hour").valueOf();
    setTempActivityPointsLoading(true);
    setTempPointsLoading(true);
    DoctorAPI.getTempActivityPoints(id, dataFrom, dataTo).then((d) => {
      // console.log("let _tempActivityPoints", d);
      if (!d || d.length === 0) {
        return;
      }

      let _tempActivityPoints = d;

      let _tzs = d.map((s) => s?.date).filter((s) => s);
      let currentZone = moment().utcOffset() / 60;
      // console.log("tzs", moment().utcOffset());

      // getting how many hour difference between current client and browser
      // console.log("_tzs", _tzs);

      if (_tzs.length === 0) {
        setTz(currentZone);
      } else {
        let _tz = _tzs[_tzs.length - 1];
        let zone = moment.parseZone(_tz).utcOffset() / 60;
        // console.log("tzs zone", zone, currentZone, currentZone- zone);
        setTz(currentZone - zone);
      }
      // console.log("timezone info", tz);

      _tempActivityPoints = _tempActivityPoints.map((s) => {
        return {
          bpm: s.bpm_q ? s.bpm : null,
          bpm_q: s?.bpm_q,
          t: moment(s.date).valueOf(),
          timestamp: moment(s.date).valueOf(),
          date: s.date,
          slot: s.date,
          slot_with_tz: s.date,
          spo2: s.spo2_q ? s.spo2 : null,
          spo2_q: s?.spo2_q,
        };
      });

      setTempActivityPoints(_tempActivityPoints);
    });
  }, [date, id]);

  useEffect(() => {
    if (id == undefined) {
      return;
    }
    const defaultSelectedOption = "1Y";
    const { from, to } = getFromToFromOption(defaultSelectedOption);
    // console.log({from, to});

    setVitalSummaryInRange([]);
    DoctorAPI.getUserLightSummariesInRange(id, from, to, false).then((d) => {
      setVitalSummaryInRange(d);
    });
  }, [date, id]);

  useEffect(() => {
    let offsetActivityDataFrom = moment(date)
      .startOf("day")
      .subtract(12, "hour")
      .valueOf();
    let offsetActivityDataTo = moment(date)
      .startOf("day")
      .add(12, "hour")
      .valueOf();

    setOffsetActivityPoints([]);
    DoctorAPI.getActivityPoints(
      id,
      offsetActivityDataFrom,
      offsetActivityDataTo
    ).then((d) => {
      // setActivityPointsLoading(false);
      if (!d || d.length === 0) {
        return;
      }
      let _offsetActivityPoints = d;
      _offsetActivityPoints = _offsetActivityPoints.map((s) => {
        return {
          ...s,
          t: moment(s.timestamp).valueOf(),
          slot: moment(s.timestamp).valueOf(),
          date: moment(s.timestamp).format("HH:mm:ss"),
        };
      });
      setOffsetActivityPoints(_offsetActivityPoints);
    });
  }, [date, id]);

  useEffect(() => {
    setNoonToNoonSleepData([]);
    if (window.location.href.indexOf("portal.corsano") > 0) {
      return;
    }
    setNoonToNoonSleepDataLoading(true);
    DoctorAPI.getPatientNoonToNoonSleep(id, date).then((d) => {
      setNoonToNoonSleepDataLoading(false);
      setNoonToNoonSleepData(d);
    });
  }, [date, id]);

  // useEffect(() => {
  //   DoctorAPI.getUserBaselineAllType(id, rrDataFrom, rrDataTo).then((d) => {
  //     if (!d || d.length === 0) {
  //       return;
  //     }
  //     console.log("DoctorAPI.getUserBaselineAllType", d);
  //   });
  // }, [date, id]);

  let hasAfTab =
    vitalParams && vitalParams.hasOwnProperty("afib")
      ? vitalParams["afib"] === 0
      : false;

  // const vitalFrequency = ["respiration_rate", "spo2", "heart_rate"].map((x) => {
  //   const _item = ITEMS.find((item) => item.name === x);
  //   const options = _item.options;
  //   const enabledOption = options.find(
  //     (option) => option.value === vitalParams[x]
  //   );
  //   // vitalParams[x]
  //   // console.log(enabledOption);
  //   return options?.label;
  // });
  // console.log(vitalFrequency);

  if (window.location.href.indexOf("portal.corsano") < 0) {
    hasAfTab = true;
  }
  // console.log("hasAfTab", hasAfTab);

  let seltT = +moment(date);

  let expUserId = user === undefined ? undefined : user.uuid;

  let newTempPoints = getTemperaturePoints(summary);

  let hasNoRRData =
    summary === undefined ||
    summary?.respiration_rate === undefined ||
    summary?.respiration_rate.slots === undefined;

  let hasNoTempData =
    summary === undefined ||
    summary.temperature === undefined ||
    summary.temperature.slots === undefined;

  let hasNoSleepData =
    summary === undefined ||
    summary.sleep === undefined ||
    summary.sleep.slots === undefined ||
    summary.sleep.slots.length === 0;

  let hasNoActivityData =
    summary === undefined ||
    summary.activity === undefined ||
    summary.activity.slots === undefined;

  // console.log("render: canShowPhilipsSpo2 = ", canShowPhilipsSpo2);

  let showCloud = false;
  const lastestCloudDate = moment("2024/10/02", "YYYY/MM/DD");
  if (
    moment(date).isBefore(lastestCloudDate) &&
    !window.location.href.includes("B2WZ9")
  ) {
    showCloud = true;
  }
  // console.log("Show cloud", showCloud);

  const memoizedBp = useMemo(() => {
    return (
      <PatientDayBpTool
        dayTimestamp={+moment(date).startOf("day")}
        uuid={id}
        min={+moment(date).startOf("day")}
        max={+moment(date).startOf("day").add(1, "day").valueOf()}
        cuffInit={cuffInit}
        activityPoints={activityPoints}
        cloudBpPoints={cloudBpPoints}
        activityPointsLoading={activityPointsLoading}
        mode={bpTimeMode}
        toggleBpMode={toggleBpMode}
        freshestData={freshestData}
        freshestNotification={freshestNotification}
      />
    );
  }, [id, date, cuffInit, activityPoints, activityPointsLoading]);

  let weight = healthUser == undefined ? undefined : healthUser.weight;
  let caloriesPerMile = +weight * 2.20462 * 0.5;
  let caloriesPerStep = caloriesPerMile / 1200;
  let stepsToday = 0;

  let calorieByHour = [];
  let stepsByHalfHour = [];

  for (let hour = 0; hour < 24; hour++) {
    const formattedHour = hour.toString().padStart(2, "0");
    const timeString = `${formattedHour}:00`;
    calorieByHour.push({ time: timeString, value: 0, steps: 0, calorie: 0 });
    stepsByHalfHour.push({ time: `${formattedHour}:00`, value: 0 });
    stepsByHalfHour.push({ time: `${formattedHour}:30`, value: 0 });
  }

  let __steps = 0;
  let __cal = 0;
  let motionMarkerTimestamps = [];

  activityPoints.map((activityPoint, i) => {
    let energy = activityPoint.energy;
    let ts = activityPoint.timestamp;
    let steps = activityPoint.steps;
    let date = activityPoint.date;
    let timeObject = moment(ts);
    // let _hour = +timeObject.hour();
    let _minute = +timeObject.minute();
    const _hour = +moment(date, "HH:mm:ss").hour();
    const _minutes = +moment(date, "HH:mm:ss").minute();
    const _minutesB = _minutes < 30 ? 0 : 1;
    const _halfHourIndex = 2 * _hour + _minutesB;
    // console.log(stepsByHalfHour[_halfHourIndex].time, _hour);

    stepsToday = stepsToday + steps;
    calorieByHour[_hour].calorie += +energy;
    calorieByHour[_hour].steps += +steps;
    calorieByHour[_hour].value += +steps * caloriesPerStep;

    stepsByHalfHour[_halfHourIndex].value += +steps;
  });

  calorieByHour = calorieByHour.map((x) => {
    if (x.steps >= 800) {
      motionMarkerTimestamps.push(x.time);
    }
    return {
      ...x,
      calorie: Math.floor(x.calorie),
      value: Math.floor(x.value),
    };
  });
  // console.log({ motionMarkerTimestamps});

  let isToday = moment(date).isSame(new Date(), "day");
  let caloriesToday = isToday
    ? parseInt(stepsToday * caloriesPerStep)
    : undefined;

  // console.log("caloriesToday", calorieByHour);

  return (
    <Wrapper
      className="statistics-tab-wrapper"
      style={{
        opacity: loading === true ? 0.5 : 1,
      }}
    >
      {hasTopTabs === false ? null : (
        <TopPlaceholder>
          <TabNamesPlaceholder>
            {TABS.filter((a) => isDemo === false || a.value !== "hrv")
              .filter((a) => !(hasSleepTab === false && a.value === "sleep"))
              .filter((a) => !(hasAfTab === false && a.value === "af"))
              .map((a, i) => {
                let isSelected = a.value === selectedTab;

                return (
                  <TabNameItem
                    className="statistics-tab-name"
                    theme={theme}
                    key={i}
                    selected={isSelected}
                    onClick={() => {
                      setSelectedTab(a.value);
                    }}
                    style={{ flexDirection: a?.value === "mpi" ? "row" : "" }}
                  >
                    {a.label}
                    {a.value === "mpi" && (
                      <i style={{ fontSize: "small", fontWeight: 400 }}>
                        &nbsp;{`beta`}
                      </i>
                    )}
                  </TabNameItem>
                );
              })}
          </TabNamesPlaceholder>
        </TopPlaceholder>
      )}

      {isAFMode === false ? null : (
        <AfNsrTool uuid={id} t={+moment(date).startOf("day")} />
      )}

      {hasTopTabs === false ? null : (
        <ContentPlaceholder
          key={`ssst_${selectedTab}`}
          className="patient-statistics-content"
        >
          {selectedTab !== "overview" ? null : (
            <OverviewPanel
              id={id}
              date={date}
              theme={theme}
              summary={summary}
              goals={goals}
              isDemo={isDemo}
              loading={loading}
              seltT={seltT}
              isEmbedMode={isEmbedMode}
              workouts={workouts}
              sessionMode={sessionMode}
              appAndDeviceInfo={appAndDeviceInfo}
              AppLogsTool={AppLogsTool}
            />
          )}
          {selectedTab !== "hrv" ? null : (
            <HrvPanel id={id} theme={theme} seltT={seltT} />
          )}

          {selectedTab !== "af" ? null : <AfPanel id={id} date={date} />}

          {selectedTab !== "heart_rate" ? null : (
            <PulseRatePanel
              id={id}
              date={date}
              seltT={seltT}
              user={user}
              summary={summary}
              loading={loading}
              theme={theme}
              baselineWindow={baselineWindow}
              tz={tz}
              loadingAllTypeBaseline={loadingAllTypeBaseline}
              allTypeBaselineMap={allTypeBaselineMap}
              vitalSummaryInRange={vitalSummaryInRange}
              tempActivityPoints={tempActivityPoints}
              isDemo={isDemo}
            />
          )}

          {selectedTab !== "bioz" ? null : <BiozPanel id={id} date={date} />}

          {selectedTab !== "activity" ? null : (
            <ActivityPanel
              id={id}
              date={date}
              calorieByHour={calorieByHour}
              caloriesToday={caloriesToday}
              stepsByHalfHour={stepsByHalfHour}
              summary={summary}
              summaryLoading={summaryLoading}
              hasNoActivityData={hasNoActivityData}
            />
          )}

          {selectedTab !== "sleep" ? null : (
            <SleepTabPanel
              id={id}
              date={date}
              offsetActivityPoints={offsetActivityPoints}
              summary={summary}
              hasNoActivityData={hasNoActivityData}
              hasNoSleepData={hasNoSleepData}
              loading={loading}
              ntrainSleepStages={ntrainSleepStages}
              aiSleepLoading={aiSleepLoading}
              displayFullDay={displayFullDay}
              noonToNoonSleepData={noonToNoonSleepData}
              noonToNoonSleepDataLoading={noonToNoonSleepDataLoading}
            />
          )}

          {selectedTab !== "temperature" ? null : (
            <CbtPanel
              id={id}
              date={date}
              unit={unit}
              theme={theme}
              tz={tz}
              hasNoTempData={hasNoTempData}
              loading={loading}
              baselineWindow={baselineWindow}
              tempPoints={tempPoints}
              tempPointsLoading={tempPointsLoading}
              summary={summary}
              vitalSummaryInRange={vitalSummaryInRange}
              newTempPoints={newTempPoints}
              isDemo={isDemo}
            />
          )}

          {selectedTab !== "spo2" ? null : (
            <Spo2Panel
              id={id}
              date={date}
              summary={summary}
              theme={theme}
              baselineWindow={baselineWindow}
              allTypeBaselineMap={allTypeBaselineMap}
              tz={tz}
              vitalSummaryInRange={vitalSummaryInRange}
              loadingAllTypeBaseline={loadingAllTypeBaseline}
              motionMarkerTimestamps={motionMarkerTimestamps}
              loading={loading}
            />
          )}

          {selectedTab !== "respiration" ? null : (
            <BrpmPanel
              id={id}
              date={date}
              hasNoRRData={hasNoRRData}
              theme={theme}
              baselineWindow={baselineWindow}
              tz={tz}
              allTypeBaselineMap={allTypeBaselineMap}
              loadingAllTypeBaseline={loadingAllTypeBaseline}
              vitalSummaryInRange={vitalSummaryInRange}
              summary={summary}
              loading={loading}
            />
          )}

          {selectedTab !== "weight" ? null : <WeightPanel id={id} />}

          {selectedTab !== "mpi" ? null : (
            <RiskPanel id={id} unit={unit} date={date} />
          )}

          {selectedTab !== "raw" ? null : (
            <div>
              <div style={{ width: "100%" }}>
                <BinaryMeasurementsPanel uuid={id} isDemo={isDemo} />
              </div>
            </div>
          )}

          {selectedTab !== "blood_pressure" ? null : (
            <NibpPanel
              id={id}
              date={date}
              seltT={seltT}
              bpTimeMode={bpTimeMode}
              toggleBpMode={toggleBpMode}
              cloudBpPoints={cloudBpPoints}
              cuffInit={cuffInit}
              activityPoints={activityPoints}
              activityPointsLoading={activityPointsLoading}
              freshestData={freshestData}
              freshestNotification={freshestNotification}
              memoizedBp={memoizedBp}
              showCloud={showCloud}
              isDebugMode={isDebugMode}
            />
          )}

          {selectedTab !== "spiro" ? null : <SpiroPanel id={id} />}

          {selectedTab !== "health_battery" ? null : (
            <div>
              <TabHeading className="patient-statistics-heading">
                Energy reserve
              </TabHeading>

              <HealthBatteryWidgetTool uuid={id} date={date} />
              {/*<HealthBatteryTool*/}
              {/*    uuid={id}*/}
              {/*    from={+moment(date).startOf('day')}*/}
              {/*    to={+moment(date).endOf('day')}*/}
              {/*/>*/}
            </div>
          )}

          {selectedTab !== "surveys" ? null : (
            <SurveyPanel id={id} date={date} />
          )}

          <Sidebar
            visible={exportPpg2Visible && debugVisible}
            width={Math.min(720, window.innerWidth)}
            onCloserClick={() => {
              setDebugVisible(false);
            }}
          >
            {exportPpg2Visible === false ? null : (
              <div>
                <ContinuousMeasurementsPanel
                  type={"ppg2"}
                  userId={id}
                  from={+moment(date).startOf("day")}
                  to={+moment(date).endOf("day")}
                  canExportMergedWiff={true}
                />
              </div>
            )}
          </Sidebar>

          {exportPpg2Visible === false ? null : (
            <NiceModal
              onClose={() => {
                setExportPpg2Visible(false);
              }}
            >
              <h3 style={{ paddingLeft: 5 }}>Export PPG</h3>
              <PpgTypesSelector
                onExport={(types) => {
                  // console.log("onExport: types = ", types);

                  setExportToolData({
                    types: types,
                    from: +moment(date).startOf("day"),
                    to: +moment(date).endOf("day"),
                    users: [user],
                    name: `${types.join("_")}_${moment(date)
                      .startOf("day")
                      .format("YYYY-MM-DD-HH-mm-ss")}_${moment(date)
                      .endOf("day")
                      .format("YYYY-MM-DD-HH-mm-ss")}_${expUserId}.zip`,
                    expUserId: expUserId,
                  });

                  // let from = +moment(date).startOf('day');
                  // let to = +moment(date).endOf('day');
                  // let userId = id;
                  // let type = 'ppg2';
                  // let url = BinaryMeasurementsAPI.getInstance().getContinuousMeasurementsPPG2FileUrl(userId, type, from, to, types);
                  // console.log('--->>> url = ', url);
                  // window.open(url, '_blank').focus();
                }}
              />

              <div style={{ marginTop: 20, opacity: 0.5, textAlign: "center" }}>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setDebugVisible(true);
                  }}
                >
                  show debug info
                </span>
              </div>
            </NiceModal>
          )}

          {/*<Sidebar visible={exportBiozVisible} onCloserClick={() => {*/}
          {/*    setExportBiozVisible(false);*/}
          {/*}}>*/}
          {/*    {exportBiozVisible === false ? null :*/}
          {/*        <div>*/}
          {/*            <ContinuousMeasurementsPanel*/}
          {/*                type={'bioz'}*/}
          {/*                userId={id}*/}
          {/*                from={+moment(date).startOf('day')}*/}
          {/*                to={+moment(date).endOf('day')}*/}
          {/*            />*/}
          {/*        </div>*/}
          {/*    }*/}
          {/*</Sidebar>*/}
        </ContentPlaceholder>
      )}

      {exportToolData === undefined ? null : (
        <NiceModal
          onClose={() => {
            setExportToolData(undefined);
          }}
        >
          <LogsInnerPlaceholder>
            <ExportServiceTool {...exportToolData} />
          </LogsInnerPlaceholder>
        </NiceModal>
      )}

      {newExportToolData === undefined ? null : (
        <NiceModal
          onClose={() => {
            setNewExportToolData(undefined);
          }}
        >
          <LogsInnerPlaceholder>
            <NewExportServiceTool {...newExportToolData} />
          </LogsInnerPlaceholder>
        </NiceModal>
      )}
    </Wrapper>
  );
}
