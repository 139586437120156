export const PAGE_SIZE_OPTIONS = [
  {
      value: 10,
      label: "10",
  },
  {
      value: 25,
      label: "25",
  },
  {
      value: 50,
      label: "50",
  },
];

export const AF_PAGE_SIZE_OPTIONS = [
  {
      value: 25,
      label: "25",
  },
  {
      value: 50,
      label: "50",
  },
];
export const DANGER_TIMEOUT = 60 * 60 * 1000;